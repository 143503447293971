import { Component, OnInit, ViewChild } from "@angular/core";
import { FormArray, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Subject } from "rxjs";
import { CommonFunctionsService } from "@ds-common-services/utility-services/common-functions.service";
import { LocalStorageService } from "@ds-common-services/storage-services/local-storage.service";
import { NotificationService } from "@ds-common-services/utility-services/notification.service";
import { debounceTime, distinctUntilChanged, finalize, takeUntil, Subscription} from 'rxjs';
import { CriteriaObj, MarketplaceData, ProfileLabel } from "@ds-shared/models/build-profiles.model";
import { ItemObject } from "@ds-shared/models/common.model";
import { NgSelectComponent } from "@ng-select/ng-select";
import { DataTransferService } from "@ds-common-services/utility-services/data-transfer.service";
import { ScorecardService } from "@ds-private-layouts/scorecard/scorecard.service";
import moment from 'moment';
import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import { MixpanelService } from '@ds-common-services/utility-services/mixpanel.service';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { GlobalService } from "@ds-shared/common-services/http-services/global.service";
import { RolePermissions } from "@ds-shared/models/access-matrix.model";
import { AccessMatrixService } from "@ds-shared/common-services/utility-services/access-matrix.service";
import { AccessMatrix } from "@ds-shared/enums/access-matrix.enum";
import { CustomValidationsService } from "@ds-shared/common-services/utility-services/custom-validations.service";

@Component({
  selector: 'app-build-profile-form',
  templateUrl: './build-profile-form.component.html',
  styleUrls: ['./build-profile-form.component.scss'],
})
export class BuildProfileFormComponent implements OnInit {
  @ViewChild("myInput") myInput: any;
  public profileForm: UntypedFormGroup;
  public minStartDate: Dayjs;
  public maxStartDate: Dayjs;
  public minEndDate: Dayjs;
  public maxEndDate: Dayjs;
  private destroy$: Subject<boolean> = new Subject();
  public submitted: boolean = false;
  public profileTypes: string[] = ["store", "keyword", "brand", "product", "category"];
  private startAtValueChangesSubscription: Subscription;
  public targetPriceTooltip = 'Set alerts based on Target Price for notifications on frequent price drops or jumps';
  public requiredFields: any = {
    store: [{ key: 'profile_type', required: true, formKey: 'profile_type' }, { key: 'name', required: true, formKey: 'profile_name' }, { key: 'profile_val', required: true, formKey: 'profile_url' }, { key: 'frequency', required: true, formKey: 'frequency' }, { key: 'sort_by', required: true, formKey: 'sort_by' }, { key: 'page_limit', required: true, formKey: 'page_limit' }, { key: 'segmentation_tag', required: true, formKey: 'tag_segment' }, { key: 'harmonised_brand', required: false, formKey: 'brand_label' }, { key: 'harmonised_product', required: false, formKey: 'product_label' }, { key: 'filter_keywords', required: false, formKey: 'filter_keywords' }, { key: 'exclude_keywords', required: false, formKey: 'exclude_keywords' }, { key: 'ml_service_codes', required: false, formKey: 'ml_service_codes' }, { key: 'start_at', required: false, formKey: 'start_at' }, { key: 'expiry_at', required: false, formKey: 'expiry_at' }, { key: 'keyword_search_filter', required: false, formKey: 'keyword_search_filter' }, { key: 'exclude_stores', required: false, formKey: 'exclude_stores' }, { key: 'exclude_brands', required: false, formKey: 'exclude_brands' }, { key: 'exclude_categories', required: false, formKey: 'exclude_categories' }],
    keyword: [{ key: 'profile_type', required: true, formKey: 'profile_type' }, { key: 'marketplace', required: true, formKey: 'select_marketplace' }, { key: 'profile_val', required: true, formKey: 'multiple_keywords' }, { key: 'frequency', required: true, formKey: 'frequency' }, { key: 'sort_by', required: true, formKey: 'sort_by' }, { key: 'harmonised_brand', required: false, formKey: 'brand_label' }, { key: 'segmentation_tag', required: true, formKey: 'tag_segment' }, { key: 'harmonised_product', required: false, formKey: 'product_label' }, { key: 'filter_keywords', required: false, formKey: 'filter_keywords' }, { key: 'exclude_keywords', required: false, formKey: 'exclude_keywords' }, { key: 'limit', required: true, formKey: 'limit' }, { key: 'page_limit', required: true, formKey: 'page_limit' }, { key: 'start_at', required: false, formKey: 'start_at' }, { key: 'expiry_at', required: false, formKey: 'expiry_at' }, { key: 'newest_first', required: false, formKey: 'newest_first' }, { key: 'keyword_search_filter', required: false, formKey: 'keyword_search_filter' }, { key: 'exclude_stores', required: false, formKey: 'exclude_stores' }, { key: 'exclude_brands', required: false, formKey: 'exclude_brands' }, { key: 'exclude_categories', required: false, formKey: 'exclude_categories' }],
    brand: [{ key: 'profile_type', required: true, formKey: 'profile_type' }, { key: 'name', required: true, formKey: 'profile_name' }, { key: 'profile_val', required: true, formKey: 'profile_url' }, { key: 'frequency', required: true, formKey: 'frequency' }, { key: 'sort_by', required: true, formKey: 'sort_by' }, { key: 'segmentation_tag', required: true, formKey: 'tag_segment' }, { key: 'harmonised_brand', required: false, formKey: 'brand_label' }, { key: 'harmonised_product', required: false, formKey: 'product_label' }, { key: 'filter_keywords', required: false, formKey: 'filter_keywords' }, { key: 'exclude_keywords', required: false, formKey: 'exclude_keywords' }, { key: 'limit', required: true, formKey: 'limit' }, { key: 'page_limit', required: true, formKey: 'page_limit' }, { key: 'ml_service_codes', required: false, formKey: 'ml_service_codes' }, { key: 'start_at', required: false, formKey: 'start_at' }, { key: 'expiry_at', required: false, formKey: 'expiry_at' }, { key: 'keyword_search_filter', required: false, formKey: 'keyword_search_filter' }, { key: 'exclude_stores', required: false, formKey: 'exclude_stores' }, { key: 'exclude_brands', required: false, formKey: 'exclude_brands' }, { key: 'exclude_categories', required: false, formKey: 'exclude_categories' }],
    product: [{ key: 'profile_type', required: true, formKey: 'profile_type' }, { key: 'name', required: true, formKey: 'profile_name' }, { key: 'profile_val', required: true, formKey: 'profile_url' }, { key: 'frequency', required: true, formKey: 'frequency' }, { key: 'harmonised_brand', required: false, formKey: 'brand_label' }, { key: 'harmonised_product', required: false, formKey: 'product_label' }, { key: 'segmentation_tag', required: true, formKey: 'tag_segment' }, { key: 'filter_keywords', required: false, formKey: 'filter_keywords' }, { key: 'exclude_keywords', required: false, formKey: 'exclude_keywords' }, { key: 'ml_service_codes', required: false, formKey: 'ml_service_codes' }, { key: 'start_at', required: false, formKey: 'start_at' }, { key: 'expiry_at', required: false, formKey: 'expiry_at' }, { key: 'notification_config', required: false, formKey: 'notification_config' }, { key: 'keyword_search_filter', required: false, formKey: 'keyword_search_filter' }, { key: 'exclude_stores', required: false, formKey: 'exclude_stores' }, { key: 'exclude_brands', required: false, formKey: 'exclude_brands' }, { key: 'exclude_categories', required: false, formKey: 'exclude_categories' }],
    category: [{ key: 'profile_type', required: true, formKey: 'profile_type' }, { key: 'name', required: true, formKey: 'profile_name' }, { key: 'profile_val', required: true, formKey: 'profile_url' }, { key: 'frequency', required: true, formKey: 'frequency' }, { key: 'sort_by', required: true, formKey: 'sort_by' }, { key: 'segmentation_tag', required: true, formKey: 'tag_segment' }, { key: 'harmonised_brand', required: false, formKey: 'brand_label' }, { key: 'harmonised_product', required: false, formKey: 'product_label' }, { key: 'filter_keywords', required: false, formKey: 'filter_keywords' }, { key: 'exclude_keywords', required: false, formKey: 'exclude_keywords' }, { key: 'limit', required: true, formKey: 'limit' }, { key: 'page_limit', required: true, formKey: 'page_limit' }, { key: 'ml_service_codes', required: false, formKey: 'ml_service_codes' }, { key: 'start_at', required: false, formKey: 'start_at' }, { key: 'expiry_at', required: false, formKey: 'expiry_at' }, { key: 'keyword_search_filter', required: false, formKey: 'keyword_search_filter' }, { key: 'exclude_stores', required: false, formKey: 'exclude_stores' }, { key: 'exclude_brands', required: false, formKey: 'exclude_brands' }, { key: 'exclude_categories', required: false, formKey: 'exclude_categories' }]
  }
  public frequencyList: { data: { id: number, name: string }[], isLoaded: boolean, subscription: Subscription } = {
    data: [],
    isLoaded: false,
    subscription: null
  };
  public mlServices: { code: string, name: string, isActive: boolean }[] =[];
  public actionType: string = "add";
  public criteria: CriteriaObj = {
    filter_keywords: [],
    exclude_keywords: [],
    multiple_keywords: [],
    keyword_search_filter: [],
    exclude_stores: [],
    exclude_brands: [],
    exclude_categories: []
  }
  public labels: { brand_label: { data: ItemObject[], isLoaded: boolean, search: string, sub_info: Subscription }, product_label: { data: ItemObject[], isLoaded: boolean, search: string, sub_info: Subscription } } = {
    brand_label: {
      data: [],
      isLoaded: false,
      search: null,
      sub_info: null
    },
    product_label: {
      data: [],
      isLoaded: false,
      search: null,
      sub_info: null
    }
  }
  public sortBy: { list: ItemObject[], loading: boolean } = {
    list: [],
    loading: false
  };
  public pageLimitData: number = 0;
  public tagsSubject$ = new Subject<{}>();
  public marketplaceData: MarketplaceData = {
    list: [],
    search: "",
    pageNumber: 1,
    selected: null,
    loading: false,
    initialList: [],
    initialPageNumber: 1
  }
  public segmentMap = { 'OS': 'Official', 'CP': 'Competitor', 'RS': 'OARP (Reseller)', 'GS': 'Greyseller', 'OT': 'Others' };
  public segmentList: string[] = Object.keys(this.segmentMap);
  public mappedTag: string;
  public profileType: string;
  public profileId: number;
  public profileData: any;
  public refresh: boolean = false;
  public loadingForm: boolean = false;
  public profileInfo: any = null;
  public tooltips={
    brandLabel: 'Classify all products from different profiles with a common brand label for easier reporting. Collection name must be between 3 and 15 characters long and contains at least one alphabets.',
    productLabel: 'Classify all products from different profiles with a common product label for easier reporting. Collection name must be between 3 and 15 characters long and contains at least one alphabets.',
    includeByCriteria: 'Filter collected listings by phrases found in the product title or description',
    excludeByCriteria: 'Exclude collected listings by phrases found in the product title or description',
    keywordSearchFilter: 'Filter listings by search term',
    excludeByStore: 'Exclude collected listings by store name',
    excludeByBrand: 'Exclude collected listings by brand name',
    excludeByCategory: 'Exclude collected listings by category name',
    startDate: 'Select date when you want to start tracking data using this tracker',
    endDate: 'Select date when you want to stop tracking data using this tracker'
  }
  @ViewChild("selectBrandLabel") selectBrandLabel: NgSelectComponent;
  @ViewChild("selectProductLabel") selectProductLabel: NgSelectComponent;
	public clearGetProfileInfoCall: Subscription;
	public clearGetMarketplaceListCall: Subscription;
	public clearCreateOrUpdateProfileCall: Subscription;
	public clearortByListCall: Subscription;
  public clearPageLimitDataCall: Subscription;
  public categoryData = {
    list: [],
    selected: null,
    loading: false,
  }
  public sampleURLData = {
    data: [],
    loading: false,
    subscription: null
  }
  public copied: number = null;
  public copiedObj: { copied: string, copiedId: number } = {
    copied: null,
    copiedId: null
  }
  public categoryTrackerView: string = "default";
  public currencyDetail = null;
  public marketplaceDetail: { id: number, name: string, multiple: boolean, marketplaces: any[] } = {
    id: null,
    name: null,
    multiple: false,
    marketplaces: []
  };
  public checkURL = false;
  public cqPermissions: RolePermissions = new RolePermissions();
  constructor(public commonFunctions: CommonFunctionsService, private localStorageService: LocalStorageService, public scorecardService: ScorecardService,
    private notificationService: NotificationService, private route: ActivatedRoute, private readonly router: Router,
    public dataTransferService: DataTransferService, 
    private mixpanelService: MixpanelService, private modalService: NgbModal, private globalService: GlobalService, private accessMatrixService: AccessMatrixService,
    private customValidations: CustomValidationsService, private notificationMessageService: NotificationService
    ) {
      this.cqPermissions = this.accessMatrixService.getPermissionByModule(AccessMatrix["Content Quality"]);
      this.searchInit();
    }

  private searchInit(): void {
    this.tagsSubject$
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        takeUntil(this.destroy$)
      )
      .subscribe(
        (res: any) => {
          const { searchType, event } = res
          this.onSearchFire(searchType, event);
        },
        (err) => { }
      );
  }

  public ngOnInit(): void {
    this.actionType = this.route["_routerState"]["snapshot"]?.url?.includes('add-profile') ? 'add' : 'edit';
    this.route.params.subscribe((params: any) => {
      this.profileId = parseInt(params?.id);
      this.profileType = this.commonFunctions.getURLSegments()[1];
    });
    this.route.queryParams.subscribe((params: any) => {
      if (params?.p) this.profileType = params?.p;
      this.profileInfo = params?.profileInfo ? JSON.parse(this.commonFunctions.getDecodedData(params?.profileInfo)) : null;
    });
    if (this.actionType === 'add') {
      this.getFormStructure();
      this.minStartDate = dayjs();
      this.minEndDate = dayjs();
    }
    else if (this.actionType === "edit") {
      this.getProfileData(this.profileId);
    }
    this.getSampleURL(this.profileType);
  }
  private getFormStructure(): void {
    this.profileForm = new UntypedFormGroup({
      profile_type: new UntypedFormControl(null, [Validators.required]),
      profile_name: new UntypedFormControl(this.getDefaultValue("name"), [
        Validators.required
      ]),
      select_marketplace: new UntypedFormControl(this.getDefaultValue("marketplace"), [
        Validators.required,
      ]),
      select_category: new UntypedFormControl(this.getDefaultValue("name"), [Validators.required]),
      multiple_keywords: new UntypedFormControl(null),
      profile_url: new UntypedFormControl(this.getDefaultValue("profile_val"), [
        Validators.required,
        this.validateShopeeUrl.bind(this),
      ]),
      frequency: new UntypedFormControl(null, [
        Validators.required
      ]),
      brand_label: new UntypedFormControl(null),
      product_label: new UntypedFormControl(null),
      filter_keywords: new UntypedFormControl(null),
      exclude_keywords: new UntypedFormControl(null),
      keyword_search_filter: new UntypedFormControl(null),
      exclude_stores: new UntypedFormControl(null),
      exclude_brands: new UntypedFormControl(null),
      exclude_categories: new UntypedFormControl(null),
      sort_by: new UntypedFormControl(this.getDefaultValue("sort"), [Validators.required]),
      page_limit: new UntypedFormControl(this.getDefaultValue("page_limit"), [Validators.required, this.validateLimit.bind(this)]),
      limit: new UntypedFormControl(this.commonFunctions.pdpLimit, [Validators.required, ]),
      ml_service_codes: new UntypedFormControl([]),
      tag_segment: new UntypedFormControl(
        this.getDefaultValue("segmentation_tag") || "OT",
        Validators.required
      ),
      expiry_at: new UntypedFormControl(this.getDefaultValue("expiry_at") ? {startDate:dayjs.utc(this.getDefaultValue("expiry_at")).format("MM-DD-YYYY"), endDate: dayjs.utc(this.getDefaultValue("expiry_at")).format("MM-DD-YYYY")} : null),
      start_at: new UntypedFormControl(this.getDefaultValue("start_at") ? {startDate:dayjs.utc(this.getDefaultValue("start_at")).format("MM/DD/YYYY"), endDate: dayjs.utc(this.getDefaultValue("start_at")).format("MM/DD/YYYY")} : null) ,
      notification_config: new UntypedFormControl(this.getDefaultValue("notification_config") ? this.getDefaultValue("notification_config").target_value : null),
      newest_first: new UntypedFormControl(!!this.getDefaultValue("newest_first")),
    });  
    this.profileForm.controls["profile_type"].setValue(this.getDefaultValue("profile_type") || this.profileType);
    this.getmlServiceList();
    this.mappedTag = this.getDefaultValue("segmentation_tag") || "OT";
    this.profileForm.controls["limit"].disable();
    if (this.actionType == "edit") {
      this.profileForm.get("profile_type").disable();
      this.criteria.filter_keywords =
        this.profileData?.[0]?.["attributes"]?.[
        "filter_keywords"
        ];
      this.criteria.exclude_keywords =
        this.profileData?.[0]?.["attributes"]?.[
        "exclude_keywords"
        ];
      this.criteria.multiple_keywords =
        this.profileData?.[0]?.["attributes"]?.["profile_val"]?.split(
          ","
        );
      this.criteria.keyword_search_filter =
        this.profileData?.[0]?.["attributes"]?.[
        "keyword_search_filter"
        ];
      this.criteria.exclude_stores =
        this.profileData?.[0]?.["attributes"]?.[
        "exclude_stores"
        ];
      this.criteria.exclude_brands =
        this.profileData?.[0]?.["attributes"]?.[
        "exclude_brands"
        ];
      this.criteria.exclude_categories =
        this.profileData?.[0]?.["attributes"]?.[
        "exclude_categories"
        ];
      for (let item of ["filter_keywords", "exclude_keywords", "keyword_search_filter", "exclude_stores", "exclude_brands", "exclude_categories"]) {
        if (this.criteria[item]?.length === (item === "keyword_search_filter" ? 1 : 10)) {
          this.profileForm.controls[item].disable();
        }
      }
      this.marketplaceData.selected = { name: this.getDefaultValue("marketplace"), id: this.getDefaultValue("marketplace_id") };
      this.profileForm.controls["profile_url"].disable();
      this.profileForm.controls["multiple_keywords"].disable();
      this.profileForm.controls["select_marketplace"].disable();
      this.profileForm.get("brand_label").disable();
      this.profileForm.get("product_label").disable();
      this.profileForm.get("sort_by").disable();
      this.profileForm.get("page_limit").disable();
      this.profileForm.get("frequency").disable();
      if (this.profileType !== 'keyword') {
        this.checkMarketplaceURL(this.commonFunctions.encodeURi(this.profileForm?.controls?.profile_url.value));
      }
      else {
        this.getSortByList();
        this.getPageLimitData();
        this.getFrequencyList();
        this.fetchCurrencyDetails();
      }
    }
    else if (this.actionType === "add") {
      this.profileForm.get("sort_by").disable();
      this.profileForm.get("page_limit").disable();
      this.profileForm.get("frequency").disable();
      this.profileForm.get("brand_label").disable();
      this.profileForm.get("product_label").disable();
      if (this.profileForm.get("profile_type").value === 'keyword') {
        this.profileForm.get("newest_first").patchValue(true)
        this.getMarketplaceList();
      }
      else if (this.profileForm.get("profile_type").value === 'category') {
        this.getCategoryList();
      }
    }
    if (this.profileInfo) {
      if (this.profileType === 'keyword') {
        this.criteria.multiple_keywords = [this.profileInfo.name];
        this.profileForm.controls["multiple_keywords"].disable();
      }
      else if (this.profileType === 'brand') {
        this.profileForm.controls['profile_name'].setValue(this.profileInfo.name);
      }
      else {
        this.profileForm.controls['profile_url'].setValue(this.profileInfo?.url);
        this.profileForm.controls['profile_url'].disable();
        this.checkMarketplaceURL(this.commonFunctions.encodeURi(this.profileForm?.controls?.profile_url.value));
        if (this.profileType === 'category') {
          this.onMLServiceSelect(null);
          this.categoryTrackerView = "custom";
          this.profileForm.get("profile_name").reset();
        }
      }
    }
    this.profileForm.get("profile_url").valueChanges.subscribe(() => {
      this.resetProfile();
      this.resetMarketplaceData();
    })
    if (this.profileType === 'category') {
      if (this.actionType === 'add') {
        this.profileForm.controls['profile_url'].disable();
      }
      else {
        this.categoryTrackerView = "custom";
      }
    }
  }

  public getMarketplaceList(url?: string): void {
    if (this.marketplaceData.pageNumber) {
      this.marketplaceData.loading = true;
      this.clearGetMarketplaceListCall?.unsubscribe();
		this.clearGetMarketplaceListCall = 
      this.scorecardService
        .getMarketplaceList(
          10,
          this.marketplaceData.pageNumber,
          this.marketplaceData.search,
          url
        ).pipe(takeUntil(this.destroy$))
        .subscribe((res: ProfileLabel) => {
          if (res) {
            this.marketplaceData.list = [...this.marketplaceData.list, ...res["data"]];
            this.marketplaceData.pageNumber = res["meta"]["next_page"];
            this.marketplaceData.loading = false;
            if (res?.meta?.current_page === 1 && !this.marketplaceData.initialList.length) {
              this.marketplaceData.initialList = JSON.parse(JSON.stringify(this.marketplaceData.list));
              this.marketplaceData.initialPageNumber = this.marketplaceData.pageNumber;
            }
          }
        }, (err: any) => {
          this.marketplaceData.list = null;
          this.marketplaceData.initialList = [];
          this.marketplaceData.loading = false;
        });
    }
  }
  public getCategoryList(): void {
    this.categoryData.loading = true;
    this.categoryData.selected = null;
    this.categoryData.list = [];
    this.scorecardService
      .getCategoryList().pipe(
        finalize(() => this.categoryData.loading = false),
        takeUntil(this.destroy$)
      )
      .subscribe((res) => {
        if (res) {
          this.categoryData.list = res["data"] || [];
          this.categoryData.list.splice(0,0,{
            id:-1,
            attributes:{
              name:'Add a new category for trend analysis only',
            }
          })
        }
      });
  }
  private getDefaultValue(forLabel: string): any {
    if (this.actionType == "edit") {
        return this.profileData?.[0]?.["attributes"]?.[forLabel];
    }
    return null;
  }
  private onResponseAction(response: any): void {
    if (response) {
      this.refresh = true;
      this.router.navigate([this.actionType==='edit'?'../..':'..'], {relativeTo: this.route})
      this.notificationService.setMessage(
        response["context_code"],
        response["summary"]
      );
      this.dataTransferService.sendRefreshProfileGridRequest(true);
		  this.dataTransferService.sendfireMenuCount('scorecard');
    }
  }
  public onSubmit(): void {
    this.submitted = true;
    const data = {
      name: this.profileForm.get("profile_name").value,
      harmonised_brand: this.profileForm.get("brand_label").value?.id,
      harmonised_product: this.profileForm.get("product_label").value?.id,
      profile_type: this.profileForm.get("profile_type").value,
      frequency: this.profileForm.get("frequency").value?.id,
      profile_val: this.profileForm.get("profile_type").value === 'keyword' ? this.criteria.multiple_keywords : this.profileForm.get("profile_url").value,
      filter_keywords: this.criteria.filter_keywords,
      exclude_keywords: this.criteria.exclude_keywords,
      keyword_search_filter: this.criteria.keyword_search_filter,
      exclude_stores: this.criteria.exclude_stores,
      exclude_brands: this.criteria.exclude_brands,
      exclude_categories: this.criteria.exclude_categories,
      segmentation_tag: this.mappedTag,
      sort_by: this.profileForm.get("sort_by").value?.id,
      marketplace: this.marketplaceData.selected?.id,
      limit: this.profileForm.get("limit").value,
      page_limit: this.profileForm.get("page_limit").value,
      ml_service_codes: this.mlServicesForm.value,
      newest_first: this.profileForm.get("newest_first").value
    };
    if(this.profileType==='product') {
        data['notification_config'] = {
          "notification_metric": 1,
          "target_value": this.profileForm.get("notification_config").value,
          "repeat": true,
          "operator": "<",
          "id": this.profileData?.[0]?.["attributes"]?.['notification_config']?.id
        }
      if (!data['notification_config']["id"] && data['notification_config']["target_value"] === null) delete data["notification_config"]
    }
    data["expiry_at"] = null;
    data["start_at"] = null;
    if(this.profileForm.get("start_at").value?.startDate && this.profileForm.get("expiry_at").value?.endDate) {
      data["expiry_at"] = moment(this.profileForm.get("expiry_at").value?.endDate).format("YYYY-MM-DD HH:mm:ss");
      data["start_at"] = moment(this.profileForm.get("start_at").value?.startDate).format("YYYY-MM-DD HH:mm:ss");
    }
    let validStatus: boolean = true;
    const payload = {}
    this.requiredFields[this.profileForm.get("profile_type").value].forEach(item => {
      payload[item.key] = data[item.key];
      if (this.profileForm.get(item.formKey).status === "INVALID" || (item.required && ((Array.isArray(payload[item.key]) && !payload[item.key]?.length) || (!Array.isArray(payload[item.key]) && (payload[item.key] === null || payload[item.key] === undefined))))) validStatus = false;
    })
    if (this.actionType === 'edit' && this.profileType == 'product') payload['scorecard_id'] = this.profileData?.[0]?.["attributes"]?.['scorecard_id'];
    if (!this.marketplaceDetail.multiple && this.profileType !== 'keyword') payload['marketplace'] = this.marketplaceDetail.id;
    if (validStatus) {
      this.clearCreateOrUpdateProfileCall?.unsubscribe();
		this.clearCreateOrUpdateProfileCall = 
      this.scorecardService
        .createOrUpdateProfile(this.actionType == 'add' ? 'post' : 'put', payload, this.profileId).pipe(takeUntil(this.destroy$))
        .subscribe((res: any) => {
          if (res){
            this.onResponseAction(res);
            if(this.actionType === 'edit'){
              this.mixpanelService.track(this.profileType + ' tracker updated',{})
            }
            else{
              this.mixpanelService.track(this.profileType + ' tracker created',{})
            }
          }
        }, (err: any) => {
          this.notificationService.setMessage(
            err?.error?.context_code,
            err?.error?.summary
          );
        });
    }
    
  }
  public onCloseAction(): void {
    this.refresh = false;
    this.router.navigate([this.actionType==='edit'?'../..':'..'], {relativeTo: this.route})
  }
  public checkUpdate(): void { }
  public validateShopeeUrl(c: UntypedFormControl): { [s: string]: boolean } {
    const url = c.value;
    if (!(this.commonFunctions.validateUrl(url) && url.startsWith("http"))) {
      return { invalid: true };
    } else return null;
  }
  public onURLAction(event): void {
    if (this.profileForm.controls.profile_url.status === 'VALID' && (event?.keyCode === 9 || event?.keyCode === 13 || event?.type === 'blur') && (!this.sortBy.list?.length)) {
      if (event?.keyCode === 9) event.preventDefault();
      this.resetProfile();
      this.checkMarketplaceURL(this.commonFunctions.encodeURi(this.profileForm?.controls?.profile_url.value))
    }
  }

  private getmlServiceList():void{
    this.mlServices = [];
    let checkForLocalStorage;
    try {
      checkForLocalStorage = this.localStorageService.get(this.localStorageService.mlServicesStorageKey) ? JSON.parse(this.commonFunctions.getDecodedData(this.localStorageService.get(this.localStorageService.mlServicesStorageKey))) : null;
      if (checkForLocalStorage.length) {
        this.mlServices = checkForLocalStorage;
        if (this.actionType === "edit") {
          this.profileData?.[0]?.["attributes"].ml_service_codes.forEach((code,i)=>{
              this.mlServices = this.mlServices.map((ml)=>{
                if(ml.code===code){
                  ml.isActive = true
                }
                return ml;
              })
          })
          this.mlServicesForm.patchValue(this.profileData?.[0]?.["attributes"].ml_service_codes || [])
        }
        return;
      }
    } catch {

    }
  }

  private getFrequencyList(): void {
    this.frequencyList.subscription?.unsubscribe();
    this.frequencyList.data = [];
    this.frequencyList.isLoaded = true;
    let tempObj: { type: string, key: string, value: number | string } = { type: this.profileForm.get("profile_type").value, key: 'marketplace_id', value: this.marketplaceDetail.id };
    if (this.profileForm.get("profile_type").value === 'keyword' || this.marketplaceDetail.multiple) tempObj = { type: this.profileForm.get("profile_type").value, key: 'marketplace_id', value: this.marketplaceData.selected?.id };
    this.frequencyList.subscription =
      this.scorecardService.getFrequencyList(tempObj.type, tempObj.key, tempObj.value).pipe(
        finalize(() => {
          this.frequencyList.isLoaded = false;
        }),
        takeUntil(this.destroy$)).subscribe((res: any) => {
          if (res) {
            this.profileForm.get("frequency").enable();
            this.frequencyList.data = [...res.data];
            this.frequencyList.data = this.frequencyList.data.map(item => {
              return { ...item, disabled: ["Campaign"].includes(item.name) };
            })
            if (this.actionType === "edit") {
              this.profileForm.controls.frequency.setValue(this.frequencyList.data.find(item => item.name === this.profileData?.[0]?.["attributes"].frequency))
            }
            else {
              this.profileForm.controls.frequency.setValue(this.frequencyList.data?.[0]);
            }
          }
        }, (err: any) => {
          this.notificationService.setMessage(
            err?.error?.context_code,
            err?.error?.summary
          );
        });
  }
  public onCriteriaSetKeydown(event: any, type: string, limit: number) {
    if (!event.target.value?.trim() && !this.profileForm.get(type).value?.trim()?.length) return;
    this.criteria[type].push(event.target.value?.trim() || this.profileForm.get(type).value?.trim());
    this.profileForm.controls[type].reset();
    if (this.criteria[type]?.length === limit) {
      this.profileForm.controls[type].disable();
    }
  }
  public dropCriteria(index: number, type: string) {
      this.criteria[type].splice(index, 1);
      this.profileForm.controls[type].enable();
  }
  private getProfileLabels(type: string, id?:number): void {
    this.labels[type].data = [];
    this.labels[type].isLoaded = true;
    this.labels[type].sub_info?.unsubscribe();
    this.labels[type].sub_info = 
      this.scorecardService.getHarmonisationEntity(type.split('_')[0], this.currencyDetail?.id).pipe(finalize(() => (this.labels[type].isLoaded = false)),takeUntil(this.destroy$)).subscribe((res: any) => {
      if (res) {
        this.labels[type].data = res.data;
        if (id) {
          let tempData = {};
          for (const item of res.data) {
            if (item.id === id) {
              tempData = { ...item };
              break;
            }
          }
          this.profileForm.controls[type].setValue({...tempData});
        }
      }
    })
  }
  private resetProfile(): void {
    this.sortBy.list = [];
    this.profileForm.get("sort_by").reset();
    this.profileForm.get("page_limit").reset();
    this.profileForm.get("frequency").reset();
    this.profileForm.get("brand_label").reset();
    this.profileForm.get("product_label").reset();
    this.profileForm.get("sort_by").disable();
    this.profileForm.get("page_limit").disable();
    this.profileForm.get("frequency").disable();
    this.profileForm.get("brand_label").disable();
    this.profileForm.get("product_label").disable();
  }
  private getSortByList(): void {
    if (this.profileType === 'product') return;
    this.sortBy.loading = true;
    this.sortBy.list = [];
    let tempObj: { type: string, key: string, value: number | string } = { type: this.profileForm.get("profile_type").value, key: 'marketplace_id', value: this.marketplaceDetail.id };
    if (this.profileForm.get("profile_type").value === 'keyword' || this.marketplaceDetail.multiple) tempObj = { type: this.profileForm.get("profile_type").value, key: 'marketplace_id', value: this.marketplaceData.selected?.id };
    this.clearortByListCall?.unsubscribe();
		this.clearortByListCall = 
    this.scorecardService.sortByList(tempObj.type, tempObj.key, tempObj.value).pipe(
      finalize(() => {
        this.sortBy.loading = false;
        if (!this.profileInfo && this.actionType!=='edit') this.myInput.focus();
      }),
      takeUntil(this.destroy$)).subscribe((res: any) => {
        if (res) {
          this.profileForm.get("sort_by").enable();
          this.sortBy.list = res.data;
          if (this.actionType === 'add') {
            this.profileForm.controls.sort_by.patchValue(this.sortBy.list[0]);
          }
        }
      }, (err: any) => {
        this.notificationService.setMessage(
          err?.error?.context_code,
          err?.error?.summary
        );
      });
  }
  private validateLimit(c: UntypedFormControl): { [s: string]: string } {
    const val = c.value;
    if (val > (Math.ceil(this.commonFunctions.pdpLimit / this.pageLimitData))) {
      return { fieldMessage: `Maximum page limit allowed for the current subscription is ${Math.ceil(this.commonFunctions.pdpLimit / this.pageLimitData)} (${this.commonFunctions.pdpLimit} Products)` };
    }
    else if (val === 0) {
      return { fieldMessage: `Minimum page limit allowed for the current subscription is 1` };
    }
    else return null;
  }
   
  private getPageLimitData(): void {
    if (this.profileType === 'product') return;
    this.pageLimitData = 0;
    let tempObj: { type: string, key: string, value: number | string } = { type: this.profileForm.get("profile_type").value, key: 'marketplace_id', value: this.marketplaceDetail.id };
    if (this.profileForm.get("profile_type").value === 'keyword' || this.marketplaceDetail.multiple) tempObj = { type: this.profileForm.get("profile_type").value, key: 'marketplace_id', value: this.marketplaceData.selected?.id };
    const is_brand_store = this.marketplaceDetail.marketplaces.length && this.profileType==='store' ? this.marketplaceDetail.marketplaces.find(item => item.id === tempObj.value).is_brand_store : false;
    this.clearPageLimitDataCall?.unsubscribe();
    this.clearPageLimitDataCall = this.scorecardService.pageLimitData(tempObj.type, tempObj.key, tempObj.value, is_brand_store).pipe(takeUntil(this.destroy$)).subscribe((res: any) => {
      if (res) {
        this.profileForm.get("page_limit").enable();
        if (this.actionType === 'add') {
          this.profileForm.controls.page_limit.patchValue(1);
        }
        this.pageLimitData = res?.data;
      }
    }, (err: any) => {
      this.notificationService.setMessage(
        err?.error?.context_code,
        err?.error?.summary
      );
    })
  }
  public onChange(event: any, type: string): void {
    if (type === 'profile_type') {
      this.resetProfile();
      this.profileForm.controls['profile_url'].reset();
      this.profileForm.controls['profile_name'].reset();
      this.profileForm.controls['multiple_keywords'].reset();
      this.resetMarketplaceData();
      this.submitted = false;
      if (this.profileForm.get("profile_type").value === 'keyword') {
        this.getMarketplaceList();
      }
    }
    else if (type === 'select_marketplace') {
      this.marketplaceData.selected = event;
      this.resetProfile();
      this.getSortByList();
      this.getPageLimitData();
      this.getFrequencyList();
      this.fetchCurrencyDetails();
    }
    else if (type === 'select_category') {
      this.resetProfile();
      if(event.id===-1){
        this.categoryTrackerView = "custom";
        this.profileForm.get("profile_url").reset();
        this.profileForm.controls['profile_url'].enable();
        this.onMLServiceSelect(null)
        this.profileForm.get("profile_name").reset();
        this.categoryData.selected = null;
        return
      }
      this.profileForm.controls['profile_url'].disable();
      this.categoryTrackerView = "official";
      this.categoryData.selected = event;
      this.onMLServiceSelect('pm');
      if (event) {
        this.profileForm?.controls?.profile_url.setValue(this.categoryData.selected?.attributes?.category_url);
        this.profileForm.controls["profile_name"].setValue(this.categoryData.selected?.attributes?.name);
        this.checkMarketplaceURL(this.commonFunctions.encodeURi(this.profileForm?.controls?.profile_url.value));
      }
    }
  }
  public searchInput(searchType: string, event: any): void {
    this.tagsSubject$.next({ searchType, event })
  }

  private onSearchFire(searchType: string, event: any): void {
    if (searchType === "search_marketplace") {
      this.marketplaceData.search = event?.term;
      if (event?.term.length >= 3 || event?.term.length == 0) {
        this.marketplaceData.pageNumber = 1;
        this.marketplaceData.list = [];
        this.getMarketplaceList();
      }
    }
  }
  public onMarketplaceDropdownClose(): void {
    this.marketplaceData.list = this.marketplaceData.initialList;
    this.marketplaceData.pageNumber = this.marketplaceData.initialPageNumber;
    this.marketplaceData.search = '';
  }
  public resetMarketplaceData(): void {
    this.marketplaceData = {
      list: [],
      search: "",
      pageNumber: 1,
      selected: null,
      loading: false,
      initialList: [],
      initialPageNumber: 1
    }
    this.profileForm.get("select_marketplace").reset();
    this.marketplaceDetail = {
      id: null,
      name: null,
      multiple: false,
      marketplaces: []
    }
    this.criteria.multiple_keywords = [];
  }
  private getProfileData(profileId: number): void {
    this.profileData = null;
    this.loadingForm = true;
    this.clearGetProfileInfoCall?.unsubscribe();
		this.clearGetProfileInfoCall = 
    this.scorecardService
      .getProfileInfo(
        this.profileType, profileId).pipe(takeUntil(this.destroy$))
      .subscribe((res: any) => {
        if (res)
          this.profileData = res.data;
        this.loadingForm = false;
        this.getFormStructure();
      }, (err: any) => {
        this.loadingForm = false;
      });

  }
  public addNewBrandLabel = (term: string) => {
    this.selectBrandLabel.close();
    this.addHarmonisationData("brand", term);
  }
  public addNewProductLabel = (term: string) => {
    this.selectProductLabel.close();
    this.addHarmonisationData("product", term);
  }
  public addHarmonisationData(type: string, term: string) {
    if(this.customValidations.validateInputString({value: term})?.fieldMessage) {
      this.notificationMessageService.setMessage(1100, this.customValidations.validateInputString({value: term})?.fieldMessage)
  }
  else {
    this.scorecardService.addHarmonisation(type, { name: term, currency_id: this.currencyDetail?.id }).pipe(takeUntil(this.destroy$)).subscribe((res:any) => {
      if (res) {
        this.notificationService.setMessage(
          res["context_code"],
          res["summary"]
        );
        this.dataTransferService.sendSignalOnHarmonisationCrud(true);
        this.getProfileLabels(`${type}_label`, res.data?.id);
      }
    })
  }
  }

  get mlServicesForm() {
    return this.profileForm.controls["ml_service_codes"] as FormArray;
  }

  public onMLServiceSelect(code){
    if(!code){
      this.mlServicesForm.reset();
      this.mlServices = this.mlServices.map(ml=>{
        ml.isActive = false;
        return ml;
      });
      this.mlServicesForm.patchValue([])
      return;
    }
    this.mlServices = this.mlServices.map(ml=>{
      if(ml.code===code) ml.isActive = !ml.isActive;
      return ml;
    });
    this.mlServicesForm.setValue(this.mlServices.filter(ml => ml.isActive).map((ml => ml.code)));
  }
  public onCustomDateUpdate(event, type) {
    if (type === "start_date") this.minEndDate = event.startDate ? event.startDate : moment();
    if (type === "end_date") this.maxStartDate = event.startDate ? event.endDate : null;
    if (this.profileForm.get("start_at").value?.startDate && !this.profileForm.get("expiry_at").value?.startDate) {
      this.profileForm.get("expiry_at").setErrors({ "wrong": true })
    }
    if (!this.profileForm.get("start_at").value?.startDate && this.profileForm.get("expiry_at").value?.startDate) {
      this.profileForm.get("start_at").setErrors({ "wrong": true })
    }
    if (!this.profileForm.get("start_at").value?.startDate && !this.profileForm.get("expiry_at").value?.startDate) {
      this.profileForm.get("start_at").setErrors(null)
      this.profileForm.get("expiry_at").setErrors(null)
    }
  }

  formatDate(date) {
    return moment(date).format("MM/DD/YYYY")
  }
  checkInvalidDate(date) {
    return moment(date._d).isBefore(moment(), 'date');
  }

  showSampleURL(content, sentiment_type = null, sentiment_phrase = null) {
    this.modalService.open(content, {
      centered: true,
      windowClass: "customModal sampleURLModal",
    });
    // this.getSampleURL(this.profileType);
  }
  getSampleURL(profileType:string) {
    this.sampleURLData.subscription?.unsubscribe();
    this.sampleURLData.loading = true;
    this.sampleURLData.data = [];
    this.sampleURLData.subscription = this.scorecardService
      .getSampleURL(profileType)
      .pipe(
        finalize(
          () => (this.sampleURLData.loading = false)
        ),
        takeUntil(this.destroy$)
      )
      .subscribe((res: any) => {
        if (res) {
          this.sampleURLData.data = res.data
        }
      });
  }
  copyURL(url) {
    this.copiedObj["copied"] = null;
    clearTimeout(this.copiedObj["copiedId"]);
    navigator.clipboard.writeText(url).then(() => {
      this.copiedObj["copied"] = url;
      this.copiedObj["copiedId"] = window.setTimeout(() => {
        this.copiedObj["copied"] = null;
      }, 2000)
    })
  }
  private fetchCurrencyDetails() {
    this.currencyDetail = null;
    const id = this.profileType === 'keyword' || this.marketplaceDetail.multiple ? this.marketplaceData.selected?.id : this.marketplaceDetail.id;
    this.globalService.getCurrencyDetails(this.profileType, id, 'marketplace', null).pipe(takeUntil(this.destroy$)).subscribe((res) => {
      this.currencyDetail = res["data"];
      this.profileForm.get("brand_label").enable();
      this.profileForm.get("product_label").enable();
      this.getProfileLabels('brand_label', this.profileData?.[0]?.["attributes"]?.["harmonised_brand"]?.id);
      this.getProfileLabels('product_label', this.profileData?.[0]?.["attributes"]?.["harmonised_product"]?.id);
    })
  }

  private checkMarketplaceURL(url) {
    this.checkURL = true;
    this.requiredFields[this.profileType].filter(item => item.key !== 'marketplace');
    this.resetMarketplaceData();
    this.scorecardService.checkMarketplaceURL(url, this.profileType).pipe(finalize(() => this.checkURL = false), takeUntil(this.destroy$)).subscribe((res: any) => {
      this.marketplaceDetail = res.data;
      if (this.marketplaceDetail.multiple) {
        this.requiredFields[this.profileType].push({ key: 'marketplace', required: true, formKey: 'select_marketplace' });
        this.getMarketplaceList(url);
        this.marketplaceData.selected = { name: this.getDefaultValue("marketplace"), id: this.getDefaultValue("marketplace_id") };
        if (this.actionType === 'edit') this.profileForm.controls["select_marketplace"].setValue(this.marketplaceData.selected);
      }
      else {
        this.getSortByList();
        this.getPageLimitData();
        this.getFrequencyList();
        this.fetchCurrencyDetails();
      }
    }, (err: any) => {
      this.notificationService.setMessage(
        err?.error?.context_code,
        err?.error?.summary
      );
    })
  }

  public ngOnDestroy(): void {
    this.tagsSubject$.next(null);
    this.tagsSubject$.unsubscribe();
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    this.sampleURLData.subscription?.unsubscribe();
  }

}
