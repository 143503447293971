import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { GlobalService } from "@ds-shared/common-services/http-services/global.service";
import { CommonFunctionsService } from "@ds-shared/common-services/utility-services/common-functions.service";
import { DataTransferService } from "@ds-shared/common-services/utility-services/data-transfer.service";
import {
	COMMONTEXT,
	GRIDTYPE,
	LOCALFILTER,
	SEGMENT
} from "@ds-shared/enums/common.enum";
import { finalize, Subject, takeUntil, Subscription } from "rxjs";
import { LocalStorageService } from "@ds-common-services/storage-services/local-storage.service";
import { RatingsList, TagsList } from "@ds-static-data/local-filter-data";
import { Options } from "@angular-slider/ngx-slider";
import { MarketShareTimelineType } from "@ds-shared/enums/sales.enum";
import moment from "moment";
import { FilterList } from "@ds-shared/models/local-filter.model";

@Component({
	selector: "app-local-filters",
	templateUrl: "./local-filters.component.html",
	styleUrls: ["./local-filters.component.scss"]
})
export class LocalFiltersComponent implements OnInit {
	public filterForm: UntypedFormGroup;
	public isOpen: boolean = false;
	public isOptionalActive: boolean = false;
	private $destroy: Subject<boolean> = new Subject();
	@Input() isDisabled: boolean = false;
	@Input() public optionalFilterParams: {
		style?;
		filterRequired: {
			store: boolean;
			brand: boolean;
			category_l1: boolean;
			category_l2: boolean;
			category: boolean;
			segment: boolean;
			status: boolean;
			sku?: boolean;
			retail_channel?: boolean;
			aiService?: boolean;
			product_segment?: boolean;
		};
	} = {
		style: null,
		filterRequired: {
			store: true,
			brand: true,
			category_l1: true,
			category_l2: true,
			category: true,
			segment: true,
			sku: this.isHarmonisedTurnedOn() || this.isKoreanReport(),
			status: false,
			retail_channel: false,
			aiService: false,
			product_segment: this.isHarmonisedTurnedOn()
		}
	};
	@Output() filterChange = new EventEmitter();
	public filterList: FilterList = {
		[LOCALFILTER.PRODUCT]: {
			placeholder: "Product",
			list: [],
			meta: { key: "id" },
			multiple: true,
			selected: [],
			searchTerm: "",
			loading: true,
			path: "products",
			query: [],
			clear: true,
			closeOnSelect: false,
			needAPIcall: true,
			type: "select",
			payloadString: "products"
		},
		[LOCALFILTER.RETAIL_CHANNEL]: {
			key: LOCALFILTER.RETAIL_CHANNEL,
			placeholder: COMMONTEXT.RETAIL_CHANNEL,
			list: [],
			meta: { key: "id" },
			multiple: true,
			selected: [],
			searchTerm: "",
			loading: false,
			path: "marketplaces",
			apiPath: "marketplaces",
			query: [],
			clear: true,
			closeOnSelect: false,
			needAPIcall: true,
			type: "select",
			payloadString: "marketplace_id"
		},
		[LOCALFILTER.STORE]: {
			key: LOCALFILTER.STORE,
			placeholder: "Store",
			list: [],
			meta: { key: "attributes.name" },
			multiple: true,
			selected: [],
			searchTerm: "",
			loading: false,
			path: "stores",
			apiPath: "stores",
			query: [],
			clear: true,
			closeOnSelect: false,
			needAPIcall: true,
			type: "select",
			payloadString: "stores"
		},
		[LOCALFILTER.BRAND]: {
			placeholder: "Brand",
			list: [],
			meta: { key: "attributes.name" },
			multiple: true,
			selected: [],
			searchTerm: "",
			loading: false,
			path: "brands",
			apiPath: "brands",
			query: [],
			clear: true,
			closeOnSelect: false,
			needAPIcall: true,
			type: "select",
			payloadString: "brands"
		},
		[LOCALFILTER.SKU]: {
			key: LOCALFILTER.SKU,
			placeholder: "Harmonised Products / SKU",
			list: [],
			meta: { key: "id" },
			multiple: true,
			selected: [],
			searchTerm: "",
			loading: false,
			path: "skus",
			apiPath: "skus",
			query: [],
			clear: true,
			closeOnSelect: false,
			needAPIcall: true,
			type: "select",
			payloadString: "skus"
		},
		[LOCALFILTER.PRODUCT_SEGMENT]: {
			placeholder: "Product Segment",
			list: [],
			meta: { key: "attributes.name" },
			multiple: true,
			selected: [],
			searchTerm: "",
			loading: true,
			path: "product-collection-segments",
			apiPath: "product-collection-segments",
			query: [],
			clear: true,
			closeOnSelect: false,
			needAPIcall: true,
			type: "select",
			payloadString: "product_collection_segments"
		},
		[LOCALFILTER.CATEGORY_L1]: {
			placeholder: "Category (L1)",
			list: [],
			meta: { key: "attributes.name" },
			multiple: true,
			selected: [],
			searchTerm: "",
			loading: true,
			path: "categories_l1",
			apiPath: "categories",
			query: [],
			clear: true,
			closeOnSelect: false,
			type: "select",
			needAPIcall: true,
			level: { name: 1, suffix: "l1" },
			payloadString: "categories_l1"
		},
		[LOCALFILTER.CATEGORY_L2]: {
			placeholder: "Category (L2)",
			list: [],
			meta: { key: "attributes.name" },
			multiple: true,
			selected: [],
			searchTerm: "",
			loading: true,
			path: "categories_l2",
			apiPath: "categories",
			query: [],
			clear: true,
			closeOnSelect: false,
			type: "select",
			needAPIcall: true,
			level: { name: 2, suffix: "l2" },
			payloadString: "categories_l2"
		},
		[LOCALFILTER.CATEGORY]: {
			placeholder: "Category (L3)",
			list: [],
			meta: { key: "attributes.name" },
			multiple: true,
			selected: [],
			searchTerm: "",
			loading: true,
			path: "categories",
			apiPath: "categories",
			query: [],
			clear: true,
			closeOnSelect: false,
			type: "select",
			needAPIcall: true,
			level: { name: 3, suffix: "l3" },
			payloadString: "categories"
		},
		[LOCALFILTER.SEGMENT]: {
			placeholder: "Seller Segments",
			list: [],
			meta: { key: "attributes.code" },
			multiple: true,
			selected: [],
			searchTerm: "",
			loading: false,
			path: "segments",
			apiPath: "segments",
			query: [],
			clear: true,
			closeOnSelect: false,
			needAPIcall: true,
			type: "select",
			payloadString: "segments"
		},
		[LOCALFILTER.STATUS]: {
			placeholder: "Status",
			list: [
				{
					id: 1,
					attributes: {
						name: "Active",
						code: true
					}
				},
				{
					id: 2,
					attributes: {
						name: "Paused",
						code: false
					}
				}
			],
			meta: { key: "attributes.name" },
			multiple: false,
			selected: [],
			searchTerm: "",
			loading: false,
			path: "status",
			apiPath: "status",
			query: [],
			clear: true,
			closeOnSelect: true,
			needAPIcall: false,
			type: "select",
			payloadString: "status"
		},
		[LOCALFILTER.AI_Services]: {
			placeholder: "AI Services",
			list: [
				{ id: 1, attributes: { code: "null", name: "None" } },
				{ id: 2, attributes: { code: "cq", name: "Content Quality" } },
				{ id: 3, attributes: { code: "sa", name: "Sentiment Analysis" } }
			],
			meta: { key: "attributes.code" },
			multiple: true,
			selected: [],
			searchTerm: "",
			loading: false,
			path: "ai",
			apiPath: "ai",
			query: [],
			clear: true,
			closeOnSelect: false,
			needAPIcall: false,
			type: "select",
			payloadString: "ai"
		},
		[LOCALFILTER.TAGS]: {
			placeholder: "Tags",
			list: JSON.parse(JSON.stringify(TagsList)),
			meta: { key: "attributes.value" },
			multiple: true,
			selected: [],
			searchTerm: "",
			loading: false,
			path: "tags",
			apiPath: "tags",
			query: [],
			clear: true,
			closeOnSelect: true,
			needAPIcall: false,
			type: "select",
			payloadString: "tags"
		},
		[LOCALFILTER.COUNTRY]: {
			placeholder: "Country",
			list: [],
			meta: { key: "id" },
			multiple: false,
			selected: [],
			searchTerm: "",
			loading: true,
			path: "currencies",
			apiPath: "currencies",
			query: [],
			clear: true,
			closeOnSelect: false,
			needAPIcall: true,
			type: "select",
			payloadString: "currency_id"
		},
		[LOCALFILTER.PRICE_RANGE]: {
			placeholder: "Price Range",
			list: null,
			meta: { key: "id" },
			multiple: false,
			selected: [],
			searchTerm: "",
			loading: true,
			path: "price-filter",
			apiPath: "price-filter",
			query: [],
			clear: true,
			closeOnSelect: false,
			needAPIcall: true,
			dependency: LOCALFILTER.COUNTRY,
			type: "range",
			payloadString: "price-filter"
		},
		[LOCALFILTER.RATINGS]: {
			placeholder: "Ratings",
			list: JSON.parse(JSON.stringify(RatingsList)),
			meta: { key: "attributes.value" },
			multiple: true,
			selected: [],
			searchTerm: "",
			loading: false,
			path: "ratings",
			apiPath: "ratings",
			query: [],
			clear: true,
			closeOnSelect: true,
			needAPIcall: false,
			type: "select",
			payloadString: "ratings"
		}
	};
	public filters: string[] = Object.keys(this.filterList);
	public routeSubscription: Subscription;
	@Input() scorecardInfo;
	public filterData;
	public isFirstCalltoFilter: boolean = true;
	private _globalFilterParam;
	private timelineString: string;
	get globalFilterParam() {
		return this._globalFilterParam;
	}
	@Input() set globalFilterParam(value) {
		this._globalFilterParam = value;
		this.clearFilter();
		this.onSubmit(true);
		this.getRevelantTimeline();
		this.getLocalFilterData();
	}
	private currentModule: string;
  public options: Options = {
    floor: 0,
    ceil: 0,
    disabled: true
  };
  public rangeFiltersSelected = {
    selectedOption: { name: "Price", key: "price", units: this.commonFunctions.currencySelected?.attributes?.symbol },
    data: null,
  };
	
	private _globalScorecardFilterParam;
	get globalScorecardFilterParam() {
		return this._globalScorecardFilterParam;
	}
	@Input() set globalScorecardFilterParam(value) {
		this._globalScorecardFilterParam = value;
		this.clearFilter();
		this.onSubmit(true);
		this.getLocalFilterData();
	}
	constructor(
		public commonFunctions: CommonFunctionsService,
		private globalService: GlobalService,
		private dataTransferService: DataTransferService,
		private router: Router,
		private route: ActivatedRoute,
		private localStorageService: LocalStorageService
	) {
		this.dataTransferService.openOptionalFilter$
			.pipe(takeUntil(this.$destroy))
			.subscribe((res) => {
				if (res) this.openFilter();
			});
		this.currentModule = this.router.url.split("/").slice(1).join("-");
	}

	ngOnInit(): void {
		this.filterForm = new UntypedFormGroup({
			product: new UntypedFormControl([]),
			store: new UntypedFormControl([]),
			brand: new UntypedFormControl([]),
			sku: new UntypedFormControl([]),
			category_l1: new UntypedFormControl([]),
			category_l2: new UntypedFormControl([]),
			category: new UntypedFormControl([]),
			segment: new UntypedFormControl([]),
			status: new UntypedFormControl([]),
			retail_channel: new UntypedFormControl([]),
			aiService: new UntypedFormControl([]),
			tags: new UntypedFormControl([]),
			country: new UntypedFormControl([]),
			ratings: new UntypedFormControl([]),
			price_range: new UntypedFormControl([]),
			product_segment: new UntypedFormControl([])
		});
		if (!this.globalFilterParam) this.getLocalFilterData();
		if (
			this.localStorageService.get(
				this.localStorageService.scorecardDefaultListType
			) === GRIDTYPE.ALL &&
			this.localStorageService.get(
				this.localStorageService.scorecardAllLocalFilters
			)
		) {
			this.filterList = JSON.parse(
				this.commonFunctions.getDecodedData(
					this.localStorageService.get(
						this.localStorageService.scorecardAllLocalFilters
					)
				)
			);
		} else if (
			this.localStorageService.get(
				this.localStorageService.scorecardUserOnlyLocalFilters
			) &&
			this.localStorageService.get(
				this.localStorageService.scorecardDefaultListType
			) === GRIDTYPE.USERONLY
		) {
			this.filterList = JSON.parse(
				this.commonFunctions.getDecodedData(
					this.localStorageService.get(
						this.localStorageService.scorecardUserOnlyLocalFilters
					)
				)
			);
		}
		this.isOptionalActive = this.checkIfFilterClear(this.filterList);
		this.filterData = this.localStorageService.get("filterData");
		this.localStorageService.removeItem("filterData");
		if (this.filterData) this.patchFilter(true);
		this.filterList["price_range"].list = { min_price: 0, max_price: 0 };
		this.options.floor = 0;
		this.options.ceil = 0;
	}
	private checkIfFilterClear(filterList) {
		return Object.entries(filterList).some(
			([key, filter]) => filterList[key].selected?.length > 0
		);
	}

	public onChange(eve) {
		if (eve === "country") {
			if (this.filterForm.get("country")?.value?.id) {
				this.getPrice();
			} else {
				this.filterList["price_range"].list = { min_price: 0, max_price: 0 };
				this.options.floor = 0;
				this.options.ceil = 0;
			}
		}
	}
	public onSubmit(isFirstCall?: boolean): void {
		this.isOptionalActive = false;
		const tempObj = JSON.parse(JSON.stringify(this.filterList));
		this.filters?.forEach((item) => {
			this.filterList[item].selected = this.filterForm?.get(item)?.value;
			if (item !== "price_range") {
				if (
					this.filterList[item].selected?.length ||
					(!this.filterList[item]?.multiple &&
						this.filterList[item].selected?.attributes)
				)
					this.isOptionalActive = true;
			}
			this.filterList[item].selected = this.filterForm?.get(item).value
				? this.filterForm.get(item).value
				: [];
			if (item !== "price_range") {
				if (
					this.filterList[item].selected?.length ||
					(!this.filterList[item]?.multiple &&
						this.filterList[item].selected?.attributes)
				)
					this.isOptionalActive = true;
			}
			if (
				["store", "brand", "category", "category_l1", "category_l2"].includes(
					item
				) &&
				!this.isKoreanReport(item)
			) {
				const newData = [];
				this.filterList[item]?.selected?.forEach((data) => {
					newData.push(...data["attributes"]["raw_data"]);
				});
				tempObj[item].selected = [...newData];
			} else {
				tempObj[item].selected = Array.isArray(this.filterList[item].selected)
					? [...this.filterList[item].selected]
					: this.filterList[item].selected;
			}
		});
		this.filterChange.emit(tempObj);
		if (!isFirstCall) this.isOpen = !this.isOpen;
	}

	getNumberDate(date): any | void {
		if (date) {
			let numberDate = date?.format("L").split("/");
			if (numberDate?.length == 3) {
				return numberDate[2] + "-" + numberDate[0] + "-" + numberDate[1];
			}
			return;
		}
	}
	private checkIfScorecard(list) {
		let res = '';
		let scorecardType = this.localStorageService.get('listType') ? this.localStorageService.get('listType') : '';
		const profileType = scorecardType === 'keyword' ? 'userOnly' : this.localStorageService.get(this.localStorageService.scorecardDefaultListType);
		const profileConversion = {
			'userOnly': 'profile',
			'all': 'raw'
		}
		if (list.apiPath === 'marketplaces' && (this.router.url.includes('scorecard') || this.router.url.includes('share-of-search'))) {
			res = `${res}&filter[view]=${profileConversion[profileType]}&filter[scorecard_type]=${scorecardType}`
		}
		if (this.router.url.includes('scorecard/product') && profileType === "all") {
			res = `${res}&${this.commonFunctions.getPayloadFromObject(this.globalScorecardFilterParam)}`
		}
		return res;
	}

	private getLocalFilterData() {
		this.filters.forEach((item, i) => {
			if (
				this.optionalFilterParams.filterRequired[item] &&
				this.filterList[item].needAPIcall
			) {
				this.filterList[item].loading = true;
				let scorecardProfileView = this.checkIfScorecard(this.filterList[item]);
				this.globalService
					.getLocalFilters(
						item === LOCALFILTER.PRODUCT_SEGMENT
							? false
							: this.isKoreanReport(item),
						this.filterList[item].apiPath,
						this.router.url.includes("scorecard") ||
							this.router.url.includes("share-of-search")
							? ""
							: this.commonFunctions.currencySelected.id,
						this.filterList[item].apiPath === "skus",
						this.scorecardInfo,
						false,
						null,
						this.filterData?.id,
						this.filterData?.harmonised_view,
						null,
						this.globalFilterParam,
						this.filterList[item].level,
						null,
						!!this.currentModule && this.timelineString,
						this.currentModule,
						scorecardProfileView
					)
					.pipe(
						finalize(() => (this.filterList[item].loading = false)),
						takeUntil(this.$destroy)
					)
					.subscribe((res: any) => {
						this.filterList[item].list = res.data;
						if (item === "price_range") {
							this.options.floor = this.filterList[item].list["min_price"];
							this.options.ceil = this.filterList[item].list["max_price"];
						}
						if (item === LOCALFILTER.SEGMENT) {
							this.filterList[item].list = res.data.map((item, i) => {
								return {
									id: this.isKoreanReport() ? item.attributes.name : item.name,
									attributes: {
										name: SEGMENT[
											this.isKoreanReport() ? item.attributes.name : item.name
										],
										code: this.isKoreanReport()
											? item.attributes.name
											: item.name
									}
								};
							});
						}
					});
			}
		});
	}

	public closeMultiDropdown(key) {
		this.filterList[key].searchTerm = "";
	}

	addTick(item, filter) {
		if (this.filterForm.get(filter).value?.some((e) => e.id === item.id)) {
			return true;
		} else return false;
	}

	onMultiSearch(event, key: string) {
		this.filterList[key].searchTerm = event.term;
	}
	openFilter() {
		this.isOpen = !this.isOpen;
		if (this.isOpen) {
			this.filters.forEach((item) => {
				this.filterForm.get(item).setValue(this.filterList[item].selected);
			});
		}
	}

	clearFilter() {
		if (!this.isFirstCalltoFilter) {
			this.filters.forEach((item) => {
				this.filterForm?.get(item).setValue([]);
			});
			this.getLinearCategoryData("category_l1", { name: 2, suffix: "l2" });
			this.getLinearCategoryData("category_l1", {
				name: 3,
				suffix: "l3",
				isL2Changed: false
			});
		}
		this.isFirstCalltoFilter = false;
	}

	private isKoreanReport(localFilterItem = ""): boolean {
		return (
			this.router.url.includes("brand-analysis") ||
			this.router.url.includes("dealer-analysis") ||
			(this.router.url.includes("sku-analysis") &&
				![
					LOCALFILTER.STORE,
					LOCALFILTER.CATEGORY,
					LOCALFILTER.CATEGORY_L1,
					LOCALFILTER.CATEGORY_L2,
					LOCALFILTER.BRAND
				].includes(localFilterItem as LOCALFILTER))
		);
	}

	private isHarmonisedTurnedOn(): boolean {
		return this.commonFunctions.getSavedUserData()["harmonised_view"];
	}

	checkSelectedOption(value, item) {
		if (value) {
			return value.map((val) => val.id).includes(item.id);
		}
	}

	public patchFilter(shouldRemoveShowLevel?) {
		let name = this.filterData.name;

		if (name === "store" || name === "category" || name === "brand") {
			this.patchFilterData(name, shouldRemoveShowLevel);
		} else if (name === "segment") {
			this.filterForm.controls[name].setValue(this.filterData.value);
			this.onSubmit();
		}
	}

	public patchFilterData(name, shouldRemoveShowLevel?) {
		this.globalService
			.getLocalFilters(
				this.isKoreanReport(name),
				this.filterList[name].apiPath,
				this.router.url.includes("scorecard")
					? ""
					: this.commonFunctions.currencySelected.id,
				this.filterList[name].apiPath === "skus",
				this.scorecardInfo,
				false,
				name,
				this.filterData?.id,
				this.filterData?.harmonised_view,
				shouldRemoveShowLevel,
				this.globalFilterParam,
				null,
				"",
				!!this.currentModule && this.timelineString,
				this.currentModule
			)
			.pipe(takeUntil(this.$destroy))
			.subscribe((res: any) => {
				this.filterForm.controls[name].setValue(res.data);
				this.onSubmit();
			});
	}

	onFilterSelect(eve, filter) {
		if (this.filterList[filter].apiPath === "categories") {
			if (this.filterList[filter].level.name === 1) {
				this.getLinearCategoryData(filter, { name: 2, suffix: "l2" });
				this.getLinearCategoryData(filter, {
					name: 3,
					suffix: "l3",
					isL2Changed: true
				});
			} else if (this.filterList[filter].level.name === 2) {
				this.getLinearCategoryData(filter, { name: 3, suffix: "l3" });
			}
		}
	}

	public getLinearCategoryData(filter, getLevel) {
		this.filterList[filter].selected = this.filterForm?.get(filter).value;
		if (getLevel.name === 2) {
			this.filterList["category_l2"].loading = true;
			this.filterList["category_l2"].selected = [];
			this.filterForm.get("category_l2").setValue([]);
			this.filterList["category"].selected = [];
			this.filterList["category"].loading = true;
			this.filterForm.get("category").setValue([]);
			this.filterList["category"].list = [];
			this.globalService
				.getLocalFilters(
					this.isKoreanReport(filter),
					this.filterList["category_l2"].apiPath,
					this.router.url.includes("scorecard")
						? ""
						: this.commonFunctions.currencySelected.id,
					this.filterList["category_l2"].apiPath === "skus",
					this.scorecardInfo,
					false,
					null,
					this.filterData?.id,
					this.filterData?.harmonised_view,
					null,
					this.globalFilterParam,
					getLevel,
					this.commonFunctions.generateLinearFilter(
						getLevel,
						this.filterList,
						filter
					),
					!!this.currentModule && this.timelineString,
					this.currentModule
				)
				.pipe(
					finalize(() => (this.filterList["category_l2"].loading = false)),
					takeUntil(this.$destroy)
				)
				.subscribe((res: any) => {
					this.filterList["category_l2"].list = res.data;
				});
		} else {
			this.filterList["category"].loading = true;
			this.filterList["category"].selected = [];
			this.filterForm.get("category").setValue([]);
			this.globalService
				.getLocalFilters(
					this.isKoreanReport(filter),
					this.filterList["category"].apiPath,
					this.router.url.includes("scorecard")
						? ""
						: this.commonFunctions.currencySelected.id,
					this.filterList["category"].apiPath === "skus",
					this.scorecardInfo,
					false,
					null,
					this.filterData?.id,
					this.filterData?.harmonised_view,
					null,
					this.globalFilterParam,
					getLevel,
					this.commonFunctions.generateLinearFilter(
						getLevel,
						this.filterList,
						filter
					),
					!!this.currentModule && this.timelineString,
					this.currentModule
				)
				.pipe(
					finalize(() => (this.filterList["category"].loading = false)),
					takeUntil(this.$destroy)
				)
				.subscribe((res: any) => {
					this.filterList["category"].list = res.data;
				});
		}
	}

	private getRevelantTimeline() {
		let currentTimelineObject: any = {};
		if (this.route["_routerState"]["snapshot"]["url"].includes("sales")) {
			currentTimelineObject =
				this.route["_routerState"]["snapshot"]["url"] === "/sales/market-share"
					? this.localStorageService.get(
							this.localStorageService.calendarTimelineCustomRangeStorageKey
					  )?.timeline
					: this.localStorageService.get(
							this.localStorageService.globalFilterStorageKey
					  )?.salesTimeline;
		} else if (
			this.route["_routerState"]["snapshot"]["url"] === "/content-quality"
		) {
			currentTimelineObject["label"] = "Last 90 Days";
		} else {
			currentTimelineObject =
				this.route["_routerState"]["snapshot"]["url"] ===
				"/availability/share-of-assortments"
					? this.localStorageService.get(
							this.localStorageService.calendarTimelineCustomRangeStorageKey
					  )?.timeline
					: this.localStorageService.get(
							this.localStorageService.globalFilterStorageKey
					  )?.timeline;
		}
		this.timelineString = currentTimelineObject.label
			? `&filter[timeline]=${
					MarketShareTimelineType[currentTimelineObject?.label]
			  }`
			: `&filter[start_date]=${this.getNumberDate(
					moment(currentTimelineObject?.startDate)
			  )}&filter[end_date]=${this.getNumberDate(
					moment(currentTimelineObject?.endDate)
			  )}`;
	}

	onPriceChange(type, changeContext?: any): void {
		if (type === "range") {
			this.filterList["price_range"].list["min_price"] = changeContext?.value
				? changeContext?.value
				: 0;
			this.filterList["price_range"].list["max_price"] =
				changeContext?.highValue ? changeContext?.highValue : 0;
		} else if (type === "min")
			this.filterList["price_range"].list["min_price"] =
				changeContext.target.value;
		else if (type === "max")
			this.filterList["price_range"].list["max_price"] =
				changeContext.target.value;
	}

	getPrice() {
		this.filterList["country"].selected = this.filterForm.get("country")?.value;
		this.filterList["price_range"].loading = true;
		this.globalService
			.getLocalFilters(
				this.isKoreanReport(),
				this.filterList["price_range"].path,
				this.filterForm.get("country")?.value?.id,
				this.filterList["price_range"].path === "skus",
				this.scorecardInfo,
				null,
				this.globalFilterParam
			)
			.pipe(
				finalize(() => (this.filterList["price_range"].loading = false)),
				takeUntil(this.$destroy)
			)
			.subscribe((res: any) => {
				this.filterList["price_range"].list = res.data;
				const newOptions: Options = Object.assign({}, this.options);
				newOptions.floor = this.filterList["price_range"].list["min_price"];
				newOptions.ceil = this.filterList["price_range"].list["max_price"];
				newOptions.disabled = false;
				this.options = newOptions;
			});
	}

	public closeDropdown(filter) {
		if (filter === "country") {
			this.filterList["price_range"].list["min_price"] = 0;
			this.filterList["price_range"].list["max_price"] = 0;
			const newOptions: Options = Object.assign({}, this.options);
			newOptions.disabled = true;
			newOptions.floor = 0;
			newOptions.ceil = 0;
			this.options = newOptions;
		}
	}

	refreshOptions() {}

	public getTooltipForPriceRange() {
		if (this.options.disabled) {
			return "Please first select country filter";
		} else return "";
	}

	public ngOnDestroy(): void {
		this.$destroy.next(true);
		this.$destroy.unsubscribe();
	}
}
