<div class="mt-2">
    <div class="ds-box p-0">
                        <div>
                            <div class="grid-parent-header d-flex justify-content-between align-items-center py-3">
                                <div class="grid-name d-flex align-items-center gap-8">
                                    <span class="name text-capitalize">Harmonised {{harmonisationType !== 'category' ? harmonisationType+'s' : 'Categories' }}</span>
                                </div>
                                <div class="d-flex align-items-center gap-8">
                                    <app-search-common [searchObj]="searchObj"
                                        (onSearch)="onSearch($event)"></app-search-common>
                                    <bulk-actions *ngIf="harmonisationType === 'product'" [listType]="'product_collection'"></bulk-actions>
                                    <button class="btn btn-secondary btn-lg"
                                        [disabled]="!permissions.write_access"
                                        (click)="openAddModal(addHarmonisation)" translate>
                                        <i class="icon icon-Add"></i>
                                        <span>{{'Add New Harmonised '+(harmonisationType|titlecase) | translate}}</span>
                                    </button>
                                    <button class="btn btn-primary btn-lg" [disabled]="!permissions.write_access" translate (click)="isMappingPanelOpen=!isMappingPanelOpen">
                                        <i class="icon icon-Shuffle"></i>
                                        <span>{{(harmonisationType | titlecase) +' Mapping' | translate}}</span>
                                    </button>
                                    <app-common-action-dropdown [path]="exportPath" [params]="exportParams"
                                    [filename]="exportedFileName" [columns]="[]" [disableIcon]="NoData"></app-common-action-dropdown>
    
                                </div>
                            </div>
                            <app-ngx-grid-revamp [ngxCols]="cols" [ngxItems]="harmonisedList" [moduleType]="moduleType"
                                [column]="cols" [collection]="harmonisedList" [loadingData]="loadingData" [noData]="NoData"
                                (sortData)="sortData($event)" [itemPerPage]='itemPerPage' [isPaginationWithoutRoute]=true
                                (paginationNo)="onPageChange($event)" (paginationSize)="onPageSizeChange($event)"
                                [maxSize]="3" [customNoDataText]="noDataText" [showThreeOptionsForPageSize]="true"
                                [className]="'harmonised'+'-'+harmonisationType+'-grid'" (refreshGrid)="this.getGridData(page, $event)"></app-ngx-grid-revamp>
                        </div>
    </div>
</div>

<ng-template #addHarmonisation let-modal>
    <div class="modal-header">
        <h4 class="modal-title" translate>Add New Harmonised {{this.harmonisationType | titlecase}}</h4>
        <i class="icon icon-Close2 cursor-pointer" (click)="modal.dismiss('Cross click')">
        </i>
    </div>
    <form [formGroup]="productCollection" class="text-align-right-rtl mt-3" (ngSubmit)="[addHarmonisationData(),this.productCollection.get('harmonisedName').reset(),modal.close('Close click')]" (keydown.enter)="$event.preventDefault()">
    <div class="modal-body">
        <div class="input-container w-100 mb-3">
            <label class="mb-2" for="harmonisedName" translate>Harmonised {{harmonisationType |titlecase}} Name<sup>*</sup></label>
            <input type="text" formControlName="harmonisedName" name="harmonisedName" placeholder="Enter harmonised {{harmonisationType}} name" 
                id="harmonisedName" class="w-100" [ngClass]="{error: productCollection.controls['harmonisedName'].invalid && (productCollection.controls['harmonisedName'].dirty || productCollection.controls['harmonisedName'].touched) }">
            <div class="errorMsg" *ngIf="productCollection.controls['harmonisedName'].invalid && (productCollection.controls['harmonisedName'].dirty || productCollection.controls['harmonisedName'].touched)">
                <span *ngIf="productCollection.controls['harmonisedName'].errors.required" translate>Please enter harmonised {{harmonisationType}} name</span>
                <span *ngIf="productCollection.controls['harmonisedName'].errors.fieldMessage">{{(productCollection.controls['harmonisedName'].errors.fieldMessage) | translate}}</span>
            </div>
        </div>
        <div *ngIf="this.harmonisationType === 'product'" class="harmonisation-filter mb-3 mt-2">
            <ng-container *ngFor="let filter of filters">
                <div class="mb-3" *ngIf="productFilters[filter].showFilter">
                    <label class="mb-2" translate>{{productFilters[filter].label}}</label>
                    <div class="d-flex gap-8 align-items-center w-100">
                        <ng-select 
                            class="select-text-wrap" [notFoundText]="'No Data Found' | translate"
                            [loadingText]="'Loading...' | translate" [items]="productFilters[filter].list"
                            [placeholder]="('Select ' + productFilters[filter].placeholder) | translate" bindLabel="attributes.name"  formControlName="{{filter}}" [loading]="productFilters[filter].loading" [closeOnSelect]="productFilters[filter].closeOnSelect" [clearable]="productFilters[filter].clear" (change)="onChange(filter === 'product_segment' ? 'old' : '')"> 
                        </ng-select>
                        <div *ngIf="filter === 'product_segment'" class="d-flex gap-8 align-items-center w-50">
                            <span>or</span>
                            <div class="input-container w-100">
                                <input *ngIf="filter === 'product_segment'" formControlName="new_product_segment" type="text"  name="new_product_segment"  (keyup)="onKeyEnter()" placeholder="Add new product segment" (change)="onChange('new')" [ngClass]="{ error: productCollection.controls['new_product_segment'].invalid}">
                                <div class="errorLable">
                                    <span *ngIf="productCollection.controls['new_product_segment'].invalid && productCollection.controls['new_product_segment'].errors.fieldMessage" translate>{{productCollection.controls['new_product_segment'].errors.fieldMessage}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary btn-lg" (click)="modal.close('Close click')"
            translate>Cancel</button>
        <button type="submit" class="btn btn-primary btn-lg"
            [disabled]="productCollection.controls['harmonisedName'].invalid || productCollection.controls['new_product_segment'].invalid" translate>Add {{harmonisationType |
            titlecase}}</button>
    </div>
    </form>
</ng-template>


<div *ngIf="isMappingPanelOpen">
    <div class="custom-right-panel">
        <div class="overlay"></div>
        <div class="right-panel">
            <app-harmonisation-mapping [harmonisationType]="harmonisationType" (closePanel)="onRightPanelEmit($event)"></app-harmonisation-mapping>
        </div>
    </div>

</div>