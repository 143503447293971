<div class="product-info-container" *ngIf="response">
    <table>
        <tbody>
            <tr>
                <td rowspan="2">
                    <section class="d-flex flex-column position-relative">
                        <span class="badge bg-store cursor-pointer ellipsis"
                            (click)="commonFunctions?.redirectToScorecardInfo(response.store_id,'store')" showTooltipOnTextOverflow [data]="response?.store"></span>
                        <span [attr.data-test]="'product-modal'" class="product-image position-relative"
                            (click)="openModal(content)">
                            <img class="cursor-pointer" *ngIf="response?.images && response?.images?.length>0" src="{{response.images[0]}}"
                                alt="">
                            <img class="cursor-pointer" *ngIf="!response?.images || response?.images?.length==0"
                                src="assets/images/no-image.svg" alt="">
                            <span *ngIf="response?.images && response?.images?.length>1"
                                class="additional-variant cursor-pointer">+{{(response?.images?.length-1)}}</span>
                        </span>
                    </section>
                </td>
                <td>
                    <section class="d-flex flex-column">
                        <span class="header" translate>Product</span>
                        <div class="d-flex align-items-center position-relative">
                            <a class="common-text product-name" [routerLink]="[commonFunctions?.redirectToScorecardInfo(response.product_id,'product',true)?.params]"
                                [target]="'_blank'" [ngClass]="headerType!='scorecard' && 'on-hover-action'" showMultiTooltipOnTextOverflow
                                [data]="response?.name"></a>
                            <a href={{response?.url}} target="_blank" class='navigate-link' placement="bottom"
                                ngbTooltip="{{'Go to Product Channel' | translate}}" tooltipClass="info-custom-tooltip"><i
                                    class="icon icon-Arrow-up-2 d-inline-block"></i></a>
                        </div>
                        <div class="content-top position-relative d-flex flex-column">
                            <div class="showVariants">
                                <div ngbDropdown class="d-inline-block" placement="bottom-left">
                                    <span [attr.data-test]="'show-variant'" id="dropdownBasic1" ngbDropdownToggle
                                        class="cursor-pointer on-hover-action" *ngIf="response?.variant_count"
                                        (click)="getVariantsList(response?.product_id)">{{'In' | translate}} {{(response?.variant_count)}}
                                        {{'Variants' | translate}}</span>
                                    <span id="dropdownBasic1" ngbDropdownToggle class="cursor-pointer"
                                        *ngIf="!response?.variant_count">&nbsp;</span>
                                    <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="variantDropdownList infoVariantDropdown"
                                        *ngIf="variantsList && variantsList?.length>0">
                                        <a class="dropdown-item" *ngFor="let variant of variantsList"
                                            [routerLink]="[commonFunctions?.redirectToScorecardInfo(variant.variant_id,'product',true)?.params]"
                                            [target]="'_blank'"><span>{{variant?.variant_name}}</span></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </td>
                <td colspan="3">
                    <section class="d-flex flex-column">
                        <span class="header" translate>Description</span>
                        <div class="desc cursor-pointer" (click)="descriptionReadMore()" *ngIf="response?.description;else showHyphen">
                            <ng-container *ngIf="response?.isExplicitDescription;else showNormalDescription">
                                <p>View Explicit Description</p>
                            </ng-container>
                            <ng-template #showNormalDescription>
                                {{response?.description | slice:0:75}}<span>...</span><span class="read-more">Read More</span>
                            </ng-template>
                        </div>
                    </section>
                </td>
                <td>
                    <section class="d-flex flex-column">
                        <span class="header" translate>Price</span>
                        <span class="common-text" *ngIf="commonFunctions.isIntegerOrDefined(response.price);else showHyphen">{{response.currency?.symbol}}{{response.price | numberSuffix: 2}}</span>
                    </section>
                </td>
                <td>
                    <section class="d-flex flex-column">
                        <span class="header" translate>Discount</span>
                        <span class="voucher-badge me-0" *ngIf="response?.discount != null;else showHyphen">{{response?.discount}}%
                            {{'OFF' | translate}}</span>
                    </section>
                </td>
                <td>
                    <section class="d-flex flex-column">
                        <span class="header" translate>Unit Sold</span>
                        <span class="common-text mb-1" *ngIf="commonFunctions.isIntegerOrDefined(response?.unit_sold);else showHyphen">{{response.unit_sold | numberSuffix: 2}}</span>
                    </section>
                </td>
                <td>
                    <section class="d-flex flex-column">
                        <span class="header" translate>Ratings</span>
                        <ng-template #starLabel>
                            <span class="star">&#x02605;</span>
                        </ng-template>
                        <div class="ratingLabel" [ngClass]="commonFunctions.getStarStatus(response?.rating)"
                            *ngIf="response?.rating;else showHyphen">
                            {{response?.rating}}<ngb-rating [max]="1" [starTemplate]="starLabel" [rate]="1"></ngb-rating>
                        </div>
                    </section>
                </td>
                <td>
                    <section class="d-inline-flex" *ngIf="response?.product_id===response?.parent_id">
                        <app-scorecard-actions [bannerData]="response" [profileObj]="profileObj" [noteObj]="noteObj" [scorecardType]="'product'"></app-scorecard-actions>
                    </section>
                </td>
            </tr>
            <tr>
                <td>
                    <section class="d-flex flex-column">
                        <span class="header" translate>Channel</span>
                        <div class="market-logo d-flex align-items-center gap-8"
                            *ngIf="response?.marketplace;else showHyphen">
                            <app-marketplace-store-logo [storeName]="response?.marketplace"></app-marketplace-store-logo>
                            <span class="common-text">{{response?.marketplace}}</span>
                        </div>
                    </section>
                </td>
                <td>
                    <section class="d-flex flex-column">
                        <span class="header" translate>Status</span>
                        <div class="labels d-flex" *ngIf="response?.tags?.length>0;else showHyphen">
                            <label *ngFor="let tag of response?.tags" class="badge badge-dark">{{tag}}</label>
                        </div>
                    </section>
                </td>
                <td>
                    <section class="d-flex flex-column">
                        <span class="header" translate>Category</span>
                        <div *ngIf="response?.category;else showHyphen" class="common-text categories ellipsis" showTooltipOnTextOverflow [data]="response?.category"></div>
                    </section>
                </td>
                <td colspan="3">
                    <section class="d-flex flex-column">
                        <span class="header" translate>Tracked by</span>
                        <ng-container *ngIf="response?.tracked_by?.length;else showHyphen">
                            <div class="tracked-by-parent d-flex align-items-center main-content" ngbDropdown display="dynamic">
                                <div>{{(response?.tracked_by[0].profile_type.split('')[0]|uppercase)}}</div>
                                <div class="separator">:</div>
                                <div class="profile-name text-truncate"
                                    [ngClass]="{'single-profile-name-width':response?.tracked_by.length===1}"
                                    showTooltipOnTextOverflow [data]="response?.tracked_by[0].profile_name"
                                    id="scorecardTrackedBy"></div>
                                <span class="link cursor-pointer" id="dropdownBasic1" ngbDropdownToggle
                                    *ngIf="response?.tracked_by.length>1">+{{response?.tracked_by.length - 1}}</span>
                                <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="commonDropdown customTooltipPositionContainer">
                                    <ng-container *ngFor="let tag of response?.tracked_by.slice(1);let last = last">
                                        <div class="tracked-by-parent d-flex align-items-center child-content" [ngClass]="{'mb-1':!last}">
                                            <div>{{tag.profile_type.split('')[0]|uppercase}}</div>
                                            <div class="separator">:</div>
                                            <div class="profile-name text-truncate dropdown-profile-name-width customTooltipPosition"
                                                showTooltipOnTextOverflow [data]="tag.profile_name"></div>
                                        </div>
                                    </ng-container>
                
                                </div>
                            </div>
                        </ng-container>
                    </section>
                </td>
                <td colspan="3">
                    <section class="d-flex flex-column">
                        <span class="header" translate>Last Updated</span>
                        <span class="common-text mb-2"
                            *ngIf="response?.updated_at;else showHyphen">{{commonFunctions.formatSimpleDate(response?.updated_at)}}</span>
                    </section>
                </td>
            </tr>
            
        </tbody>
    </table>
</div>
<footer class="product-info-end d-flex justify-content-between" *ngIf="moduleType=='scorecard' && response.segment==='OS'">
    <div class="product-info-end-content d-flex text-align-right-rtl gap-16">
        <span class="img-content d-flex align-items-center justify-content-center"><i class="icon icon-Shuffle"></i></span>
        <div class="product-info-end-1-item-1 text-align-right-rtl">
            <span>{{scorecardMatchingsCountRes?.competitors}} </span>
            {{'matches from competitors' | translate}}, 
            <span>{{scorecardMatchingsCountRes?.direct}}</span>
            {{'direct product mapping' | translate}}
            <p>{{'Manage upto' | translate}} 5 {{'products' | translate}}</p>
        </div>
    </div>
    <div class="product-info-end-content d-flex align-items-center justify-content-between gap-16" *ngIf="response?.product_id===response?.parent_id">
        <button [disabled]="!permissionsForDiscover.read_access" class="btn btn-secondary btn-lg"
        (click)="commonFunctions.openDiscover(response?.product_id, true)" translate>See Matches</button>
        <button [disabled]="!permissionsForBuild.read_access" class="btn btn-secondary btn-lg"
            (click)="openDirectMapping(response?.product_id)" translate>Manage Direct Mapping</button>
    </div>
</footer>
<ng-template #showHyphen>-</ng-template>
<!-- Modal Template -->
<ng-template #content let-modal>
    <div class="modal-header-product">
        <div class="start-content">
            <div class="modal-title text-align-right-rtl" id="modal-basic-title" ngbAutofocus>{{response.name}}</div>
            <span>{{variantSelected}}</span>
        </div>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <i class="icon icon-Close2"></i>
        </button>
    </div>
    <div class="modal-body-product" [ngClass]="{'singleImage':response.images?.length === 1}">
        <ngb-carousel [showNavigationIndicators]="response.images?.length !== 1" [interval]="0">
            <ng-template ngbSlide *ngFor="let image of response.images">
                <div class="image-wrapper d-flex align-items-center justify-content-center">
                    <img [src]="image" alt="Random first slide">
                </div>
            </ng-template>
        </ngb-carousel>

    </div>
</ng-template>