import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { CommonFunctionsService } from "@ds-common-services/utility-services/common-functions.service";
import { DataTransferService } from "@ds-shared/common-services/utility-services/data-transfer.service";

@Component({
	selector: "app-common-piechart-slicing",
	templateUrl: "./common-piechart-slicing.component.html",
	styleUrls: ["./common-piechart-slicing.component.scss"]
})
export class CommonPiechartSlicingComponent implements OnInit {
	@Input() data = [];
	@Input() chartName;
	@Input() type;
	@Output() spliceFilterParamEvent = new EventEmitter<any>();
	public selectedData = [];
	@Input() isSelectedKeywordChanged;
	@Input() localFilter;
	public isBtnDisabled: boolean = false;
	constructor(
		private commonFunctions: CommonFunctionsService,
		private dataTransferService: DataTransferService
	) {}

	ngOnInit(): void {}
	ngOnChanges() {
		if (this.data?.length) {
			this.selectedData = this.data.map((item) => item.id);
			this.isBtnDisabled = false;
		}
	}

	public onPieSpliceSelect(eventDetails, i) {
		if (eventDetails.target.checked) this.selectedData.push(i);
		else this.selectedData = this.selectedData.filter((item) => item !== i);
		if (this.data.length !== this.selectedData.length) {
			this.isBtnDisabled = true;
		}
	}

	public onApply() {
		let res = [];
		let total = 0;
		this.data.forEach((item) => {
			if (this.selectedData.includes(item.id)) {
				res.push(item);
				total = total + item.value;
			}
		});
		res = res.map((item) => {
			return {
				...item,
				y: parseFloat(((item.value / total) * 100).toFixed(2))
			};
		});
		this.spliceFilterParamEvent.emit(res);
	}
	public onReset() {
		this.selectedData = this.data.map((item) => item.id);
		this.onApply();
	}
}
