import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
	selector: "app-cards",
	templateUrl: "./cards.component.html",
	styleUrls: ["./cards.component.scss"]
})
export class CardsComponent implements OnInit {
	private _cardData;
	get cardData() {
		return this._cardData;
	}
	@Input() set cardData(value) {
		this._cardData = value;

	}
	@Input() cardInfo = {
		title: "Card",
		customerTitle: "Your",
		competitorTitle: "Competitor",
	};
	@Input() cardName;
	@Output() public cardClicked = new EventEmitter();

	constructor() {}

	ngOnInit(): void {}
	ngOnChanges() {}
	
	public onCardClicked(eve){
		this.cardClicked.emit(true)
	}
}
