import moment from "moment";

export enum MarketShareTimelineType{
    'Yesterday' = 'yesterday',
    'Last 7 Days' = 'current_week',
    'Last 30 Days' = 'current_month',
    'This Month' = 'this_month',
    'Last 60 Days' = 'last_60_days',
    'Last 90 Days' = 'last_90_days',
    "Previous Week" = 'previous_week',
    'Today' = 'today',
    'Current Week' = 'current_week',
    "Previous Month" = 'previous_month',
    'Current Month' = 'current_month',
    'Quarterly Trend' = 'last_90_days'
}




