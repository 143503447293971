/* eslint-disable */
import { Component, Input, OnInit, SimpleChanges } from "@angular/core";
import * as Highcharts from "highcharts/highstock";
import { DataTransferService } from "@ds-common-services/utility-services/data-transfer.service";
import { LineChartModel } from "@ds-models/charts.model";
import { Subject, filter, takeUntil } from "rxjs";
import { CommonFunctionsService } from "@ds-shared/common-services/utility-services/common-functions.service";
import { TOOLTIPCOLOR } from "@ds-shared/enums/common.enum";
import moment from 'moment';
import { SAVE_WIDGET } from "@ds-shared/enums/reporting.enum";
let componentContext;

@Component({
	selector: "app-spline-chart",
	templateUrl: "./spline-chart.component.html",
	styleUrls: ["./spline-chart.component.scss"]
})
export class SplineChartComponent implements OnInit {
	@Input() public data: LineChartModel[];
	@Input() public categories;
	@Input() public isMultipleYaxis = false;
	@Input() public leftYAxisTitle: string | boolean = "";
	@Input() public rightYAxisTitle: string | boolean = "";
	@Input() public caller = "default";
	@Input() public scrollbarThreshold = 12;
	@Input() public tooltipShared = true;
	@Input() public chartObj: { spacing?: number[]; margin?: number[] } = {
		spacing: [55, 0, 5, 0]
	};
	@Input() public backgroundColor = 'transparent';
	@Input() public leftYaxisOffset = -14;
	@Input() public rightYaxisOffset = -34;
	@Input() public customAlign = "high";
	@Input() public customRotation = "0";
	@Input() public customRotationY = "0";
	@Input() public isMarkerNeeded = false;
	@Input() public isDate;
	@Input() public xAxisRotationAngle: number = 0;
	@Input() public callFrom: string = '';
	@Input() public tooltipSplit:boolean = false;
	@Input() public chartCursor: string = 'pointer';
	private destroy$: Subject<boolean> = new Subject();
	public Highcharts: typeof Highcharts = Highcharts; // required
	public highchartsData: typeof Highcharts;
	chartOptions;
	chartRef;
	readonly MONTHS = [
		"Jan",
		"Feb",
		"Mar",
		"Apr",
		"May",
		"Jun",
		"Jul",
		"Aug",
		"Sep",
		"Oct",
		"Nov",
		"Dec"
	];
	constructor(
		private dataTransferService: DataTransferService,
		private commonFunctions: CommonFunctionsService
	) {
		this.dataTransferService.secondaaryMenuToggleSignal$
			.pipe(
				filter((res) => res),
				takeUntil(this.destroy$)
			)
			.subscribe((res) => {
				setTimeout(() => {
					this.chartRef.reflow();
				}, 0);
			});
	}

	ngOnChanges(){
		setTimeout(() => {
			this.chartRef.yAxis[0].axisTitle?.attr({
				text: this.leftYAxisTitle
			});
			if(this.isMultipleYaxis){
				this.chartRef.yAxis[1].axisTitle?.attr({
					text: this.rightYAxisTitle
				});
			}
			this.chartRef.reflow();
		}, 0);
	   
		}

	ngOnInit(): void {
		componentContext = this;
		Highcharts.setOptions({
			lang: {
				// thousandsSep: ","
			}
		});
	}
	ngAfterViewInit(): void {
		this.renderSplineChart();
	}

	chartCallback: Highcharts.ChartCallbackFunction = (chart) => {
		this.chartRef = chart;
	};
	private renderSplineChart() {
		this.highchartsData = Highcharts;
		this.chartOptions = {
			chart: {
				renderTo: 'container',
				events: {
					load: () => {
						document
							.querySelectorAll(".highcharts-scrollbar-button")
							.forEach((el) => {
								el.remove();
							});
						document
							.querySelectorAll(".highcharts-scrollbar-arrow")
							.forEach((el) => {
								el.remove();
							});
					}
				},
				...this.chartObj,
				backgroundColor:this.backgroundColor
			},
			title: {
				text: ""
			},
			legend: {
				enabled: false,
				symbolWidth: 16,
				symbolRadius: 0,
				squareSymbol: true
			},
			xAxis: {
				type: "category",
				categories: this.categories,
				gridLineWidth: 0,
				lineWidth: 0,
				tickWidth: 0,
				labels: {
					enabled: true,
					formatter: (ev: any) => {
							if (componentContext.commonFunctions.isDateStringValid(ev.value)) {
								return `${
									this.MONTHS[new Date(ev.value).getMonth()] +
									" " +
									new Date(ev.value).getDate()
								} `;
							}
							else {

								if (componentContext.caller === "rating-trend") {
									return ev.value;
								}
								if (componentContext.caller === "category-BrandMarketShareGMV" || componentContext.caller === "category-BrandMarketShareQTY") {
									return 'Week of ' + ev.value;
								}
							    if(!componentContext.isDate) {
									var shortText = ev.value.toString().substring(0, 10) + '...';
									return shortText;
								}
								return ev.value;
							}

						 // clean, unformatted number for year
					},
					style: {
						fontFamily: "Poppins Regular",
						fontWeight: "400",
						fontSize: "0.6875rem",
						color: "#8D9199"
					},
					rotation: this.xAxisRotationAngle
				},
				scrollbar: {
					enabled: this.categories?.length > this.scrollbarThreshold,
					barBackgroundColor: "#aaaaaae5",
					barBorderRadius: 7,
					barBorderWidth: 0,
					buttonBackgroundColor: "#B3B3B3",
					buttonBorderWidth: 0,
					buttonBorderRadius: 7,
					trackBackgroundColor: "none",
					trackBorderWidth: 0,
					trackBorderRadius: 8,
					trackBorderColor: "#CCC",
					rifleColor: "transparent"
				},
				tickLength: 0,
				tickInterval: 0,
				lineColor: "transparent",
				gridLineColor: "transparent",
				max:
					this.categories?.length > this.scrollbarThreshold
						? this.scrollbarThreshold - 1
						: this.categories?.length - 1
			},
			yAxis: this.isMultipleYaxis
				? [
						{
							gridLineDashStyle: "dash",
							minPadding: 0,
							maxPadding: 0,
							gridLineColor: "#f5f5f5",
							title: {
								text: this.leftYAxisTitle,
								align: this.customAlign,
								rotation: this.customRotation,
								y: -30,
								// x:-20,
								offset: this.leftYaxisOffset,
								style: {
									fontFamily: "Poppins Regular",
									fontSize: "0.625rem",
									fontWeight: "400",
									letterSpacing: "0.01em",
									color: "#727487",
									marginRight: "5px"
								}
							},
							labels: {
								enabled: true,
								formatter: (ev) => {
									return this.commonFunctions.getSuffixedNumberFormat(ev.value);
								},
								style: {
									fontFamily: "Poppins Regular",
									fontWeight: "400",
									color: "#8D9199",
									fontSize: "0.625rem"
								}
							}
						},
						{
							gridLineDashStyle: "dash",
							title: {
								text: this.rightYAxisTitle,
								align: this.customAlign,
								rotation: this.customRotationY,
								y: -30,
								offset: this.rightYaxisOffset,
								style: {
									fontFamily: "Poppins Regular",
									fontSize: "0.625rem",
									fontWeight: "400",
									letterSpacing: "0.01em",
									color: "#727487"
								}
							},
							labels: {
								enabled: true,
								formatter: this.oppositeYAxisFormatter,
								style: {
									fontFamily: "Poppins Regular",
									fontWeight: "400",
									color: "#8D9199",
									fontSize: "0.625rem"
								}
							},
							opposite: true
						}
				  ]
				: {
						gridLineDashStyle: "dash",
						title: {
							text: this.leftYAxisTitle,
							style: {
								fontFamily: "Poppins Regular",
								fontSize: "0.75rem",
								fontWeight: "400",
								letterSpacing: "0.01em",
								color: "#9BA1AC"
							}
						},
						labels: {
							enabled: true,
							style: {
								fontFamily: "Poppins Regular",
								fontWeight: "400",
								color: "#8D9199",
								fontSize: "0.6875rem"
							},
							formatter: this.yAxisFormatter
						}
				  },
			tooltip: {
				enabled: true,
				split: this.tooltipSplit,
				backgroundColor: undefined,
				borderColor: undefined,
				borderWidth: 0,
				shadow: true,
				formatter: this.toolTipFormatter,
				shared: this.tooltipShared,
				useHTML: true,
				hideDelay:5000
			},
			plotOptions: {
				spline: {
					events: {
						click: (ev) => {
							this.dataTransferService.sendGraphSliceData({
								...ev.point.series.userOptions,
								category: ev.point.category
							});
						}
					}
				},
				series: {
					pointPadding: 0,
					cursor: componentContext.chartCursor,
					dataLabels: {
						enabled: true,
						formatter: (ev) => {
							if (ev.value?.includes("-")) {
								return `${
									this.MONTHS[new Date(ev.value).getMonth()] +
									" " +
									new Date(ev.value).getDate()
								}`;
							}
							return ev.value; // clean, unformatted number for year
						}
					},
					lineWidth: 1.8,
					marker: {
						enabled: this.isMarkerNeeded,
						symbol: "circle",
						shadow: false,
						radius: 4,
						states: {
							hover: {
								enabled: true
							}
						}
					}
				}
			},
			series: this.data,
			credits: false
		};

		// this.chartRef.reflow();
	}

	toolTipFormatter() {
		const dataLabel = this as Highcharts.DataLabelsOptions;
		const caller = componentContext.caller === SAVE_WIDGET ? dataLabel['points'][0]['point']['series']['userOptions']['caller'] : componentContext.caller === 'marketSharePriceElasticityTrend'? dataLabel['series']['options']['caller']: componentContext.caller;
		switch (caller) {
			case "pricing":
				const symbol = dataLabel['series']['userOptions']['currency']['symbol']
				return `<div
    style="background-color:${TOOLTIPCOLOR["BG"]};min-width:5.5rem;border-radius:0.375rem;height:5rem;display:flex;flex-direction: column;padding:0.625rem 0.875rem;border:0.0625rem solid #eaedf3;margin:0;font-family:Poppins Regular;font-weight:400;row-gap:0.5rem;">
    <p style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.625rem;margin:0">${dataLabel["x"].toString().includes(":")
						? "at " + componentContext.tConvert(dataLabel["x"])
						: "on " +
						componentContext.MONTHS[new Date(dataLabel["x"]).getMonth()] +
						" " +
						new Date(dataLabel["x"]).getDate()
					}</p>
    <div style="display:flex;gap:1rem;flex-direction:row;justify-content:space-between">
	${dataLabel["series"]["userOptions"]["caller"] === "price" ? `<div style="display:flex;flex-direction: column;">
            <span style="color:${TOOLTIPCOLOR["VALUE"]};font-size:0.875rem;">${symbol ? symbol : ''}${componentContext.commonFunctions.getSuffixedNumberFormat(dataLabel.y)}</span>
            <span style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.875rem;">${componentContext.commonFunctions.getSpecificTranslation('Price')}</span>
        </div>` :
        `<div style="display:flex;flex-direction: column;">
            <span style="color:${TOOLTIPCOLOR["VALUE"]};font-size:0.875rem;">${dataLabel.y}%</span>
                    <span style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.875rem;">${componentContext.commonFunctions.getSpecificTranslation('Discount')}</span>
        </div>`}
    </div>
</div>`;
			case "productDailySales":
				return `<div style="display:flex;flex-direction:column;background-color:${TOOLTIPCOLOR["BG"]};min-width:10rem;border-radius:0.375rem;padding:0.5rem 0.625rem;border:0.0625rem solid #eaedf3;margin:0;font-family:Poppins Regular;font-weight:400;color:${TOOLTIPCOLOR["TEXT"]}">
			 				<div style="font-size:0.625rem;">on ${dataLabel.x} (${
					dataLabel["series"]["userOptions"]["name"]
				})</div>
							<div style="display:flex;flex-direction: column;margin-top:0.5rem">
								<span style="color:${TOOLTIPCOLOR["VALUE"]};font-size:0.875rem;">${
									dataLabel["series"]["userOptions"]["criterion"] === "sales"
										? componentContext.commonFunctions.currencySelected
												.attributes.symbol
										: ""
								}${componentContext.commonFunctions.getSuffixedNumberFormat(
					dataLabel.y
				)}</span>
								<span style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.875rem;">${
									dataLabel["series"]["userOptions"]["criterion"] === "sales"
										? componentContext.commonFunctions.getSpecificTranslation("Price")
										: componentContext.commonFunctions.getSpecificTranslation("Quantity")
								}</span>
							</div>
						</div>`;

			case "pricingHistory":
				return `<div style="display:flex;flex-direction:column;background-color:${TOOLTIPCOLOR["BG"]};min-width:10rem;border-radius:0.375rem;padding:0.5rem 0.625rem;border:0.0625rem solid #eaedf3;margin:0;font-family:Poppins Regular;color:${TOOLTIPCOLOR["TEXT"]}">
			 				<div style="font-size:0.625rem;">on ${dataLabel.x}</div>
							<div style="display:flex;flex-direction: column;margin-top:0.5rem">
								<span style="color:${TOOLTIPCOLOR["VALUE"]};font-size:0.875rem;">${
					["price", "gmv"].includes(dataLabel["series"]["userOptions"]["caller"])
										? dataLabel['series']["userOptions"]["currency"]
										: ""
								}${componentContext.commonFunctions.getSuffixedNumberFormat(
					dataLabel.y
				)}${
					dataLabel["series"]["userOptions"]["caller"] === "discount" ? "%" : ""
				}</span>
								<span style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.875rem;">${
									componentContext.commonFunctions.getSpecificTranslation(dataLabel["series"]["userOptions"]["name"])
								}</span>
							</div>
						</div>`;
			case "sellout":
				const sellout_symbol =
								dataLabel["series"]["userOptions"]["currency"]["symbol"];
				return `<div
				style="background-color:${TOOLTIPCOLOR["BG"]};min-width:5.5rem;border-radius:0.375rem;height:5rem;display:flex;flex-direction: column;padding:0.625rem 0.875rem;border:0.0625rem solid #eaedf3;margin:0;font-family:Poppins Regular;font-weight:400;row-gap:0.5rem;">
				<p style="color:${TOOLTIPCOLOR["VALUE"]};font-size:0.625rem;margin:0">${
					dataLabel["x"].toString().includes(":")
						? "at " + componentContext.tConvert(dataLabel["x"])
						: "on " +
						  componentContext.MONTHS[new Date(dataLabel["x"]).getMonth()] +
						  " " +
						  new Date(dataLabel["x"]).getDate()
				}</p>
				<div style="display:flex;gap:1rem;flex-direction:row;justify-content:space-between">
				${dataLabel["series"]["userOptions"]["caller"] === "sales" ?
					`<div style="display:flex;flex-direction: column;">
						<span style="color:${TOOLTIPCOLOR["VALUE"]};font-size:0.875rem;">${
							sellout_symbol ? sellout_symbol : ""
						}${componentContext.commonFunctions.getSuffixedNumberFormat(
					dataLabel.y
				)}</span>
						<span style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.875rem;">${componentContext.commonFunctions.getSpecificTranslation('Sales')}</span>
					</div>` :
					`<div style="display:flex;flex-direction: column;">
						<span style="color:${TOOLTIPCOLOR["VALUE"]};font-size:0.875rem;">${
							componentContext.commonFunctions.getSuffixedNumberFormat(dataLabel.y)
						}</span>
								<span style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.875rem;">${componentContext.commonFunctions.getSpecificTranslation('Quantity Sold')}</span>
					</div>`
					}
				</div>
			</div>`;
			case "xSimpleYSimple":
				return `<div
				style="background-color:${TOOLTIPCOLOR["BG"]};min-width:${componentContext.isMultipleYaxis ? '10rem' : '7rem'};border-radius:0.375rem;height:5rem;display:flex;flex-direction: column;padding:0.625rem 0.875rem;border:0.0625rem solid #eaedf3;margin:0;font-family:Poppins Regular;font-weight:400;row-gap:0.5rem;">
				<p style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.625rem;margin:0">${
					(componentContext.isDate || dataLabel['points'][0]['series']['userOptions']['isDate'] )?
					dataLabel["x"].toString().includes(":")
						? "at " + componentContext.tConvert(dataLabel["x"])
						: "on " +
						  componentContext.MONTHS[new Date(dataLabel["x"]).getMonth()] +
						  " " +
						  new Date(dataLabel["x"]).getDate() : dataLabel["x"]
				}</p>
				<div style="display:flex;gap:1rem;flex-direction:row;justify-content:space-between">
					<div style="display:flex;flex-direction: column;">
						<span style="color:${TOOLTIPCOLOR["VALUE"]};font-size:0.875rem;">${componentContext.commonFunctions.getSuffixedNumberFormat(
							dataLabel?.y
						)}</span>
						<span style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.875rem;">${
							componentContext.caller === SAVE_WIDGET ? componentContext.commonFunctions.getSpecificTranslation(dataLabel['points'][0]['series']['userOptions']['name']) : componentContext.commonFunctions.getSpecificTranslation(componentContext.leftYAxisTitle)
						}</span>
					</div>
					<div style="display:flex;flex-direction: column;">
						<span style="color:${TOOLTIPCOLOR["VALUE"]};font-size:0.875rem;">${componentContext.commonFunctions.getSuffixedNumberFormat(
							dataLabel["points"][1]?.y
						)}</span>
								<span style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.875rem;">${
									componentContext.caller === SAVE_WIDGET ? componentContext.commonFunctions.getSpecificTranslation(dataLabel['points'][1]['series']['userOptions']['name']) :componentContext.commonFunctions.getSpecificTranslation(componentContext.rightYAxisTitle)
								}</span>
					</div>
				</div>
			</div>`;
			case "xUnitYUnit":
				return `<div
				style="background-color:${TOOLTIPCOLOR["BG"]};min-width:${componentContext.isMultipleYaxis ? '10rem' : '7rem'};border-radius:0.375rem;height:5rem;display:flex;flex-direction: column;padding:0.625rem 0.875rem;border:0.0625rem solid #eaedf3;margin:0;font-family:Poppins Regular;font-weight:400;row-gap:0.5rem;">
				<p style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.625rem;margin:0">
				${
					(componentContext.isDate || dataLabel['points'][0]['series']['userOptions']['isDate'])?
					dataLabel["x"].toString().includes(":")
						? "at " + componentContext.tConvert(dataLabel["x"])
						: "on " +
						  componentContext.MONTHS[new Date(dataLabel["x"]).getMonth()] +
						  " " +
						  new Date(dataLabel["x"]).getDate()
						  : dataLabel["x"]
				}
				</p>
				<div style="display:flex;gap:1rem;flex-direction:row;justify-content:space-between">
					<div style="display:flex;flex-direction: column;">
						<span style="color:${TOOLTIPCOLOR["VALUE"]};font-size:0.875rem;">${componentContext.commonFunctions.getSuffixedNumberFormat(
							dataLabel.y
						)} units</span>
						<span style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.875rem;">${
							componentContext.caller === SAVE_WIDGET ? componentContext.commonFunctions.getSpecificTranslation(dataLabel['points'][0]['series']['userOptions']['name']) : componentContext.commonFunctions.getSpecificTranslation(componentContext.leftYAxisTitle)
						}</span>
					</div>
					<div style="display:flex;flex-direction: column;">
						<span style="color:${TOOLTIPCOLOR["VALUE"]};font-size:0.875rem;">${componentContext.commonFunctions.getSuffixedNumberFormat(
							dataLabel["points"][1]?.y
						)} units</span>
								<span style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.875rem;">${
									componentContext.caller === SAVE_WIDGET ? componentContext.commonFunctions.getSpecificTranslation(dataLabel['points'][1]['series']['userOptions']['name']) : componentContext.commonFunctions.getSpecificTranslation(componentContext.rightYAxisTitle)
								}</span>
					</div>
				</div>
			</div>`;
			case "xCurrencyYCurrency":
				const adHocValueWithCurrency_symbol =
					dataLabel["points"][0]["series"]["userOptions"]["currency"]["symbol"];
				return `<div
				style="background-color:${TOOLTIPCOLOR["BG"]};min-width:${componentContext.isMultipleYaxis ? '10rem' : '7rem'};border-radius:0.375rem;height:5rem;display:flex;flex-direction: column;padding:0.625rem 0.875rem;border:0.0625rem solid #eaedf3;margin:0;font-family:Poppins Regular;font-weight:400;row-gap:0.5rem;">
				<p style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.625rem;margin:0">${
					(componentContext.isDate || dataLabel['points'][0]['series']['userOptions']['isDate']) ?
					dataLabel["x"].toString().includes(":")
						? "at " + componentContext.tConvert(dataLabel["x"])
						: "on " +
						  componentContext.MONTHS[new Date(dataLabel["x"]).getMonth()] +
						  " " +
						  new Date(dataLabel["x"]).getDate()
						  : dataLabel["x"]
				}</p>
				<div style="display:flex;gap:1rem;flex-direction:row;justify-content:space-between">
					<div style="display:flex;flex-direction: column;">
						<span style="color:${TOOLTIPCOLOR["VALUE"]};font-size:0.875rem;">${
							adHocValueWithCurrency_symbol ? adHocValueWithCurrency_symbol : ""
						}${componentContext.commonFunctions.getSuffixedNumberFormat(
					dataLabel?.y
				)}</span>
						<span style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.875rem;">${
							componentContext.caller === SAVE_WIDGET ? componentContext.commonFunctions.getSpecificTranslation(dataLabel['points'][0]['series']['userOptions']['name']) : componentContext.commonFunctions.getSpecificTranslation(componentContext.leftYAxisTitle)
						}</span>
					</div>
					<div style="display:flex;flex-direction: column;">
						<span style="color:${TOOLTIPCOLOR["VALUE"]};font-size:0.875rem;">${
							adHocValueWithCurrency_symbol ? adHocValueWithCurrency_symbol : ""
						}${componentContext.commonFunctions.getSuffixedNumberFormat(
					dataLabel["points"][1]?.y
				)}</span>
								<span style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.875rem;">${
									componentContext.caller === SAVE_WIDGET ? componentContext.commonFunctions.getSpecificTranslation(dataLabel['points'][1]['series']['userOptions']['name']) : componentContext.commonFunctions.getSpecificTranslation(componentContext.rightYAxisTitle)
								}</span>
					</div>
				</div>
			</div>`;
			case "xCurrencyYUnit":
				const xCurrencyYUnit_symbol =
					dataLabel["points"][0]["series"]["userOptions"]["currency"]["symbol"];
				return `<div
				style="background-color:${TOOLTIPCOLOR["BG"]};min-width:${componentContext.isMultipleYaxis ? '10rem' : '7rem'};border-radius:0.375rem;height:5rem;display:flex;flex-direction: column;padding:0.625rem 0.875rem;border:0.0625rem solid #eaedf3;margin:0;font-family:Poppins Regular;font-weight:400;row-gap:0.5rem;">
				<p style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.625rem;margin:0">${
					(componentContext.isDate || dataLabel['points'][0]['series']['userOptions']['isDate']) ?
					dataLabel["x"].toString().includes(":")
						? "at " + componentContext.tConvert(dataLabel["x"])
						: "on " +
						  componentContext.MONTHS[new Date(dataLabel["x"]).getMonth()] +
						  " " +
						  new Date(dataLabel["x"]).getDate() :
						  dataLabel["x"]
				}</p>
				<div style="display:flex;gap:1rem;flex-direction:row;justify-content:space-between">
					<div style="display:flex;flex-direction: column;">
						<span style="color:${TOOLTIPCOLOR["VALUE"]};font-size:0.875rem;">${
							xCurrencyYUnit_symbol ? xCurrencyYUnit_symbol : ""
						}${componentContext.commonFunctions.getSuffixedNumberFormat(
					dataLabel?.y
				)}</span>
						<span style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.875rem;">${
							componentContext.caller === SAVE_WIDGET ? componentContext.commonFunctions.getSpecificTranslation(dataLabel['points'][0]['series']['userOptions']['name']) : componentContext.commonFunctions.getSpecificTranslation(componentContext.leftYAxisTitle)
						}</span>
					</div>
					<div style="display:flex;flex-direction: column;">
						<span style="color:${TOOLTIPCOLOR["VALUE"]};font-size:0.875rem;">${componentContext.commonFunctions.getSuffixedNumberFormat(
							dataLabel["points"][1]?.y
						)} units</span>
								<span style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.875rem;">${
									componentContext.caller === SAVE_WIDGET ? dataLabel['points'][1]['series']['userOptions']['name'] : componentContext.rightYAxisTitle
								}</span>
					</div>
				</div>
			</div>`;
			case "xUnitYSimple":
				return `<div
				style="background-color:${TOOLTIPCOLOR["BG"]};min-width:${componentContext.isMultipleYaxis ? '10rem' : '7rem'};border-radius:0.375rem;height:5rem;display:flex;flex-direction: column;padding:0.625rem 0.875rem;border:0.0625rem solid #eaedf3;margin:0;font-family:Poppins Regular;font-weight:400;row-gap:0.5rem;">
				<p style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.625rem;margin:0">${
					(componentContext.isDate || dataLabel['points'][0]['series']['userOptions']['isDate'])?
					dataLabel["x"].toString().includes(":")
						? "at " + componentContext.tConvert(dataLabel["x"])
						: "on " +
						  componentContext.MONTHS[new Date(dataLabel["x"]).getMonth()] +
						  " " +
						  new Date(dataLabel["x"]).getDate() :
						  dataLabel["x"]
				}</p>
				<div style="display:flex;gap:1rem;flex-direction:row;justify-content:space-between">
					<div style="display:flex;flex-direction: column;">
						<span style="color:${TOOLTIPCOLOR["VALUE"]};font-size:0.875rem;">${componentContext.commonFunctions.getSuffixedNumberFormat(
							dataLabel?.y
						)} units</span>
						<span style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.875rem;">${
							componentContext.caller === SAVE_WIDGET ? componentContext.commonFunctions.getSpecificTranslation(dataLabel['points'][0]['series']['userOptions']['name']) : componentContext.commonFunctions.getSpecificTranslation(componentContext.leftYAxisTitle)
						}</span>
					</div>
					<div style="display:flex;flex-direction: column;">
						<span style="color:${TOOLTIPCOLOR["VALUE"]};font-size:0.875rem;">${componentContext.commonFunctions.getSuffixedNumberFormat(
							dataLabel["points"][1]?.y
						)}</span>
								<span style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.875rem;">${
									componentContext.caller === SAVE_WIDGET ? componentContext.commonFunctions.getSpecificTranslation(dataLabel['points'][1]['series']['userOptions']['name']) : componentContext.commonFunctions.getSpecificTranslation(componentContext.rightYAxisTitle)
								}</span>
					</div>
				</div>
			</div>`;
			case "xUnitYCurrency":
				const xUnitYCurrency_symbol =
					dataLabel["points"][0]["series"]["userOptions"]["currency"]["symbol"];
				return `<div
                style="background-color:${TOOLTIPCOLOR["BG"]};min-width:${componentContext.isMultipleYaxis ? '10rem' : '7rem'};border-radius:0.375rem;height:5rem;display:flex;flex-direction: column;padding:0.625rem 0.875rem;border:0.0625rem solid #eaedf3;margin:0;font-family:Poppins Regular;font-weight:400;row-gap:0.5rem;">
                <p style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.625rem;margin:0">${
					(componentContext.isDate || dataLabel['points'][0]['series']['userOptions']['isDate'])?
									dataLabel["x"].toString().includes(":")
										? "at " + componentContext.tConvert(dataLabel["x"])
										: "on " +
										  componentContext.MONTHS[
												new Date(dataLabel["x"]).getMonth()
										  ] +
										  " " +
										  new Date(dataLabel["x"]).getDate() :
										  dataLabel["x"]
								}</p>
                <div style="display:flex;gap:1rem;flex-direction:row;justify-content:space-between">
                    <div style="display:flex;flex-direction: column;">
                        <span style="color:${TOOLTIPCOLOR["VALUE"]};font-size:0.875rem;">${componentContext.commonFunctions.getSuffixedNumberFormat(
													dataLabel?.y
												)} units</span>
                        <span style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.875rem;">${
													componentContext.caller === SAVE_WIDGET ? componentContext.commonFunctions.getSpecificTranslation(dataLabel['points'][0]['series']['userOptions']['name']) : componentContext.commonFunctions.getSpecificTranslation(componentContext.leftYAxisTitle)
												}</span>
                    </div>
                    <div style="display:flex;flex-direction: column;">
                        <span style="color:${TOOLTIPCOLOR["VALUE"]};font-size:0.875rem;">${
													xUnitYCurrency_symbol ? xUnitYCurrency_symbol : ""
												}${componentContext.commonFunctions.getSuffixedNumberFormat(
					dataLabel["points"][1]?.y
				)}</span>
                                <span style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.875rem;">${
																	componentContext.caller === SAVE_WIDGET ? componentContext.commonFunctions.getSpecificTranslation(dataLabel['points'][1]['series']['userOptions']['name']) : componentContext.commonFunctions.getSpecificTranslation(componentContext.rightYAxisTitle)
																}</span>
                    </div>
                </div>
            </div>`;
			case "xCurrencyYSimple":
				const xCurrencyYSimple_symbol =
					dataLabel["points"][0]["series"]["userOptions"]["currency"]["symbol"];
				return `<div
                style="background-color:${TOOLTIPCOLOR["BG"]};min-width:${componentContext.isMultipleYaxis ? '10rem' : '7rem'};border-radius:0.375rem;height:5rem;display:flex;flex-direction: column;padding:0.625rem 0.875rem;border:0.0625rem solid #eaedf3;margin:0;font-family:Poppins Regular;font-weight:400;row-gap:0.5rem;">
                <p style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.625rem;margin:0">${
					(componentContext.isDate || dataLabel['points'][0]['series']['userOptions']['isDate'])?
									dataLabel["x"].toString().includes(":")
										? "at " + componentContext.tConvert(dataLabel["x"])
										: "on " +
										  componentContext.MONTHS[
												new Date(dataLabel["x"]).getMonth()
										  ] +
										  " " +
										  new Date(dataLabel["x"]).getDate() :
										  dataLabel["x"]
								}</p>
                <div style="display:flex;gap:1rem;flex-direction:row;justify-content:space-between">
                    <div style="display:flex;flex-direction: column;">
                        <span style="color:${TOOLTIPCOLOR["VALUE"]};font-size:0.875rem;">${
													xCurrencyYSimple_symbol ? xCurrencyYSimple_symbol : ""
												}${componentContext.commonFunctions.getSuffixedNumberFormat(
					dataLabel?.y
					
				)}</span>
                        <span style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.875rem;text-transform: capitalize">${
													componentContext.caller === SAVE_WIDGET ? componentContext.commonFunctions.getSpecificTranslation(dataLabel['points'][0]['series']['userOptions']['name']) : componentContext.commonFunctions.getSpecificTranslation(componentContext.leftYAxisTitle)
												}</span>
                    </div>
                    <div style="display:flex;flex-direction: column;">
                        <span style="color:${TOOLTIPCOLOR["VALUE"]};font-size:0.875rem;">${componentContext.commonFunctions.getSuffixedNumberFormat(
													dataLabel["points"][1]?.y
												)}</span>
                                <span style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.875rem;text-transform: capitalize">${
																	componentContext.caller === SAVE_WIDGET ? componentContext.commonFunctions.getSpecificTranslation(dataLabel['points'][1]['series']['userOptions']['name']) : componentContext.commonFunctions.getSpecificTranslation(componentContext.rightYAxisTitle)
																}</span>
                    </div>
                </div>
            </div>`;
			case "xSimpleYCurrency":
				const xSimpleYCurrency_symbol =
					dataLabel["points"][0]["series"]["userOptions"]["currency"]["symbol"];
				return `<div
                style="background-color:${TOOLTIPCOLOR["BG"]};min-width:${componentContext.isMultipleYaxis ? '10rem' : '7rem'};border-radius:0.375rem;height:5rem;display:flex;flex-direction: column;padding:0.625rem 0.875rem;border:0.0625rem solid #eaedf3;margin:0;font-family:Poppins Regular;font-weight:400;row-gap:0.5rem;">
                <p style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.625rem;margin:0">${
					(componentContext.isDate || dataLabel['points'][0]['series']['userOptions']['isDate']) ?
									dataLabel["x"].toString().includes(":")
										? "at " + componentContext.tConvert(dataLabel["x"])
										: "on " +
										  componentContext.MONTHS[
												new Date(dataLabel["x"]).getMonth()
										  ] +
										  " " +
										  new Date(dataLabel["x"]).getDate() :
										  dataLabel["x"]
								}</p>
                <div style="display:flex;gap:1rem;flex-direction:row;justify-content:space-between">
                    <div style="display:flex;flex-direction: column;">
                        <span style="color:${TOOLTIPCOLOR["VALUE"]};font-size:0.875rem;">${componentContext.commonFunctions.getSuffixedNumberFormat(
													dataLabel?.y
												)}</span>
                        <span style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.875rem;">${
													componentContext.caller === SAVE_WIDGET ? componentContext.commonFunctions.getSpecificTranslation(dataLabel['points'][0]['series']['userOptions']['name']) : componentContext.commonFunctions.getSpecificTranslation(componentContext.leftYAxisTitle)
												}</span>
                    </div>
                    <div style="display:flex;flex-direction: column;">
                        <span style="color:${TOOLTIPCOLOR["VALUE"]};font-size:0.875rem;">${
													xSimpleYCurrency_symbol ? xSimpleYCurrency_symbol : ""
												}${componentContext.commonFunctions.getSuffixedNumberFormat(
					dataLabel["points"][1]?.y
				)}</span>
                                <span style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.875rem;">${
																	componentContext.caller === SAVE_WIDGET ? componentContext.commonFunctions.getSpecificTranslation(dataLabel['points'][1]['series']['userOptions']['name']) :componentContext.commonFunctions.getSpecificTranslation(componentContext.rightYAxisTitle)
																}</span>
                    </div>
                </div>
            </div>`;
			case "xSimpleYUnit":
				return `<div
			style="background-color:${TOOLTIPCOLOR["BG"]};min-width:${componentContext.isMultipleYaxis ? '10rem' : '7rem'};border-radius:0.375rem;height:5rem;display:flex;flex-direction: column;padding:0.625rem 0.875rem;border:0.0625rem solid #eaedf3;margin:0;font-family:Poppins Regular;font-weight:400;row-gap:0.5rem;">
			<p style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.625rem;margin:0">${
				(componentContext.isDate || dataLabel['points'][0]['series']['userOptions']['isDate']) ?
				dataLabel["x"].toString().includes(":")
					? "at " + componentContext.tConvert(dataLabel["x"])
					: "on " +
					  componentContext.MONTHS[new Date(dataLabel["x"]).getMonth()] +
					  " " +
					  new Date(dataLabel["x"]).getDate() :
					  dataLabel["x"]
			}</p>
			<div style="display:flex;gap:1rem;flex-direction:row;justify-content:space-between">
				<div style="display:flex;flex-direction: column;">
					<span style="color:${TOOLTIPCOLOR["VALUE"]};font-size:0.875rem;">${componentContext.commonFunctions.getSuffixedNumberFormat(
						dataLabel?.y
					)}</span>
					<span style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.875rem;">${
						componentContext.caller === SAVE_WIDGET ? componentContext.commonFunctions.getSpecificTranslation(dataLabel['points'][0]['series']['userOptions']['name']) : componentContext.commonFunctions.getSpecificTranslation(componentContext.leftYAxisTitle)
					}</span>
				</div>
				<div style="display:flex;flex-direction: column;">
					<span style="color:${TOOLTIPCOLOR["VALUE"]};font-size:0.875rem;">${componentContext.commonFunctions.getSuffixedNumberFormat(
						dataLabel["points"][1]?.y
					)} units</span>
							<span style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.875rem;">${
								componentContext.caller === SAVE_WIDGET ? componentContext.commonFunctions.getSpecificTranslation(dataLabel['points'][1]['series']['userOptions']['name']) : componentContext.commonFunctions.getSpecificTranslation(componentContext.rightYAxisTitle)
							}</span>
				</div>
			</div>
		</div>`;
			case "xSimple":
				return `<div
                style="background-color:${TOOLTIPCOLOR["BG"]};min-width:${componentContext.isMultipleYaxis ? '10rem' : '7rem'};border-radius:0.375rem;height:5rem;display:flex;flex-direction: column;padding:0.625rem 0.875rem;border:0.0625rem solid #eaedf3;margin:0;font-family:Poppins Regular;font-weight:400;row-gap:0.5rem;">
                <p style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.625rem;margin:0">${
					( componentContext.isDate  || dataLabel['points'][0]['series']['userOptions']['isDate'])?
									dataLabel["x"].toString().includes(":")
										? "at " + componentContext.tConvert(dataLabel["x"])
										: "on " +
										  componentContext.MONTHS[
												new Date(dataLabel["x"]).getMonth()
										  ] +
										  " " +
										  new Date(dataLabel["x"]).getDate() :
										  dataLabel["x"]
								}</p>
                <div style="display:flex;gap:1rem;flex-direction:row;justify-content:space-between">
                    <div style="display:flex;flex-direction: column;">
                        <span style="color:${TOOLTIPCOLOR["VALUE"]};font-size:0.875rem;">${componentContext.commonFunctions.getSuffixedNumberFormat(
													dataLabel?.y
												)}</span>
                        <span style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.875rem;">${
													componentContext.caller === SAVE_WIDGET ? componentContext.commonFunctions.getSpecificTranslation(dataLabel['points'][0]['series']['userOptions']['name']) : componentContext.commonFunctions.getSpecificTranslation(componentContext.leftYAxisTitle)
												}</span>
                    </div>
                </div>
            </div>`;
			case "xCurrency":
				const xCurrency_symbol =
					dataLabel["points"][0]["series"]["userOptions"]["currency"]["symbol"];
				return `<div
		style="background-color:${TOOLTIPCOLOR["BG"]};min-width:${componentContext.isMultipleYaxis ? '10rem' : '7rem'};border-radius:0.375rem;height:5rem;display:flex;flex-direction: column;padding:0.625rem 0.875rem;border:0.0625rem solid #eaedf3;margin:0;font-family:Poppins Regular;font-weight:400;row-gap:0.5rem;">
		<p style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.625rem;margin:0">${
			(componentContext.isDate || dataLabel['points'][0]['series']['userOptions']['isDate'] ) ?
			dataLabel["x"].toString().includes(":")
				? "at " + componentContext.tConvert(dataLabel["x"])
				: "on " +
				  componentContext.MONTHS[new Date(dataLabel["x"]).getMonth()] +
				  " " +
				  new Date(dataLabel["x"]).getDate() :
				  dataLabel["x"]
		}</p>
		<div style="display:flex;gap:1rem;flex-direction:row;justify-content:space-between">
			<div style="display:flex;flex-direction: column;">
				<span style="color:${TOOLTIPCOLOR["VALUE"]};font-size:0.875rem;">${
					xCurrency_symbol ? xCurrency_symbol : ""
				}${componentContext.commonFunctions.getSuffixedNumberFormat(
					dataLabel?.y
				)}</span>
				<span style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.875rem;">${
					componentContext.caller === SAVE_WIDGET ? componentContext.commonFunctions.getSpecificTranslation(dataLabel['points'][0]['series']['userOptions']['name']) : componentContext.commonFunctions.getSpecificTranslation(componentContext.leftYAxisTitle)
				}</span>
			</div>
		</div>
	</div>`;
			case "xUnit":
				return `<div
                style="background-color:${TOOLTIPCOLOR["BG"]};min-width:${componentContext.isMultipleYaxis ? '10rem' : '7rem'};border-radius:0.375rem;height:5rem;display:flex;flex-direction: column;padding:0.625rem 0.875rem;border:0.0625rem solid #eaedf3;margin:0;font-family:Poppins Regular;font-weight:400;row-gap:0.5rem;">
                <p style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.625rem;margin:0">${
					(componentContext.isDate || dataLabel['points'][0]['series']['userOptions']['isDate']) ?
									dataLabel["x"].toString().includes(":")
										? "at " + componentContext.tConvert(dataLabel["x"])
										: "on " +
										  componentContext.MONTHS[
												new Date(dataLabel["x"]).getMonth()
										  ] +
										  " " +
										  new Date(dataLabel["x"]).getDate() :
										  dataLabel["x"]
								}</p>
                <div style="display:flex;gap:1rem;flex-direction:row;justify-content:space-between">
                    <div style="display:flex;flex-direction: column;">
                        <span style="color:${TOOLTIPCOLOR["VALUE"]};font-size:0.875rem;">${componentContext.commonFunctions.getSuffixedNumberFormat(
													dataLabel?.y
												)} units</span>
                        <span style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.875rem;">${
													componentContext.caller === SAVE_WIDGET ? componentContext.commonFunctions.getSpecificTranslation(dataLabel['points'][0]['series']['userOptions']['name']) : componentContext.commonFunctions.getSpecificTranslation(componentContext.leftYAxisTitle)
												}</span>
                    </div>
                </div>
            </div>`;
			case "heapmapCompare":
				return `<div style="display:flex;flex-direction:column;background-color:${TOOLTIPCOLOR["BG"]};min-width:10rem;border-radius:0.375rem;padding:0.5rem 0.625rem;border:0.0625rem solid #eaedf3;margin:0;font-family:Poppins Regular;color:${TOOLTIPCOLOR["TEXT"]}">
			 				<div style="font-size:0.625rem;">on ${moment(dataLabel.x).format('MMM DD') }</div>
							<div style="display:flex;flex-direction: column;margin-top:0.5rem">
								<span style="color:${TOOLTIPCOLOR["VALUE"]};font-size:0.875rem;">${["selling_price"].includes(dataLabel["series"]["userOptions"]["kpi"])
					? componentContext.commonFunctions.currencySelected.attributes?.symbol
						: ""
					}${componentContext.commonFunctions.getSuffixedNumberFormat(
						dataLabel.y
					)}${dataLabel["series"]["userOptions"]["kpi"] === "discount" ? "%" : ""
					}</span>
								<span style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.875rem;">${componentContext.commonFunctions.getSpecificTranslation(dataLabel["series"]["userOptions"]["name"])
					}</span>
							</div>
						</div>`;
			case "discover_pricing_history":
				return `<div style="display:flex;flex-direction:column;background-color:${TOOLTIPCOLOR["BG"]};min-width:14rem;border-radius:0.375rem;padding:0.5rem 0.625rem;border:0.0625rem solid #eaedf3;margin:0;font-family:Poppins Regular}">
							<p style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.625rem;margin:0">${dataLabel["series"]["name"]} (${dataLabel["x"].toString().includes(":")
								? "at " + componentContext.tConvert(dataLabel["x"])
								: "on " +
								componentContext.MONTHS[new Date(dataLabel["x"]).getMonth()] +
								" " +
								new Date(dataLabel["x"]).getDate()
							})</p>
			   				<div style="display:flex;flex-direction: column;margin-top:0.5rem">
				  				<span style="color:${TOOLTIPCOLOR["VALUE"]};font-size:0.875rem;margin-top:0.5rem">${componentContext.commonFunctions.currencySelected.attributes.symbol
				  					 }${componentContext.commonFunctions.getSuffixedNumberFormat(dataLabel.y
   									)}
								</span>
								<span style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.875rem;margin-top:0.5rem">${dataLabel['series']['userOptions']['name'] === 'TARGET PRICE' ?
								componentContext.commonFunctions.getSpecificTranslation('Target Price') : componentContext.commonFunctions.getSpecificTranslation('Average Price')
								}</span>
			   				</div>
		  				 </div>`;
			case 'gmv':
			case 'quantity_sold':
			case "price":
				const currencySymbol =dataLabel["series"]["userOptions"]["currency"]["attributes"]["symbol"];
				const caller = dataLabel["series"]["userOptions"]["caller"]
				return `<div style="background-color:${TOOLTIPCOLOR["BG"]};min-width:5.5rem;border-radius:0.375rem;height:5rem;display:flex;flex-direction: column;padding:0.625rem 0.875rem;border:0.0625rem solid #eaedf3;margin:0;font-family:Poppins Regular;font-weight:400;row-gap:0.5rem;">
					<p style="color:${TOOLTIPCOLOR["VALUE"]};font-size:0.625rem;margin:0">${
						dataLabel["x"].toString().includes(":")
							? "at " + componentContext.tConvert(dataLabel["x"])
							: "on " +
								componentContext.MONTHS[new Date(dataLabel["x"]).getMonth()] +
								" " +
								new Date(dataLabel["x"]).getDate()
					}</p>
					<div style="display:flex;gap:1rem;flex-direction:row;justify-content:space-between">
						${caller === "price" ?
					`<div style="display:flex;flex-direction: column;">
						<span style="color:${TOOLTIPCOLOR["VALUE"]};font-size:0.875rem;">${
							currencySymbol ? currencySymbol : ""
						}${componentContext.commonFunctions.getSuffixedNumberFormat(
					dataLabel.y
					)}</span>
							<span style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.875rem;">${componentContext.commonFunctions.getSpecificTranslation('Price')}</span>
						</div>` :
					`<div style="display:flex;gap:1rem">
						<div style="display:flex;flex-direction: column;">
							<span style="color:${TOOLTIPCOLOR["VALUE"]};font-size:0.875rem;">${caller==='gmv'?currencySymbol:''}${
								componentContext.commonFunctions.getSuffixedNumberFormat(dataLabel.y)
							}</span>
							<span style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.875rem;">${componentContext.commonFunctions.getSpecificTranslation(caller==='gmv'?'Estimated Sales':'Quantity Sold')}</span>
						</div>
					</div>
					`
					}
				</div>
		</div>` 
			case "rating-trend":
				const salesSymbol =
					dataLabel["series"]["userOptions"]["currency"];
				const sqStartDate =
					dataLabel["series"]["userOptions"]["dateObj"][dataLabel.x][
					"start_date"
					];
				const sqEndDate =
					dataLabel["series"]["userOptions"]["dateObj"][dataLabel.x]["end_date"];
				return `<div
		style="background-color:${TOOLTIPCOLOR["BG"]
					};min-width:10rem;border-radius:0.375rem;height:5rem;display:flex;flex-direction: column;padding:0.625rem 0.875rem;border:none;margin:0;font-family:Poppins Regular;font-weight:400;row-gap:0.5rem;">
		<p style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.625rem;margin:0">

				${dataLabel["key"]} ( ${componentContext.MONTHS[new Date(sqStartDate).getMonth()] +
					" " +
					new Date(sqStartDate).getDate()
					} - ${componentContext.MONTHS[new Date(sqEndDate).getMonth()] +
					" " +
					new Date(sqEndDate).getDate()
					} )
			</p>
		<div style="display:flex;gap:1rem;flex-direction:row;justify-content:space-between">
		<div style="display:flex;flex-direction: column;">
		<span style="color:${TOOLTIPCOLOR["VALUE"]};font-size:0.875rem;">${dataLabel["series"]["userOptions"]["caller"] === "gmv"
						? salesSymbol
						: ""
					}${componentContext.commonFunctions.getSuffixedNumberFormat(
						dataLabel.y
					)}</span>
		<span style="color:${TOOLTIPCOLOR["TEXT"]
					};font-size:0.875rem;">${componentContext.commonFunctions.getSpecificTranslation(
						dataLabel["series"]["userOptions"]["title"]
					)}</span>
		</div>
		</div>
		</div>`;
			case "category-BrandMarketShare":
				let tooltipContents =  `<div style="display:flex;flex-direction:column;height:auto;background-color:${TOOLTIPCOLOR["BG"]};min-width:24rem;border-radius:0.375rem;padding:0.5rem 0.625rem;border:0.0625rem solid #eaedf3;margin:0;font-family:Poppins Regular;font-weight:400;color:${TOOLTIPCOLOR["TEXT"]}">	<div style="margin-bottom:0.5rem;">Week of ${dataLabel.x}</div>`
						dataLabel['points'].forEach((ele: any)=> {
							tooltipContents += `
								<div>
									<span style="display:inline-block;background-color:${ele.color};height:.75rem;width:.75rem;border-radius:0.125rem"></span>
									<span style="display:inline-block;color:${TOOLTIPCOLOR["VALUE"]}font-size:0.875rem;margin-right:1rem;min-width:50%">${ele['series']['userOptions']['name']}</span>
									<span >(${ele['series']['userOptions']['share_on'] === 'gmv' ? `${componentContext.commonFunctions.getSpecificTranslation('GMV')}: ${componentContext.commonFunctions.currencySelected.attributes.symbol}${componentContext.commonFunctions.getSuffixedNumberFormat(ele.y)}`: `${componentContext.commonFunctions.getSpecificTranslation('Quantity Sold')}: ${componentContext.commonFunctions.getSuffixedNumberFormat(ele.y)}`})</span>
								</div>
							`
						});
						tooltipContents += `</div>`
			return tooltipContents;
			case "promo-segment-trend":
				return `<div
	style="background-color:${TOOLTIPCOLOR["BG"]};min-width:5.5rem;border-radius:0.375rem;height:5rem;display:flex;flex-direction: column;padding:0.625rem 0.875rem;border:0.0625rem solid #eaedf3;margin:0;font-family:Poppins Regular;font-weight:400;row-gap:0.5rem;">
	<p style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.625rem;margin:0">${dataLabel["x"].toString().includes(":")
						? "at " + componentContext.tConvert(dataLabel["x"])
						: "on " +
						componentContext.MONTHS[new Date(dataLabel["x"]).getMonth()] +
						" " +
						new Date(dataLabel["x"]).getDate()
					}</p>
	<div style="display:flex;gap:1rem;flex-direction:row;justify-content:space-between">
	${
		`<div style="display:flex;flex-direction: column;">
			<span style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.875rem;">${
				componentContext.commonFunctions.getSpecificTranslation(dataLabel["series"]["userOptions"]["name"])
			}</span>
			<span style="color:${TOOLTIPCOLOR["VALUE"]};font-size:0.875rem;margin-bottom:5px">${dataLabel.y}</span>
		</div>`}
	</div>
	</div>`;
	case "sku-product-trend":
		const details = dataLabel['series'].userOptions;
		return `<div
	style="background-color:${TOOLTIPCOLOR["BG"]};min-width:6rem;border-radius:0.375rem;height:6.6rem;display:flex;flex-direction: column;padding:0.875rem 0.875rem;border:0.0625rem solid #eaedf3;margin:0;font-family:Poppins Regular;font-weight:400;row-gap:0.5rem;">
	<div>
	<span style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.6rem">${details.name.substring(0,70)}...</span>
	<p style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.625rem;margin:0">${dataLabel["x"].toString().includes(":")
						? "at " + componentContext.tConvert(dataLabel["x"])
						: "on " +
						componentContext.MONTHS[new Date(dataLabel["x"]).getMonth()] +
						" " +
						new Date(dataLabel["x"]).getDate()
					}</p>
	</div>
	<div style="display:flex;gap:1rem;flex-direction:row;justify-content:space-between;padding-bottom:0.875rem">
	${
		`
		<div style="display:flex;flex-direction: column;">
			<span style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.875rem;">${
				componentContext.commonFunctions.getSpecificTranslation('Price')
			}${details.variant_count||0>1?`<small>(Average of ${details.variant_count} ${details.variant_count>1?'Variants':'Variant'})</small>`:''}</span>
			<span style="color:${TOOLTIPCOLOR["VALUE"]};font-size:0.875rem;margin-bottom:0.875rem">${componentContext.commonFunctions.currencySelected.attributes.symbol}${dataLabel.y}</span>
		</div>
		<div style="display:flex;flex-direction: column;">
			<span style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.875rem;">${
				componentContext.commonFunctions.getSpecificTranslation('Store Name')
			}</span>
			<span style="color:${TOOLTIPCOLOR["VALUE"]};font-size:0.875rem;margin-bottom:0.875rem">${details.store}</span>
		</div>
		<div style="display:flex;flex-direction: column;">
			<span style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.875rem;">${
				componentContext.commonFunctions.getSpecificTranslation('Channel')
			}</span>
			<span style="color:${TOOLTIPCOLOR["VALUE"]};font-size:0.875rem;margin-bottom:0.875rem">${details.marketplace}</span>
		</div>
		`}
	</div>
	</div>`;
		default:
				return "";
		}
	}
	

	formatterFunction(): string {
		const dataLabel = this as Highcharts.DataLabelsOptions;
		return componentContext.commonFunctions.getSuffixedNumberFormat(
			dataLabel.y
		);
	}

	oppositeYAxisFormatter() {
		const dataLabel = this as Highcharts.DataLabelsOptions;
		const caller = componentContext.caller === SAVE_WIDGET ? componentContext.commonFunctions.getSpecificTranslation(dataLabel['axis']['series'][0]['userOptions']['caller']) : componentContext.commonFunctions.getSpecificTranslation(componentContext.caller);
		switch (caller) {
			case "pricing":
			case "pricingHistory":
				if (dataLabel["axis"]["userOptions"]["title"]["text"] === "DISCOUNT (%)") return `${dataLabel["value"]}%`;
				return `${dataLabel["value"]}`;
			case "sellout":
				return `
				${componentContext.commonFunctions.getSuffixedNumberFormat(dataLabel["value"])}
				`;
			case "xCurrencyYCurrency":
				return `
				${componentContext.commonFunctions.getSuffixedNumberFormat(dataLabel["value"])}
				`;
			case "xUnitYUnit":
				return `
					${componentContext.commonFunctions.getSuffixedNumberFormat(dataLabel["value"])}
					`;
			case "xCurrencyYUnit":
				return `
				${componentContext.commonFunctions.getSuffixedNumberFormat(dataLabel["value"])}
				`;
			case "xUnitYSimple":
				return `
				${componentContext.commonFunctions.getSuffixedNumberFormat(dataLabel["value"])}
				`;
			case "xCurrencyYSimple":
				return `
				${componentContext.commonFunctions.getSuffixedNumberFormat(dataLabel["value"])}
				`;
			case "xSimpleYUnit":
				return `
				${componentContext.commonFunctions.getSuffixedNumberFormat(dataLabel["value"])}
				`;
			case "xUnit":
				return `
				${componentContext.commonFunctions.getSuffixedNumberFormat(dataLabel["value"])}
				`;
			case "xCurrency":
				return `
				${componentContext.commonFunctions.getSuffixedNumberFormat(dataLabel["value"])}
				`;
			case "xSimple":
				return `
				${componentContext.commonFunctions.getSuffixedNumberFormat(dataLabel["value"])}
				`;
			case "xSimpleYCurrency":
				return `
				${componentContext.commonFunctions.getSuffixedNumberFormat(dataLabel["value"])}
				`;
			case "xUnitYCurrency":
				return `
				${componentContext.commonFunctions.getSuffixedNumberFormat(dataLabel["value"])}
				`;
			case "xSimpleYSimple":
				return `
			${componentContext.commonFunctions.getSuffixedNumberFormat(dataLabel["value"])}
			`;
			case "marketSharePriceElasticityTrend":
				return `
				${componentContext.commonFunctions.getSuffixedNumberFormat(dataLabel["value"])}
				`;
			default:
				return componentContext.formatterFunction();
		}
	}
	yAxisFormatter() {
		const dataLabel = this as Highcharts.DataLabelsOptions;
		switch (componentContext.caller) {
			case "pricingHistory":
				return `${componentContext.commonFunctions.getSuffixedNumberFormat(
					dataLabel["value"]
				)}`;
			default:
				return componentContext.commonFunctions.getSuffixedNumberFormat(
					dataLabel["value"]
				);
		}
	}

	tConvert(time) {
		// Check correct time format and split into components
		time = time
			.toString()
			.match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

		if (time.length > 1) {
			// If time format correct
			time = time.slice(1); // Remove full string match value
			time[5] = +time[0] < 12 ? "am" : "pm"; // Set AM/PM
			time[0] = +time[0] % 12 || 12; // Adjust hours
		}
		return time.join(""); // return adjusted time or original string
	}

	ngOnDestroy() {
		this.destroy$.next(true);
		this.destroy$.unsubscribe();
	}
}
