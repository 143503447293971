import { COL, GRIDTYPE } from "@ds-shared/enums/common.enum";

export const ScorecardListColumns = {
	product: {
		cols: [
			{
				id: 1,
				name: "",
				map: "",
				meta: { title: "Product", type: "all-product" },
				sort: false,
				method: "bulkSelect",
				component: "bulkSelect",
				class: "",
				hasRedirect: false,
				visibleTo: ["all"],
				isChecked: true,
				style: {
					th: {
						width: "3.125rem"
					},
					td: {
						width: "3.125rem"
					}
				}
			},
			{
				id: 2,
				name: "Product Name",
				map: "attributes.name",
				meta: {"marketplaceLink": true},
				sort: true,
				method: "productImage",
				component: "common-mapping",
				class: "",
				hasRedirect: true,
				isChecked: true,
				visibleTo: ["all"],
				disableColoumn:true,
				style: {
					th: {
						"min-width": "9.375rem",
						"max-width": "14.625rem"
					},
					td: {
						"min-width": "9.375rem",
						"max-width": "14.625rem"
					}
				}
			},
			{
				id: 3,
				name: "Price",
				map: "attributes.price",
				sort: true,
				method: "pricewithDiscountNewView",
				component: "common-mapping",
				class: "font-medium",
				hasRedirect: true,
				isChecked: true,
				visibleTo: ["all"],
				disableColoumn:true,
				style: {
					th: {
						"min-width": "6rem"
					},
					td: {
						"min-width": "6rem",
						"font-size": "0.8125rem"
					},
					"th-class": "text-right",
					"td-class": "text-right"
				},
				meta:{
					isOwnCurrencyAvailable: true,
					currencyMap: 'attributes.currency.symbol'
				}
			},
			{
				id: 4,
				name: "Price Before Discount",
				map: "attributes.price_before_discount",
				sort: true,
				method: "priceView",
				component: "common-mapping",
				class: "font-medium",
				hasRedirect: true,
				isChecked: true,
				visibleTo: ["all"],
				style: {
					th: {
						"min-width": "6rem"
					},
					td: {
						"min-width": "6rem",
						"font-size": "0.8125rem"
					},
					"th-class": "text-right",
					"td-class": "text-right"
				},
				meta: {
					isOwnCurrencyAvailable: true,
					currencyMap: 'attributes.currency.symbol'
				}
			},
			{
				id: 5,
				name: COL.STORE,
				map: "attributes.store_name",
				sort: true,
				method: "storewithSegmentView",
				component: "common-mapping",
				class: "ellipsis",
				hasRedirect: true,
				isChecked: true,
				visibleTo: ["all"],
				disableColoumn:true,
				meta: {"marketplaceLink": true},
				style: {
					th: {
						"min-width": "7.5rem",
						"max-width": "10.5rem"
					},
					td: {
						"min-width": "7.5rem",
						"max-width": "10.5rem"
					}
				}
			},
			{
				id: 7,
				name: "Tracked By",
				map: "attributes.tracked_by",
				sort: true,
				method: "trackedByView",
				component: "common-mapping",
				hasRedirect: true,
				isChecked: false,
				visibleTo: ["all"],
				style: {
					th: {
						"min-width": "10rem"
					},
					td: {
						"min-width": "10rem",
					}
				}
			},
			{
				id: 9,
				name: COL.CATEGORY,
				map: "attributes.category_name",
				sort: true,
				method: "defaultView",
				component: "common-mapping",
				class: "ellipsis",
				hasRedirect: true,
				visibleTo: ["all"],
				isChecked: true,
				style: {
					th: {
						"min-width": "7.5rem",
						"max-width": "10.5rem"
					},
					td: {
						"min-width": "7.5rem",
						"max-width": "10.5rem"
					}
				}
			},
			{
				id: 10,
				name: COL.BRAND,
				map: "attributes.brand",
				sort: true,
				method: "brandWithHarmonisation",
				component: "common-mapping",
				class: "",
				hasRedirect: true,
				isChecked: true,
				visibleTo: ["all"],
				style: {
					th: {
						"min-width": "8.5rem"
					},
					td: {
						"min-width": "8.5rem"
					}
				}
			},
			{
				id: 11,
				name: COL.RETAIL_CHANNEL,
				map: "attributes.marketplace",
				sort: true,
				method: "logoFormat",
				component: "common-mapping",
				class: "",
				hasRedirect: false,
				visibleTo: ["all"],
				isChecked: true,
				style: {
					th: {
						"min-width": "11.25rem"
					},
					td: {
						"min-width": "11.25rem"
					}
				}
			},
			{
				id: 12,
				name: COL.COUNTRY,
				map: "attributes.country",
				sort: true,
				method: "countryView",
				component: "common-mapping",
				class: "",
				hasRedirect: false,
				visibleTo: ["all"],
				isChecked: false,
				style: {
					th: {
						"min-width": "11.25rem"
					},
					td: {
						"min-width": "11.25rem"
					}
				}
			},
			{
				id: 13,
				name: COL.RATINGS,
				map: "attributes.rating",
				sort: true,
				method: "ratingView",
				component: "common-mapping",
				class: "",
				hasRedirect: true,
				isChecked: false,
				visibleTo: ["all"],
				style: {
					th: {
						"min-width": "7rem",
					},
					td: {
						"min-width": "7rem",
					}
				}
			},
			{
				id: 14,
				name: COL.LIKES,
				map: "attributes.likes",
				sort: true,
				method: "countView",
				component: "common-mapping",
				class: "",
				hasRedirect: true,
				isChecked: false,
				visibleTo: ["all"],
				style: {
					th: {
						"min-width": "7rem",
					},
					td: {
						"min-width": "7rem",
					},
					'th-class': 'text-right',
					'td-class': 'text-right'
				}
			},
			{
				id: 15,
				name: COL.LAST_UPDATED,
				map: "attributes.updated_at",
				sort: true,
				method: "dateFormat",
				component: "common-mapping",
				class: "",
				hasRedirect: false,
				visibleTo: ["all"],
				isChecked: true,
				style: {
					th: {
						"min-width": "10.5rem"
					},
					td: {
						"min-width": "10.5rem"
					}
				}
			},
			{
				id: 16,
				name: "Actions",
				map: "",
				meta: { type: "productsAll" },
				sort: false,
				method: "",
				component: "build-action",
				class: "",
				hasRedirect: false,
				isChecked: true,
				visibleTo: ["all"],
				style: {
					th: {
						"min-width": "3.125rem"
					},
					td: {
						"min-width": "3.125rem"
					},
					"th-class": "text-right",
					"td-class": "text-right"
				}
			},
			{
				id: 1,
				name: "",
				map: "",
				meta: { title: "Tracker", type: "product-tracker" },
				sort: false,
				method: "bulkSelect",
				component: "bulkSelect",
				class: "",
				hasRedirect: false,
				visibleTo: ["userOnly"],
				isChecked: true,
				style: {
					th: {
						width: "3.125rem"
					},
					td: {
						width: "3.125rem"
					}
				}
			},
			{
				id: 2,
				name: "Tracker Name",
				map: "attributes.name",
				sort: true,
				method: "navigationText",
				component: "common-mapping",
				visibleTo: ["userOnly"],
				class: "",
				hasRedirect: true,
				isChecked: true,
				disableColoumn:true,
				meta: {urlType: 'profile_val'},
				style: {
					th: {
						"min-width": "12.5rem",
						"max-width": "15.625rem"
					},
					td: {
						"min-width": "12.5rem",
						"max-width": "15.625rem"
					}
				}
			},
			{
				id: 3,
				name: "Frequency",
				map: "attributes.frequency",
				sort: true,
				method: "defaultView",
				component: "common-mapping",
				class: "",
				hasRedirect: false,
				visibleTo: ["userOnly"],
				isChecked: true,
				style: {
					th: {
						"min-width": "8.125rem"
					},
					td: {
						"min-width": "8.125rem"
					}
				}
			},
			
			{
				id: 4,
				name: COL.AI_SERVICES,
				map: "attributes.ml_service_codes",
				sort: true,
				method: "aiServices",
				component: "common-mapping",
				class: "",
				hasRedirect: false,
				visibleTo: ["userOnly"],
				isChecked: true,
				style: {
					th: {
						"min-width": "11.25rem"
					},
					td: {
						"min-width": "11.25rem"
					}
				}
			},
			{
				id: 5,
				name: "Target Price",
				map: "attributes.notification_config.target_value",
				sort: true,
				method: "priceView",
				component: "common-mapping",
				class: "",
				hasRedirect: false,
				visibleTo: ["userOnly"],
				isChecked: false,
				style: {
					th: {
						"min-width": "3.125rem"
					},
					td: {
						"min-width": "3.125rem"
					},
					"th-class": "text-right",
					"td-class": "text-right"
				},
       			meta:{
					isOwnCurrencyAvailable: true,
					currencyMap: 'attributes.currency.symbol'
				}
			},
			{
				id: 6,
				name: "Harmonised Brand",
				map: "attributes.harmonised_brand.harmonised_brand",
				sort: true,
				method: "defaultView",
				component: "common-mapping",
				class: "",
				hasRedirect: false,
				visibleTo: ["userOnly"],
				isChecked: false
			},
			{
				id: 7,
				name: "Harmonised Product",
				map: "attributes.harmonised_product.harmonised_product",
				sort: true,
				method: "defaultView",
				component: "common-mapping",
				class: "",
				hasRedirect: false,
				visibleTo: ["userOnly"],
				isChecked: false
			},
			{
				id: 8,
				name: COL.RETAIL_CHANNEL,
				map: "attributes.marketplace",
				sort: true,
				method: "logoFormat",
				component: "common-mapping",
				class: "",
				hasRedirect: false,
				visibleTo: ["userOnly"],
				isChecked: false,
				style: {
					th: {
						"min-width": "11.25rem"
					},
					td: {
						"min-width": "11.25rem"
					}
				}
			},
			{
				id: 9,
				name: COL.COUNTRY,
				map: "attributes.country",
				sort: true,
				method: "countryView",
				component: "common-mapping",
				class: "",
				hasRedirect: false,
				visibleTo: ["userOnly"],
				isChecked: false,
				style: {
					th: {
						"min-width": "11.25rem"
					},
					td: {
						"min-width": "11.25rem"
					}
				}
			},
			{
				id: 10,
				name: "Status",
				map: "attributes.is_active",
				sort: true,
				method: "badgeView",
				component: "common-mapping",
				class: "",
				hasRedirect: false,
				visibleTo: ["userOnly"],
				isChecked: true,
				disableColoumn:true,
				style: {
					th: {
						"min-width": "7.5rem"
					},
					td: {
						"min-width": "7.5rem"
					}
				}
			},
			
			{
				id: 11,
				name: COL.START_DATE,
				map: "attributes.start_at",
				meta: { dateFormat: "MMM d YYYY"},
				sort: true,
				method: "dateFormat",
				component: "common-mapping",
				class: "",
				hasRedirect: false,
				visibleTo: ["userOnly"],
				isChecked: false,
				style: {
					th: {
						"min-width": "13.25rem"
					},
					td: {
						"min-width": "13.25rem"
					}
				}
			},
			{
				id: 12,
				name: COL.END_DATE,
				map: "attributes.expiry_at",
				meta: { dateFormat: "MMM d YYYY" },
				sort: true,
				method: "dateFormat",
				component: "common-mapping",
				class: "",
				hasRedirect: false,
				visibleTo: ["userOnly"],
				isChecked: false,
				style: {
					th: {
						"min-width": "11.25rem"
					},
					td: {
						"min-width": "11.25rem"
					}
				}
			},
			{
				id: 13,
				name: "Last Edited",
				map: "attributes.updated_at",
				sort: true,
				method: "dateFormat",
				component: "common-mapping",
				class: "",
				hasRedirect: false,
				visibleTo: ["userOnly"],
				isChecked: true,
				style: {
					th: {
						"min-width": "11.25rem"
					},
					td: {
						"min-width": "11.25rem"
					}
				}
			},
			{
				id: 14,
				name: "Actions",
				map: "",
				sort: false,
				method: "",
				component: "build-action",
				class: "",
				hasRedirect: false,
				visibleTo: ["userOnly"],
				isChecked: true,
				style: {
					th: {
						"min-width": "3.125rem"
					},
					td: {
						"min-width": "3.125rem"
					},
					"th-class": "text-right",
					"td-class": "text-right"
				}
			},
			{
				id: 1,
				name: "Harmonised Product Name",
				map: "attributes.name",
				meta: {"navigateTarget": '_self',showImage: true, view:'harmonised'},
				sort: true,
				method: "productImage",
				component: "common-mapping",
				class: "",
				hasRedirect: true,
				isChecked: true,
				visibleTo: [GRIDTYPE.HARMONISED],
			},
			{
				id: 2,
				name: COL.MAPPEDTO,
				map: "attributes.mapped_details",
				sort: true,
				method: "clickableUnitWithString",
				component: "common-mapping",
				hasRedirect: true,
				isChecked: true,
				visibleTo: [GRIDTYPE.HARMONISED],
				meta:{
					name: `Raw Products`,
					hideUnmapAllbtn: true,
					showDelete: true,
					scorecardType: 'product'
				},
			},
			{
				id: 3,
				name: "Average Price",
				map: "attributes.avg_price",
				sort: true,
				method: "priceView",
				component: "common-mapping",
				class: "font-medium",
				hasRedirect: true,
				isChecked: true,
				visibleTo: [GRIDTYPE.HARMONISED],
				style: {
					"th-class": "text-right",
					"td-class": "text-right"
				},
				meta: {
					isOwnCurrencyAvailable: true,
					currencyMap: 'attributes.currency.symbol'
				}
			},
			{
				id: 4,
				name: "Last Updated",
				map: "attributes.updated_at",
				sort: true,
				method: "dateFormat",
				component: "common-mapping",
				class: "",
				hasRedirect: false,
				visibleTo: [GRIDTYPE.HARMONISED],
				isChecked: true,
			},
		],
		filterColumns(visibleTo: string[] = [GRIDTYPE.ALL, GRIDTYPE.USERONLY]) {
			return this.cols.filter(
				(col) =>
					col.visibleTo &&
					col.visibleTo.some(role => visibleTo.includes(role))
			).map((item,idx)=>{
				item['id'] = idx+1;
				return item
			});
		}
	},
	store: {
		cols: [
			{
				id: 1,
				name: "",
				map: "",
				sort: false,
				method: "bulkSelect",
				meta: { type: "updateSegment" ,title: "Store"},
				component: "bulkSelect",
				class: "",
				hasRedirect: false,
				visibleTo: ["all"],
				isChecked: true,
				style: {
					th: {
						width: "3.125rem"
					},
					td: {
						width: "3.125rem"
					}
				}
			},
			{
				id: 2,
				name: "Store Name",
				map: "attributes.name",
				sort: true,
				method: "storeView",
				component: "common-mapping",
				class: "ellipsis",
				hasRedirect: true,
				visibleTo: ["all"],
				isChecked: true,
				disableColoumn:true,
				meta:{type: 'store', "navigateTarget": '_self', "marketplaceLink": true, shouldRedirectToScorecard: true},
				style: {
					th: {
						"min-width": "12.5rem",
						"max-width": "15.625rem"
					},
					td: {
						"min-width": "12.5rem",
						"max-width": "15.625rem"
					}
				}
			},
			{
				id: 3,
				name: COL.AVERAGE_PRICE,
				map: "attributes.avg_price",
				sort: false,
				method: "priceView",
				component: "common-mapping",
				class: "",
				visibleTo: ["all"],
				hasRedirect: true,
				isChecked: true,
				style: {
					"th-class": "text-right",
					"td-class": "text-right"
				}
			},
			{
				id: 4,
				name: "No. of Products",
				map: "attributes.products_count",
				sort: true,
				method: "countView",
				component: "common-mapping",
				class: "font-medium",
				hasRedirect: true,
				isChecked: true,
				visibleTo: ["all"],
				clickable: true,
				style: {
					th: {
						"min-width": "9rem"
					},
					td: {
						"min-width": "9rem"
					},
					"th-class": "text-right",
					"td-class": "text-right"
				}
			},
			{
				id: 5,
				name: COL.STORE_SEGMENT,
				map: "attributes.segment",
				sort: true,
				method: "editSegment",
				component: "common-mapping",
				class: "",
				hasRedirect: false,
				isChecked: true,
				visibleTo: ["all"],
				style: {
					th: {
						"min-width": "7.5rem"
					},
					td: {
						"min-width": "7.5rem"
					}
				}
			},
			{
				id: 6,
				name: "Tracked By",
				map: "attributes.tracked_by",
				sort: true,
				method: "trackedByView",
				component: "common-mapping",
				class: "ellipsis",
				hasRedirect: true,
				isChecked: false,
				visibleTo: ["all"],
				style: {
					th: {
						"min-width": "10rem"
					},
					td: {
						"min-width": "10rem",
					}
				}
			},
			{
				id: 7,
				name: COL.RETAIL_CHANNEL,
				map: "attributes.marketplace",
				sort: true,
				method: "logoFormat",
				component: "common-mapping",
				class: "",
				hasRedirect: false,
				visibleTo: ["all"],
				isChecked: true,
				style: {
					th: {
						"min-width": "11.25rem"
					},
					td: {
						"min-width": "11.25rem"
					}
				}
			},
			{
				id: 8,
				name: COL.COUNTRY,
				map: "attributes.country",
				sort: true,
				method: "countryView",
				component: "common-mapping",
				class: "",
				hasRedirect: false,
				visibleTo: ["all"],
				isChecked: false,
				style: {
					th: {
						"min-width": "11.25rem"
					},
					td: {
						"min-width": "11.25rem"
					}
				}
			},
			{
				id: 9,
				name: COL.AVERAGE_RATING,
				map: "attributes.avg_rating",
				sort: true,
				method: "ratingView",
				component: "common-mapping",
				class: "",
				hasRedirect: true,
				visibleTo: ["all"],
				isChecked: false,
				style: {
					th: {
						"min-width": "7rem",
					},
					td: {
						"min-width": "7rem",
					}
				}
			},
			{
				id: 10,
				name: COL["CREATED_AT"],
				map: "attributes.created_at",
				sort: true,
				method: "dateFormat",
				component: "common-mapping",
				class: "",
				hasRedirect: false,
				visibleTo: ["all"],
				isChecked: true,
				style: {
					th: {
						"min-width": "11.25rem"
					},
					td: {
						"min-width": "11.25rem"
					}
				}
			},
			{
				id: 10,
				name: "Last Updated",
				map: "attributes.last_updated",
				sort: true,
				method: "dateFormat",
				component: "common-mapping",
				class: "",
				hasRedirect: false,
				visibleTo: ["all"],
				isChecked: true,
				style: {
					th: {
						"min-width": "11.25rem"
					},
					td: {
						"min-width": "11.25rem"
					}
				}
			},
			{
				id: 11,
				name: "Actions",
				map: "",
				meta: { type: "storesAll" },
				visibleTo: ["all"],
				sort: false,
				method: "",
				component: "build-action",
				class: "",
				hasRedirect: false,
				isChecked: true,
				style: {
					th: {
						"min-width": "3.125rem"
					},
					td: {
						"min-width": "3.125rem"
					},
					"th-class": "text-right",
					"td-class": "text-right"
				}
			},
			{
				id: 1,
				name: "",
				map: "",
				meta: { title: "Tracker", type: "store-tracker" },
				sort: false,
				method: "bulkSelect",
				component: "bulkSelect",
				class: "",
				hasRedirect: false,
				visibleTo: ["userOnly"],
				isChecked: true,
				style: {
					th: {
						width: "3.125rem"
					},
					td: {
						width: "3.125rem"
					}
				}
			},
			{
				id: 2,
				name: "Tracker Name",
				map: "attributes.name",
				sort: true,
				method: "navigationText",
				component: "common-mapping",
				class: "",
				hasRedirect: true,
				visibleTo: ["userOnly"],
				meta: {urlType: 'profile_val'},
				isChecked: true,
				disableColoumn:true,
				style: {
					th: {
						"min-width": "12.5rem",
						"max-width": "15.625rem"
					},
					td: {
						"min-width": "12.5rem",
						"max-width": "15.625rem"
					}
				}
			},
			{
				id: 3,
				name: "Harvested Products",
				map: "attributes.product_count",
				sort: true,
				method: "countView",
				component: "common-mapping",
				visibleTo: ["userOnly"],
				class: "font-medium",
				hasRedirect: false,
				isChecked: true,
				clickable: true,
				style: {
					th: {
						"min-width": "11.25rem"
					},
					td: {
						"min-width": "11.25rem"
					},
					"th-class": "text-right",
					"td-class": "text-right"
				}
			},
			{
				id: 4,
                name: COL.PDP_LIMIT,
                map: "attributes.overall_product_collect_limit",
                sort: true,
                method: "countView",
                component: "common-mapping",
                visibleTo: ["userOnly"],
                class: "font-medium",
                hasRedirect: false,
                isChecked: false,
				meta:{isPDP: true}
            },
			{
				id: 5,
				name: "Sort By",
				map: "attributes.sort.sort_by_name",
				sort: true,
				method: "defaultView",
				component: "common-mapping",
				visibleTo: ["userOnly"],
				class: "",
				hasRedirect: false,
				isChecked: false
			},
			{
				id: 6,
				name: "Frequency",
				map: "attributes.frequency",
				sort: true,
				method: "defaultView",
				component: "common-mapping",
				visibleTo: ["userOnly"],
				class: "",
				hasRedirect: false,
				isChecked: true,
				clickable: true,
				style: {
					th: {
						"min-width": "8.125rem"
					},
					td: {
						"min-width": "8.125rem"
					}
				}
			},
			
			{
				id: 7,
				name: "Seller Segments",
				map: "attributes.segmentation_tag",
				sort: true,
				method: "badgeView",
				component: "common-mapping",
				visibleTo: ["userOnly"],
				class: "",
				hasRedirect: false,
				isChecked: true,
				style: {
					th: {
						"min-width": "10.5rem"
					},
					td: {
						"min-width": "10.5rem"
					}
				}
			},
			{
				id: 8,
				name: "Harmonised Brand",
				map: "attributes.harmonised_brand.harmonised_brand",
				sort: true,
				method: "defaultView",
				component: "common-mapping",
				class: "",
				hasRedirect: false,
				visibleTo: ["userOnly"],
				isChecked: false
			},
			{
				id: 9,
				name: "Harmonised Product",
				map: "attributes.harmonised_product.harmonised_product",
				sort: true,
				method: "defaultView",
				component: "common-mapping",
				class: "",
				hasRedirect: false,
				visibleTo: ["userOnly"],
				isChecked: false
			},
			{
				id: 10,
				name: "Search only for",
				map: "attributes.keyword_search_filter",
				sort: true,
				method: "filterCriteriaView",
				visibleTo: ["userOnly"],
				component: "common-mapping",
				class: "",
				hasRedirect: false,
				isChecked: false,
				style: {
					th: {
						"min-width": "11.25rem"
					},
					td: {
						"min-width": "11.25rem"
					}
				}
			},
			{
				id: 11,
				name: "Include by Search Term",
				map: "attributes.filter_keywords",
				sort: true,
				method: "filterCriteriaView",
				visibleTo: ["userOnly"],
				component: "common-mapping",
				class: "",
				hasRedirect: false,
				isChecked: false,
				style: {
					th: {
						"min-width": "11.25rem"
					},
					td: {
						"min-width": "11.25rem"
					}
				}
			},
			{
				id: 12,
				name: "Exclude by Search Term",
				map: "attributes.exclude_keywords",
				sort: true,
				method: "filterCriteriaView",
				visibleTo: ["userOnly"],
				component: "common-mapping",
				class: "",
				hasRedirect: false,
				isChecked: false,
				style: {
					th: {
						"min-width": "11.25rem"
					},
					td: {
						"min-width": "11.25rem"
					}
				}
			},
			{
				id: 13,
				name: "Exclude by Brand",
				map: "attributes.exclude_brands",
				sort: true,
				method: "filterCriteriaView",
				visibleTo: ["userOnly"],
				component: "common-mapping",
				class: "",
				hasRedirect: false,
				isChecked: false,
				style: {
					th: {
						"min-width": "11.25rem"
					},
					td: {
						"min-width": "11.25rem"
					}
				}
			},
			{
				id: 14,
				name: "Exclude by Category",
				map: "attributes.exclude_categories",
				sort: true,
				method: "filterCriteriaView",
				visibleTo: ["userOnly"],
				component: "common-mapping",
				class: "",
				hasRedirect: false,
				isChecked: false,
				style: {
					th: {
						"min-width": "11.25rem"
					},
					td: {
						"min-width": "11.25rem"
					}
				}
			},
			{
				id: 15,
				name: COL.RETAIL_CHANNEL,
				map: "attributes.marketplace",
				sort: true,
				method: "logoFormat",
				component: "common-mapping",
				visibleTo: ["userOnly"],
				class: "",
				hasRedirect: false,
				isChecked: false,
				style: {
					th: {
						"min-width": "11.25rem"
					},
					td: {
						"min-width": "11.25rem"
					}
				}
			},
			{
				id: 16,
				name: COL.COUNTRY,
				map: "attributes.country",
				sort: true,
				method: "countryView",
				component: "common-mapping",
				class: "",
				hasRedirect: false,
				visibleTo: ["userOnly"],
				isChecked: false,
				style: {
					th: {
						"min-width": "11.25rem"
					},
					td: {
						"min-width": "11.25rem"
					}
				}
			},
			{
				id: 17,
				name: "Status",
				map: "attributes.is_active",
				sort: true,
				method: "badgeView",
				component: "common-mapping",
				visibleTo: ["userOnly"],
				class: "",
				hasRedirect: false,
				isChecked: true,
				disableColoumn:true,
				style: {
					th: {
						"min-width": "7.5rem"
					},
					td: {
						"min-width": "7.5rem"
					}
				}
			},
			{
				id: 18,
				name: COL.START_DATE,
				map: "attributes.start_at",
				meta: { dateFormat: "MMM d YYYY" },
				sort: true,
				method: "dateFormat",
				component: "common-mapping",
				class: "",
				hasRedirect: false,
				visibleTo: ["userOnly"],
				isChecked: false,
				style: {
					th: {
						"min-width": "13.25rem"
					},
					td: {
						"min-width": "13.25rem"
					}
				}
			},
			{
				id: 19,
				name: COL.END_DATE,
				map: "attributes.expiry_at",
				meta: { dateFormat: "MMM d YYYY" },
				sort: true,
				method: "dateFormat",
				component: "common-mapping",
				class: "",
				hasRedirect: false,
				visibleTo: ["userOnly"],
				isChecked: false,
				style: {
					th: {
						"min-width": "11.25rem"
					},
					td: {
						"min-width": "11.25rem"
					}
				}
			},
			{
				id: 20,
				name: "Last Edited",
				map: "attributes.updated_at",
				sort: true,
				method: "dateFormat",
				component: "common-mapping",
				visibleTo: ["userOnly"],
				class: "",
				hasRedirect: false,
				isChecked: true,
				style: {
					th: {
						"min-width": "11.25rem"
					},
					td: {
						"min-width": "11.25rem"
					}
				}
			},
			{
				id: 21,
				name: "Actions",
				map: "",
				meta: { type: "trackersAll" },
				sort: false,
				method: "",
				component: "build-action",
				visibleTo: ["userOnly"],
				class: "",
				hasRedirect: false,
				isChecked: true,
				style: {
					th: {
						"min-width": "3.125rem"
					},
					td: {
						"min-width": "3.125rem"
					},
					"th-class": "text-center",
					"td-class": "text-center"
				}
			}
		],
		filterColumns(visibleTo: string[] = [GRIDTYPE.ALL, GRIDTYPE.USERONLY]) {
			return this.cols.filter(
				(col) =>
					col.visibleTo &&
					col.visibleTo.some(role => visibleTo.includes(role))
			).map((item,idx)=>{
				item['id'] = idx+1;
				return item
			})
		}
	},
	brand: {
		cols: [
			{
				id: 1,
				name: 'Brand Name',
				map: "attributes.name",
				sort: true,
				method: "defaultView",
				component: "common-mapping",
				class: "",
				hasRedirect: true,
				visibleTo: [GRIDTYPE.ALL],
				isChecked: true,
				style: {
					th: {
						"min-width": "8.5rem"
					},
					td: {
						"min-width": "8.5rem"
					}
				}
			},
			{
				id: 2,
				name: "No. of Products",
				map: "attributes.products_count",
				sort: true,
				method: "countView",
				component: "common-mapping",
				class: "font-medium",
				hasRedirect: true,
				isChecked: true,
				visibleTo: [GRIDTYPE.ALL],
				clickable: true,
				style: {
					th: {
						"min-width": "9rem"
					},
					td: {
						"min-width": "9rem"
					},
					"th-class": "text-right",
					"td-class": "text-right"
				}
			},
			{
				id: 3,
				name: COL.RETAIL_CHANNEL,
				map: "attributes.marketplace",
				sort: true,
				method: "logoFormat",
				component: "common-mapping",
				class: "",
				hasRedirect: false,
				visibleTo: [GRIDTYPE.ALL],
				isChecked: true,
				style: {
					th: {
						"min-width": "11.25rem"
					},
					td: {
						"min-width": "11.25rem"
					}
				}
			},
			{
				id: 4,
				name: COL.COUNTRY,
				map: "attributes.country",
				sort: true,
				method: "countryView",
				component: "common-mapping",
				class: "",
				hasRedirect: false,
				visibleTo: [GRIDTYPE.ALL],
				isChecked: false,
				style: {
					th: {
						"min-width": "11.25rem"
					},
					td: {
						"min-width": "11.25rem"
					}
				}
			},
			{
				id: 5,
				name: "Tracked By",
				map: "attributes.tracked_by",
				sort: true,
				method: "trackedByView",
				component: "common-mapping",
				hasRedirect: true,
				isChecked: false,
				visibleTo: [GRIDTYPE.ALL],
				style: {
					th: {
						"min-width": "10rem"
					},
					td: {
						"min-width": "10rem",
					}
				}
			},
			{
				id: 6,
				name: COL["CREATED_AT"],
				map: "attributes.created_at",
				sort: true,
				method: "dateFormat",
				component: "common-mapping",
				class: "",
				hasRedirect: false,
				visibleTo: ["all"],
				isChecked: true,
				style: {
					th: {
						"min-width": "11.25rem"
					},
					td: {
						"min-width": "11.25rem"
					}
				}
			},
			{
				id: 7,
				name: COL.LAST_UPDATED,
				map: "attributes.last_updated",
				sort: true,
				method: "dateFormat",
				component: "common-mapping",
				class: "",
				hasRedirect: false,
				visibleTo: [GRIDTYPE.ALL],
				isChecked: true,
			},
			{
				id: 8,
				name: 'Mapped To',
				map: "attributes.harmonised_data",
				sort: true,
				method: "mappedToHarmonisation",
				component: "common-mapping",
				class: "",
				hasRedirect: false,
				visibleTo: [GRIDTYPE.ALL],
				isChecked: true,
			},
			{
				id: 9,
				name: 'Action',
				map: "",
				sort: true,
				meta: { type: "addHarmonisation" },
				method: "addHarmonisation",
				component: "common-mapping",
				class: "",
				hasRedirect: false,
				visibleTo: [GRIDTYPE.ALL],
				isChecked: true,
			},
			{
				id: 1,
				name: "",
				map: "",
				meta: { title: "Tracker", type: "brand-tracker" },
				sort: false,
				method: "bulkSelect",
				component: "bulkSelect",
				class: "",
				hasRedirect: false,
				isChecked: true,
				visibleTo: ["userOnly"],
				style: {
					th: {
						width: "3.125rem"
					},
					td: {
						width: "3.125rem"
					}
				}
			},
			{
				id: 2,
				name: "Tracker Name",
				map: "attributes.name",
				sort: true,
				method: "navigationText",
				component: "common-mapping",
				class: "",
				hasRedirect: true,
				visibleTo: ["userOnly"],
				isChecked: true,
				meta: {urlType: 'profile_val'},
				disableColoumn:true,
				style: {
					th: {
						"min-width": "12.5rem",
						"max-width": "15.625rem"
					},
					td: {
						"min-width": "12.5rem",
						"max-width": "15.625rem"
					}
				}
			},
			{
				id: 3,
				name: "Harvested Products",
				map: "attributes.product_count",
				sort: true,
				method: "countView",
				component: "common-mapping",
				visibleTo: ["userOnly"],
				class: "font-medium",
				hasRedirect: false,
				isChecked: true,
				clickable: true,
				style: {
					th: {
						"min-width": "11.25rem"
					},
					td: {
						"min-width": "11.25rem"
					},
					"th-class": "text-right",
					"td-class": "text-right"
				}
			},
			{
				id: 4,
                name: COL.PDP_LIMIT,
                map: "attributes.overall_product_collect_limit",
                sort: true,
                method: "countView",
                component: "common-mapping",
                visibleTo: ["userOnly"],
                class: "font-medium",
                hasRedirect: false,
                isChecked: false,
				meta:{isPDP: true}
            },
			{
				id: 5,
				name: "Sort By",
				map: "attributes.sort.sort_by_name",
				sort: true,
				method: "defaultView",
				component: "common-mapping",
				visibleTo: ["userOnly"],
				class: "",
				hasRedirect: false,
				isChecked: false
			},
			{
				id: 6,
				name: "Frequency",
				map: "attributes.frequency",
				sort: true,
				method: "defaultView",
				component: "common-mapping",
				visibleTo: ["userOnly"],
				class: "",
				hasRedirect: false,
				isChecked: true,
				style: {
					th: {
						"min-width": "8.125rem"
					},
					td: {
						"min-width": "8.125rem"
					}
				}
			},
			
			{
				id: 7,
				name: "Harmonised Brand",
				map: "attributes.harmonised_brand.harmonised_brand",
				sort: true,
				method: "defaultView",
				component: "common-mapping",
				class: "",
				hasRedirect: false,
				visibleTo: ["userOnly"],
				isChecked: false
			},
			{
				id:8,
				name: "Harmonised Product",
				map: "attributes.harmonised_product.harmonised_product",
				sort: true,
				method: "defaultView",
				component: "common-mapping",
				class: "",
				hasRedirect: false,
				visibleTo: ["userOnly"],
				isChecked: false
			},
			{
				id: 9,
				name: "Search only for",
				map: "attributes.keyword_search_filter",
				sort: true,
				method: "filterCriteriaView",
				component: "common-mapping",
				visibleTo: ["userOnly"],
				class: "",
				hasRedirect: false,
				isChecked: false,
				style: {
					th: {
						"min-width": "11.25rem"
					},
					td: {
						"min-width": "11.25rem"
					}
				}
			},
			{
				id: 10,
				name: "Include by Search Term",
				map: "attributes.filter_keywords",
				sort: true,
				method: "filterCriteriaView",
				component: "common-mapping",
				visibleTo: ["userOnly"],
				class: "",
				hasRedirect: false,
				isChecked: false,
				style: {
					th: {
						"min-width": "11.25rem"
					},
					td: {
						"min-width": "11.25rem"
					}
				}
			},
			{
				id: 11,
				name: "Exclude by Search Term",
				map: "attributes.exclude_keywords",
				sort: true,
				method: "filterCriteriaView",
				component: "common-mapping",
				visibleTo: ["userOnly"],
				class: "",
				hasRedirect: false,
				isChecked: false,
				style: {
					th: {
						"min-width": "11.25rem"
					},
					td: {
						"min-width": "11.25rem"
					}
				}
			},
			{
				id: 12,
				name: "Exclude by Store",
				map: "attributes.exclude_stores",
				sort: true,
				method: "filterCriteriaView",
				component: "common-mapping",
				visibleTo: ["userOnly"],
				class: "",
				hasRedirect: false,
				isChecked: false,
				style: {
					th: {
						"min-width": "11.25rem"
					},
					td: {
						"min-width": "11.25rem"
					}
				}
			},
			{
				id: 13,
				name: "Exclude by Category",
				map: "attributes.exclude_categories",
				sort: true,
				method: "filterCriteriaView",
				component: "common-mapping",
				visibleTo: ["userOnly"],
				class: "",
				hasRedirect: false,
				isChecked: false,
				style: {
					th: {
						"min-width": "11.25rem"
					},
					td: {
						"min-width": "11.25rem"
					}
				}
			},
			{
				id: 14,
				name: COL.RETAIL_CHANNEL,
				map: "attributes.marketplace",
				sort: true,
				method: "logoFormat",
				visibleTo: [GRIDTYPE.USERONLY],
				component: "common-mapping",
				class: "",
				hasRedirect: false,
				isChecked: false,
				style: {
					th: {
						"min-width": "11.25rem"
					},
					td: {
						"min-width": "11.25rem"
					}
				}
			},
			{
				id: 15,
				name: COL.COUNTRY,
				map: "attributes.country",
				sort: true,
				method: "countryView",
				component: "common-mapping",
				class: "",
				hasRedirect: false,
				visibleTo: [GRIDTYPE.USERONLY],
				isChecked: false,
				style: {
					th: {
						"min-width": "11.25rem"
					},
					td: {
						"min-width": "11.25rem"
					}
				}
			},
			{
				id: 16,
				name: "Status",
				map: "attributes.is_active",
				sort: true,
				method: "badgeView",
				component: "common-mapping",
				visibleTo: ["userOnly"],
				class: "",
				hasRedirect: false,
				isChecked: true,
				disableColoumn:true,
				style: {
					th: {
						"min-width": "7.5rem"
					},
					td: {
						"min-width": "7.5rem"
					}
				}
			},
			{
				id: 17,
				name: COL.START_DATE,
				map: "attributes.start_at",
				meta: { dateFormat: "MMM d YYYY" },
				sort: true,
				method: "dateFormat",
				component: "common-mapping",
				class: "",
				hasRedirect: false,
				visibleTo: ["userOnly"],
				isChecked: false,
				style: {
					th: {
						"min-width": "13.25rem"
					},
					td: {
						"min-width": "13.25rem"
					}
				}
			},
			{
				id: 18,
				name: COL.END_DATE,
				map: "attributes.expiry_at",
				meta: { dateFormat: "MMM d YYYY" },
				sort: true,
				method: "dateFormat",
				component: "common-mapping",
				class: "",
				hasRedirect: false,
				visibleTo: ["userOnly"],
				isChecked: false,
				style: {
					th: {
						"min-width": "11.25rem"
					},
					td: {
						"min-width": "11.25rem"
					}
				}
			},
			{
				id: 19,
				name: "Last Edited",
				map: "attributes.updated_at",
				sort: true,
				method: "dateFormat",
				component: "common-mapping",
				visibleTo: [GRIDTYPE.USERONLY],
				class: "",
				hasRedirect: false,
				isChecked: true,
				style: {
					th: {
						"min-width": "11.25rem"
					},
					td: {
						"min-width": "11.25rem"
					}
				}
			},
			{
				id: 20,
				name: "Actions",
				map: "",
				meta: { type: "trackersAll" },
				sort: false,
				method: "",
				component: "build-action",
				visibleTo: ["userOnly"],
				class: "",
				hasRedirect: false,
				isChecked: true,
				style: {
					th: {
						"min-width": "3.125rem"
					},
					td: {
						"min-width": "3.125rem"
					},
					"th-class": "text-center",
					"td-class": "text-center"
				}
			}
		],
		filterColumns(visibleTo: string[] = [GRIDTYPE.ALL, GRIDTYPE.USERONLY,]) {
			return this.cols.filter(
				(col) =>
					col.visibleTo &&
					col.visibleTo.some(role => visibleTo.includes(role))
			).map((item,idx)=>{
				item['id'] = idx+1;
				return item
			});
		}
	},
	keyword: {
		cols: [
			{
				id: 1,
				name: "",
				map: "",
				meta: { title: "Tracker", type: "keyword-tracker" },
				sort: false,
				method: "bulkSelect",
				component: "bulkSelect",
				class: "",
				hasRedirect: false,
				isChecked: true,
				visibleTo: ["userOnly"],
				style: {
					th: {
						width: "3.125rem"
					},
					td: {
						width: "3.125rem"
					}
				}
			},
			{
				id: 2,
				name: "Tracker Name",
				map: "attributes.name",
				sort: true,
				method: "navigationText",
				component: "common-mapping",
				class: "",
				hasRedirect: true,
				visibleTo: ["userOnly"],
				isChecked: true,
				disableColoumn:true,
				style: {
					th: {
						"min-width": "12.5rem",
						"max-width": "15.625rem"
					},
					td: {
						"min-width": "12.5rem",
						"max-width": "15.625rem"
					}
				}
			},
			{
				id: 3,
				name: "Harvested Products",
				map: "attributes.product_count",
				sort: true,
				method: "countView",
				component: "common-mapping",
				class: "font-medium",
				hasRedirect: false,
				isChecked: true,
				visibleTo: ["userOnly"],
				clickable: true,
				style: {
					th: {
						"min-width": "11.25rem"
					},
					td: {
						"min-width": "11.25rem"
					},
					"th-class": "text-right",
					"td-class": "text-right"
				}
			},
			{
                id: 4,
                name: COL.PDP_LIMIT,
                map: "attributes.overall_product_collect_limit",
                sort: true,
                method: "countView",
                component: "common-mapping",
                visibleTo: ["userOnly"],
                class: "font-medium",
                hasRedirect: false,
                isChecked: false,
				meta:{isPDP: true}
            },
			{
				id: 5,
				name: "Sort By",
				map: "attributes.sort.sort_by_name",
				sort: true,
				method: "defaultView",
				component: "common-mapping",
				visibleTo: ["userOnly"],
				class: "",
				hasRedirect: false,
				isChecked: false
			},
			{
				id: 6,
				name: "Frequency",
				map: "attributes.frequency",
				sort: true,
				method: "defaultView",
				component: "common-mapping",
				class: "",
				hasRedirect: false,
				visibleTo: ["userOnly"],
				isChecked: true,
				style: {
					th: {
						"min-width": "8.125rem"
					},
					td: {
						"min-width": "8.125rem"
					}
				}
			},
			{
				id: 7,
				name: "Harmonised Brand",
				map: "attributes.harmonised_brand.harmonised_brand",
				sort: true,
				method: "defaultView",
				component: "common-mapping",
				class: "",
				hasRedirect: false,
				visibleTo: ["userOnly"],
				isChecked: false,
				style: {
					th: {
						"min-width": "9.375rem"
					},
					td: {
						"min-width": "9.375rem"
					}
				}
			},
			{
				id: 8,
				name: "Harmonised Product",
				map: "attributes.harmonised_product.harmonised_product",
				sort: true,
				method: "defaultView",
				component: "common-mapping",
				class: "",
				hasRedirect: false,
				visibleTo: ["userOnly"],
				isChecked: false,
				style: {
					th: {
						"min-width": "9.375rem"
					},
					td: {
						"min-width": "9.375rem"
					}
				}
			},
			{
				id: 9,
				name: "Include by Search Term",
				map: "attributes.filter_keywords",
				sort: true,
				method: "filterCriteriaView",
				component: "common-mapping",
				class: "",
				hasRedirect: false,
				isChecked: false,
				visibleTo: ["userOnly"],
				style: {
					th: {
						"min-width": "11.25rem"
					},
					td: {
						"min-width": "11.25rem"
					}
				}
			},
			{
				id: 10,
				name: "Exclude by Search Term",
				map: "attributes.exclude_keywords",
				sort: true,
				method: "filterCriteriaView",
				component: "common-mapping",
				class: "",
				hasRedirect: false,
				isChecked: false,
				visibleTo: ["userOnly"],
				style: {
					th: {
						"min-width": "11.25rem"
					},
					td: {
						"min-width": "11.25rem"
					}
				}
			},
			{
				id: 11,
				name: "Exclude by Store",
				map: "attributes.exclude_stores",
				sort: true,
				method: "filterCriteriaView",
				component: "common-mapping",
				class: "",
				hasRedirect: false,
				isChecked: false,
				visibleTo: ["userOnly"],
				style: {
					th: {
						"min-width": "11.25rem"
					},
					td: {
						"min-width": "11.25rem"
					}
				}
			},
			{
				id: 12,
				name: "Exclude by Brand",
				map: "attributes.exclude_brands",
				sort: true,
				method: "filterCriteriaView",
				component: "common-mapping",
				class: "",
				hasRedirect: false,
				isChecked: false,
				visibleTo: ["userOnly"],
				style: {
					th: {
						"min-width": "11.25rem"
					},
					td: {
						"min-width": "11.25rem"
					}
				}
			},
			{
				id: 13,
				name: "Exclude by Categories",
				map: "attributes.exclude_categories",
				sort: true,
				method: "filterCriteriaView",
				component: "common-mapping",
				class: "",
				hasRedirect: false,
				isChecked: false,
				visibleTo: ["userOnly"],
				style: {
					th: {
						"min-width": "11.25rem"
					},
					td: {
						"min-width": "11.25rem"
					}
				}
			},
			{
				id: 14,
				name: COL.RETAIL_CHANNEL,
				map: "attributes.marketplace",
				sort: true,
				method: "logoFormat",
				component: "common-mapping",
				class: "",
				hasRedirect: false,
				visibleTo: [GRIDTYPE.USERONLY],
				isChecked: true,
				style: {
					th: {
						"min-width": "11.25rem"
					},
					td: {
						"min-width": "11.25rem"
					}
				}
			},
			{
				id: 15,
				name: COL.COUNTRY,
				map: "attributes.country",
				sort: true,
				method: "countryView",
				component: "common-mapping",
				class: "",
				hasRedirect: false,
				visibleTo: [GRIDTYPE.USERONLY],
				isChecked: false,
				style: {
					th: {
						"min-width": "11.25rem"
					},
					td: {
						"min-width": "11.25rem"
					}
				}
			},
			{
				id: 16,
				name: "Status",
				map: "attributes.is_active",
				sort: true,
				method: "badgeView",
				component: "common-mapping",
				class: "",
				hasRedirect: false,
				isChecked: true,
				clickable: true,
				visibleTo: ["userOnly"],
				disableColoumn:true,
				style: {
					th: {
						"min-width": "7.5rem"
					},
					td: {
						"min-width": "7.5rem"
					}
				}
			},
			{
				id: 17,
				name: COL.START_DATE,
				map: "attributes.start_at",
				meta: { dateFormat: "MMM d YYYY" },
				sort: true,
				method: "dateFormat",
				component: "common-mapping",
				class: "",
				hasRedirect: false,
				visibleTo: ["userOnly"],
				isChecked: false,
				style: {
					th: {
						"min-width": "13.25rem"
					},
					td: {
						"min-width": "13.25rem"
					}
				}
			},
			{
				id: 18,
				name: COL.END_DATE,
				map: "attributes.expiry_at",
				meta: { dateFormat: "MMM d YYYY" },
				sort: true,
				method: "dateFormat",
				component: "common-mapping",
				class: "",
				hasRedirect: false,
				visibleTo: ["userOnly"],
				isChecked: false,
				style: {
					th: {
						"min-width": "11.25rem"
					},
					td: {
						"min-width": "11.25rem"
					}
				}
			},
			{
				id: 19,
				name: "Last Edited",
				map: "attributes.updated_at",
				sort: true,
				method: "dateFormat",
				component: "common-mapping",
				class: "",
				hasRedirect: false,
				isChecked: true,
				visibleTo: ["userOnly"],
				style: {
					th: {
						"min-width": "11.25rem"
					},
					td: {
						"min-width": "11.25rem"
					}
				}
			},
			{
				id: 20,
				name: "Actions",
				map: "",
				meta: { type: "trackersAll" },
				sort: false,
				method: "",
				component: "build-action",
				class: "",
				hasRedirect: false,
				isChecked: true,
				visibleTo: ["userOnly"],
				style: {
					th: {
						"min-width": "3.125rem"
					},
					td: {
						"min-width": "3.125rem"
					},
					"th-class": "text-center",
					"td-class": "text-center"
				}
			}
		],
		filterColumns(visibleTo: string[] = [GRIDTYPE.ALL, GRIDTYPE.USERONLY]) {
			return this.cols.filter(
				(col) =>
					col.visibleTo &&
					col.visibleTo.some(role => visibleTo.includes(role))
			).map((item,idx)=>{
				item['id'] = idx+1;
				return item
			});
		}
	},
	category: {
		cols: [
			{
				id: 1,
				name: 'Category Name',
				map: "attributes.name",
				sort: true,
				method: "defaultCatagoryView",
				component: "common-mapping",
				class: "",
				hasRedirect: true,
				visibleTo: [GRIDTYPE.ALL],
				isChecked: true,
				style: {
					th: {
						"min-width": "8.5rem"
					},
					td: {
						"min-width": "8.5rem"
					}
				}
			},
			{
				id: 2,
				name: "No. of Products",
				map: "attributes.products_count",
				sort: true,
				method: "countView",
				component: "common-mapping",
				class: "font-medium",
				hasRedirect: true,
				isChecked: true,
				visibleTo: [GRIDTYPE.ALL],
				clickable: true,
				style: {
					th: {
						"min-width": "9rem"
					},
					td: {
						"min-width": "9rem"
					},
					"th-class": "text-left",
					"td-class": "text-left"
				}
			},
			{
				id: 3,
				name: COL.RETAIL_CHANNEL,
				map: "attributes.marketplace",
				sort: true,
				method: "logoFormat",
				component: "common-mapping",
				class: "",
				hasRedirect: false,
				visibleTo: [GRIDTYPE.ALL],
				isChecked: true,
				style: {
					th: {
						"min-width": "11.25rem"
					},
					td: {
						"min-width": "11.25rem"
					}
				}
			},
			{
				id: 4,
				name: COL.COUNTRY,
				map: "attributes.country",
				sort: true,
				method: "countryView",
				component: "common-mapping",
				class: "",
				hasRedirect: false,
				visibleTo: [GRIDTYPE.ALL],
				isChecked: false,
				style: {
					th: {
						"min-width": "11.25rem"
					},
					td: {
						"min-width": "11.25rem"
					}
				}
			},
			{
				id: 5,
				name: "Tracked By",
				map: "attributes.tracked_by",
				sort: true,
				method: "trackedByView",
				component: "common-mapping",
				hasRedirect: true,
				isChecked: false,
				visibleTo: [GRIDTYPE.ALL],
				style: {
					th: {
						"min-width": "10rem"
					},
					td: {
						"min-width": "10rem",
					}
				}
			},
			{
				id: 6,
				name: COL.LAST_UPDATED,
				map: "attributes.last_updated",
				sort: true,
				method: "dateFormat",
				component: "common-mapping",
				class: "",
				hasRedirect: false,
				visibleTo: [GRIDTYPE.ALL],
				isChecked: true,
			},
			{
				id: 1,
				name: "",
				map: "",
				meta: { title: "Tracker", type: "category-tracker" },
				sort: false,
				method: "bulkSelect",
				component: "bulkSelect",
				class: "",
				hasRedirect: false,
                visibleTo: ["userOnly"],
				isChecked: true,
				style: {
					th: {
						width: "3.125rem"
					},
					td: {
						width: "3.125rem"
					}
				}
			},
			{
				id: 2,
				name: "Tracker Name",
				map: "attributes.name",
				sort: true,
				method: "navigationText",
				component: "common-mapping",
				class: "",
				hasRedirect: true,
                visibleTo: ["userOnly"],
				isChecked: true,
				meta: {urlType: 'profile_val'},
				disableColoumn:true,
				style: {
					th: {
						"min-width": "12.5rem",
						"max-width": "15.625rem"
					},
					td: {
						"min-width": "12.5rem",
						"max-width": "15.625rem"
					}
				}
			},
			{
				id: 3,
				name: "Harvested Products",
				map: "attributes.product_count",
				sort: true,
				method: "countView",
				component: "common-mapping",
				class: "font-medium",
                visibleTo: ["userOnly"],
				hasRedirect: false,
				isChecked: true,
				clickable: true,
				style: {
					th: {
						"min-width": "11.25rem"
					},
					td: {
						"min-width": "11.25rem"
					},
					"th-class": "text-right",
					"td-class": "text-right"
				}
			},
			{
                id: 4,
                name: COL.PDP_LIMIT,
                map: "attributes.overall_product_collect_limit",
                sort: true,
                method: "countView",
                component: "common-mapping",
                visibleTo: ["userOnly"],
                class: "font-medium",
                hasRedirect: false,
                isChecked: false,
				meta:{isPDP: true}
            },
			{
				id: 5,
				name: "Sort By",
				map: "attributes.sort.sort_by_name",
				sort: true,
				method: "defaultView",
				component: "common-mapping",
				visibleTo: ["userOnly"],
				class: "",
				hasRedirect: false,
				isChecked: false
			},
			{
				id: 6,
				name: "Frequency",
				map: "attributes.frequency",
				sort: true,
				method: "defaultView",
				component: "common-mapping",
				class: "",
				hasRedirect: false,
                visibleTo: ["userOnly"],
				isChecked: true,
				style: {
					th: {
						"min-width": "8.125rem"
					},
					td: {
						"min-width": "8.125rem"
					}
				}
			},
			{
				id: 7,
				name: COL.AI_SERVICES,
				map: "attributes.ml_service_codes",
				sort: true,
				method: "aiServices",
				component: "common-mapping",
				class: "",
				hasRedirect: false,
				visibleTo: ["userOnly"],
				isChecked: true,
				style: {
					th: {
						"min-width": "11.25rem"
					},
					td: {
						"min-width": "11.25rem"
					}
				}
			},
			{
				id: 8,
				name: "Harmonised Brand",
				map: "attributes.harmonised_brand.harmonised_brand",
				sort: true,
				method: "defaultView",
				component: "common-mapping",
				class: "",
				hasRedirect: false,
				visibleTo: ["userOnly"],
				isChecked: false,
				style: {
					th: {
						"min-width": "9.375rem"
					},
					td: {
						"min-width": "9.375rem"
					}
				}
			},
			{
				id: 9,
				name: "Harmonised Product",
				map: "attributes.harmonised_product.harmonised_product",
				sort: true,
				method: "defaultView",
				component: "common-mapping",
				class: "",
				hasRedirect: false,
				visibleTo: ["userOnly"],
				isChecked: false,
				style: {
					th: {
						"min-width": "9.375rem"
					},
					td: {
						"min-width": "9.375rem"
					}
				}
			},
			{
				id: 10,
				name: "Search only for",
				map: "attributes.keyword_search_filter",
				sort: true,
				method: "filterCriteriaView",
				component: "common-mapping",
				class: "",
				hasRedirect: false,
				isChecked: false,
				visibleTo: ["userOnly"],
				style: {
					th: {
						"min-width": "11.25rem"
					},
					td: {
						"min-width": "11.25rem"
					}
				}
			},
			{
				id: 11,
				name: "Include by Search Term",
				map: "attributes.filter_keywords",
				sort: true,
				method: "filterCriteriaView",
				component: "common-mapping",
				class: "",
				hasRedirect: false,
				isChecked: false,
				visibleTo: ["userOnly"],
				style: {
					th: {
						"min-width": "11.25rem"
					},
					td: {
						"min-width": "11.25rem"
					}
				}
			},
			{
				id: 12,
				name: "Exclude by Search Term",
				map: "attributes.exclude_keywords",
				sort: true,
				method: "filterCriteriaView",
				component: "common-mapping",
				class: "",
				hasRedirect: false,
				isChecked: false,
				visibleTo: ["userOnly"],
				style: {
					th: {
						"min-width": "11.25rem"
					},
					td: {
						"min-width": "11.25rem"
					}
				}
			},
			{
				id: 13,
				name: "Exclude by Store",
				map: "attributes.exclude_stores",
				sort: true,
				method: "filterCriteriaView",
				component: "common-mapping",
				class: "",
				hasRedirect: false,
				isChecked: false,
				visibleTo: ["userOnly"],
				style: {
					th: {
						"min-width": "11.25rem"
					},
					td: {
						"min-width": "11.25rem"
					}
				}
			},
			{
				id: 14,
				name: "Exclude by Brand",
				map: "attributes.exclude_brands",
				sort: true,
				method: "filterCriteriaView",
				component: "common-mapping",
				class: "",
				hasRedirect: false,
				isChecked: false,
				visibleTo: ["userOnly"],
				style: {
					th: {
						"min-width": "11.25rem"
					},
					td: {
						"min-width": "11.25rem"
					}
				}
			},
			{
				id: 15,
				name: COL.RETAIL_CHANNEL,
				map: "attributes.marketplace",
				sort: true,
				method: "logoFormat",
				component: "common-mapping",
                visibleTo: ["userOnly"],
				class: "",
				hasRedirect: false,
				isChecked: false,
				style: {
					th: {
						"min-width": "11.25rem"
					},
					td: {
						"min-width": "11.25rem"
					}
				}
			},
			{
				id: 16,
				name: COL.COUNTRY,
				map: "attributes.country",
				sort: true,
				method: "countryView",
				component: "common-mapping",
				class: "",
				hasRedirect: false,
				visibleTo: ["userOnly"],
				isChecked: false,
				style: {
					th: {
						"min-width": "11.25rem"
					},
					td: {
						"min-width": "11.25rem"
					}
				}
			},
			{
				id: 17,
				name: "Status",
				map: "attributes.is_active",
				sort: true,
				method: "badgeView",
				component: "common-mapping",
                visibleTo: ["userOnly"],
				class: "",
				hasRedirect: false,
				isChecked: true,
				disableColoumn:true,
				style: {
					th: {
						"min-width": "7.5rem"
					},
					td: {
						"min-width": "7.5rem"
					}
				}
			},
			{
				id: 18,
				name: COL.START_DATE,
				map: "attributes.start_at",
				meta: { dateFormat: "MMM d YYYY" },
				sort: true,
				method: "dateFormat",
				component: "common-mapping",
				class: "",
				hasRedirect: false,
				visibleTo: ["userOnly"],
				isChecked: false,
				style: {
					th: {
						"min-width": "13.25rem"
					},
					td: {
						"min-width": "13.25rem"
					}
				}
			},
			{
				id: 19,
				name: COL.END_DATE,
				map: "attributes.expiry_at",
				meta: { dateFormat: "MMM d YYYY" },
				sort: true,
				method: "dateFormat",
				component: "common-mapping",
				class: "",
				hasRedirect: false,
				visibleTo: ["userOnly"],
				isChecked: false,
				style: {
					th: {
						"min-width": "11.25rem"
					},
					td: {
						"min-width": "11.25rem"
					}
				}
			},
			{
				id: 20,
				name: "Last Edited",
				map: "attributes.updated_at",
				sort: true,
				method: "dateFormat",
                visibleTo: [GRIDTYPE.USERONLY],
				component: "common-mapping",
				class: "",
				hasRedirect: false,
				isChecked: true,
				style: {
					th: {
						"min-width": "11.25rem"
					},
					td: {
						"min-width": "11.25rem"
					}
				}
			},
			{
				id: 21,
				name: "Actions",
				map: "",
				meta: { type: "trackersAll" },
				sort: false,
				method: "",
				component: "build-action",
                visibleTo: ["userOnly"],
				class: "",
				hasRedirect: false,
				isChecked: true,
				style: {
					th: {
						"min-width": "3.125rem"
					},
					td: {
						"min-width": "3.125rem"
					},
					"th-class": "text-center",
					"td-class": "text-center"
				}
			}
		],
		filterColumns(visibleTo: string[] = [GRIDTYPE.ALL, GRIDTYPE.USERONLY]) {
			return this.cols.filter(
				(col) =>
					col.visibleTo &&
					col.visibleTo.some(role => visibleTo.includes(role))
			).map((item,idx)=>{
				item['id'] = idx+1;
				return item
			});
		}
	}
};
