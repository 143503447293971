<div class="legends">
	<div class="legends-container d-flex position-relative justify-content-start">
		<div
			class="info-text"
			*ngFor="let legendItem of data?.slice(0,noOfSlice); let i = index"
		>
			<div
				class="info-color"
				[ngStyle]="{'background-color':legendItem?.color}"
			></div>
			<span
				class="text"
				showTooltipOnTextOverflow
				[data]="legendItem?.name | translate"
				>{{legendItem?.name | translate}}</span
			>
		</div>
		<div ngbDropdown class="d-inline-block position-static" display="dynamic">
			<span id="dropdownBasic1" ngbDropdownToggle class="cursor-pointer"
				><span class="legends-list"
					>{{data?.length > noOfSlice ? ('+'+ (data?.length-noOfSlice)) :
					""}}</span
				>
			</span>
			<div
				ngbDropdownMenu
				aria-labelledby="dropdownBasic1"
				class="commonDropdown"
			>
				<div
					class="info-text"
					*ngFor="let legendItem of data.slice(noOfSlice,data?.length); let i = index"
				>
					<div
						class="info-color"
						[ngStyle]="{'background-color':legendItem.color}"
					></div>
					<span
						class="text"
						showTooltipOnTextOverflow
						[data]="legendItem?.name"
						>{{legendItem?.name | translate}}</span
					>
				</div>
			</div>
		</div>
	</div>
</div>
