<div class="catalog-header" *ngIf="bannerData.data">
	<ul class="catalog-list" *ngIf="scorecardInfo.view==='profile'">
		<li>
			<div class="d-inline-block align-middle">
				<div>
					<span class="verifiedName"
						>{{bannerData.data?.name ? bannerData.data?.name : '-'}}</span
					>
					<a
						*ngIf="bannerData.data?.type!='keyword'"
						href="{{bannerData.data?.profile_val}}"
						target="_blank"
						class="navigate-link"
						placement="{{commonFunctions.rtlStatus? 'left' : 'right'}}"
						ngbTooltip="{{'Go to Product Channel' | translate}}"
						tooltipClass="info-custom-tooltip"
						><i class="icon icon-Open-link"></i
					></a>
				</div>
				<span class="segmentation_style">
					<label class="segmentation_label"
						>{{(scorecardInfo.type | titlecase | translate)}}</label
					>
				</span>
			</div>
		</li>
		<li>
			<span class="labelName" translate>Unit Sold</span>
			<div class="d-flex align-items-center gap-8">
				<span
					class="common-text"
					*ngIf="commonFunctions.isIntegerOrDefined(bannerData.data?.unit_sold);else noData"
					>{{bannerData.data.unit_sold | numberSuffix: 2 }}</span
				>
			</div>
		</li>
		<li>
			<span class="labelName" translate>Channel</span>
			<div class="shpeeName d-flex align-items-center gap-6">
				<app-marketplace-store-logo
					[storeName]="bannerData.data?.marketplace"
				></app-marketplace-store-logo>
				<span class="labelValue"
					>{{bannerData.data?.marketplace ? bannerData.data?.marketplace :
					'-'}}</span
				>
			</div>
		</li>
		<li *ngIf="bannerData.data.type==='store'">
			<span class="labelName" translate>Segment</span>
			<span
				class="badgeView mt-2 {{commonFunctions.differentBadges[bannerData.data?.segmentation_tag].class}}"
				*ngIf="bannerData.data?.segmentation_tag"
				translate
				>{{commonFunctions.differentBadges[bannerData.data?.segmentation_tag].name}}</span
			>
			<span *ngIf="!bannerData.data?.segmentation_tag">-</span>
		</li>
		<li>
			<span class="labelName" translate>Include by Criteria</span>
			<app-tags-dropdown
				class="mt-2"
				[dropdownType]="'dashboard-header'"
				[item]="bannerData?.data?.filter_keywords"
			></app-tags-dropdown>
		</li>
		<li>
			<span class="labelName" translate>Active Products</span>
			<span class="common-text"
				>{{bannerData?.data?.product_count | number}}</span
			>
		</li>
		<li>
			<span class="labelName" translate>Last Edited</span>
			<span class="labelValue mt-2"
				>{{bannerData.data?.updated_at ?
				commonFunctions.formatSimpleDate(bannerData.data?.updated_at) :
				'-'}}</span
			>
		</li>
		<li>
			<div class="catalogue-action-content d-flex align-items-center">
				<!-- <button [disabled]="!permissionsForNotification.write_access" class="btn icon-only-btn notification" (click)="goToNotificationsProfile()"
                        placement="{{commonFunctions.rtlStatus ? 'right' : 'left'}}" ngbTooltip="{{'Create Notification' | translate}}" tooltipClass="info-custom-tooltip"><i class="icon icon-Notification-on"></i></button> -->
				<button
					class="btn icon-only-btn manage-btn"
					placement="{{commonFunctions.rtlStatus ? 'right' : 'left'}}"
					ngbTooltip="{{'Manage Products' | translate}}"
					tooltipClass="info-custom-tooltip"
					(click)="goToManageProducts()"
					[disabled]="!permissionsForBuild.read_access"
				>
					<i class="icon icon-Edit"></i>
				</button>
			</div>
		</li>
	</ul>
	<ul *ngIf="scorecardInfo.view==='harmonised'" class="catalog-list">
		<li>
			<div class="d-inline-block align-middle">
				<div>
					<span class="verifiedName"
						>{{bannerData.data?.name ? bannerData.data?.name : '-'}}</span
					>
				</div>
				<span class="segmentation_style">
					<label class="segmentation_label"
						>{{(scorecardInfo.type | titlecase | translate)}}</label
					>
				</span>
			</div>
		</li>
		<li>
			<span class="labelName" translate>Mapped To</span>
			<div ngbDropdown display="dynamic" class="">
				<div class="d-flex gap-8">
					<span
						class="badgeView cursor-pointer"
						[ngClass]="bannerData.data?.mapped_count?'badge-dark-blue':'badge-paused'"
						ngbDropdownToggle
						translate
						>{{bannerData.data?.mapped_count | numberSuffix : 2}}</span
					>
					<span class="text-capitalize common-text"
						>{{bannerData.data?.mapped_count === 1 ? scorecardInfo.type :
						scorecardInfo.type === 'category' ? 'Categories' :
						scorecardInfo.type + 's' }}</span
					>
				</div>
				<div
					ngbDropdownMenu
					aria-labelledby="dropdownBasic1"
					class="commonDropdown harmonisation-mapping-list"
				>
					<div
						class="d-flex justify-content-between align-items-center header mb-1"
					>
						<span class="text-capitalize header-text"
							>Raw {{bannerData.data?.mapped_count === 1 ? scorecardInfo.type :
							scorecardInfo.type === 'category' ? 'Categories' :
							scorecardInfo.type + 's' }}</span
						>
					</div>
					<section class="list-container">
						<div
							class="map-details d-flex justify-content-between align-items-center"
							*ngFor="let mapped of bannerData.data?.mapped_details"
						>
							<span>{{mapped.name}}</span>
						</div>
					</section>
				</div>
			</div>
		</li>
		<li>
			<span class="labelName" translate>Price Range</span>
			<div class="d-flex gap-2">
				<span class="common-text" *ngIf="bannerData.data?.min_price;else noData"
					>{{bannerData.data?.currency?.symbol}}{{bannerData.data?.min_price |
					numberSuffix: 2}}</span
				>
				<span>-</span>
				<span class="common-text" *ngIf="bannerData.data?.max_price;else noData"
					>{{bannerData.data?.currency?.symbol}}{{bannerData.data?.max_price |
					numberSuffix: 2}}</span
				>
			</div>
		</li>
		<li>
			<span class="labelName" translate>Average Price</span>
			<span class="common-text" *ngIf="bannerData.data?.avg_price;else noData"
				>{{bannerData.data?.currency?.symbol}}{{bannerData.data?.avg_price |
				numberSuffix: 2}}</span
			>
		</li>
		<li>
			<span class="labelName" translate>Average Discount</span>
			<span
				class="voucher-badge me-0 fit-content"
				*ngIf="bannerData.data?.avg_discount;else noData"
				>{{bannerData.data?.avg_discount | numberSuffix : 2}}% {{'OFF' |
				translate}}</span
			>
		</li>
		<li>
			<span class="labelName" translate>Average Rating</span>
			<div class="d-flex justify-content-center">
				<div
					class="ratingLabel"
					[ngClass]="commonFunctions.getStarStatus(bannerData.data?.avg_rating)"
					*ngIf="bannerData.data?.avg_rating  >= 0 && bannerData.data?.avg_rating  != null ; else noData"
				>
					<span> {{bannerData.data?.avg_rating}} </span
					><ngb-rating [max]="1" [rate]="1"> </ngb-rating>
				</div>
			</div>
		</li>
		<li>
			<span class="labelName" translate>Unit Sold</span>
			<div class="shpeeName d-flex align-items-center gap-6">
				<span
					class="labelValue"
					*ngIf="commonFunctions.isIntegerOrDefined(bannerData.data?.unit_sold);else noData"
					>{{bannerData.data.unit_sold | numberSuffix: 2 }}</span
				>
			</div>
		</li>
		<li *ngIf="bannerData.data.type==='store'">
			<span class="labelName" translate>Segment</span>
			<span
				class="badgeView mt-2 {{commonFunctions.differentBadges[bannerData.data?.segmentation_tag].class}}"
				*ngIf="bannerData.data?.segmentation_tag"
				translate
				>{{commonFunctions.differentBadges[bannerData.data?.segmentation_tag].name}}</span
			>
			<span *ngIf="!bannerData.data?.segmentation_tag">-</span>
		</li>
		<li>
			<span class="labelName" translate>Last Updated</span>
			<span class="labelValue mt-2"
				>{{bannerData.data?.created_at ?
				commonFunctions.formatSimpleDate(bannerData.data?.created_at) :
				'-'}}</span
			>
		</li>
	</ul>
</div>
<div
	class="category-info-end d-flex w-100 mt-3"
	*ngIf="router.url?.includes('scorecard/category') && bannerData.data?.ml_service_codes?.includes('pm') && !scorecardInfo.isHarmonised"
>
	<div
		class="category-info-end-content d-flex align-items-center justify-content-between w-100"
	>
		<div class="category-settings d-flex align-items-center gap-16">
			<span
				class="category-info-end-1-item-1 img-content d-flex align-items-center justify-content-center"
				><i class="icon icon-Columns"></i
			></span>
			<span class="category-info-end-1-item-1-content text-align-right-rtl"
				>{{'Show matches for this Category' | translate}}</span
			>
		</div>
		<button
			[disabled]="!permissionsForDiscover.read_access"
			class="btn btn-secondary btn-lg"
			(click)="commonFunctions.openDiscoverCategoryDetails(bannerData.data.id, true)"
			translate
		>
			See Matches
		</button>
	</div>
</div>
<div *ngIf="!bannerData.data" class="no-data-common">
	<div *ngIf="!bannerData.loading" class="no-data-found" translate>
		<div class="img"><i class="icon icon-Search"></i></div>
		<div class="no-data-text" translate>No data present</div>
	</div>
	<div *ngIf="bannerData.loading" class="loading-panel">
		<img src="/assets/images/spinner.svg" alt="" />
	</div>
</div>
<ng-template #noData> - </ng-template>
