<div class="insights-container">
    <button type="button "
        class="btn btn-outline-primary text-start ps-3 d-flex justify-content-between align-items-center button-primary"
        (click)="collapse.toggle()" [attr.aria-expanded]="!isCollapsed" aria-controls="collapseExample">
        <div>
            <i class="icon-Lightbulb-alt"></i><span class="text" translate>{{_insightInfo.title}}</span>
        </div>
        <div>
            <i class="collapse-icon me-2" [ngClass]="isCollapsed ? 'icon-Caret-down' : 'icon-Caret-up'"></i>
        </div>
    </button>
    <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed" class="insights-data p-3">
        <div id="dashboard-panel">
            <ng-container *ngIf="insightData.data.length; else noData">
                <div class="d-flex flex-column gap-16">
                    <ng-container *ngFor="let metric of insightData.data; let i = index">
                        <div class="text-container d-flex gap-16 align-items-center"
                            *ngIf="_insightInfo.type!=='tracker'">
                            <div class="circle" [ngStyle]="{'background-color':metric.color}"></div>
                            <div class="text-box" [innerHTML]="metric.text">.</div>
                        </div>
                        <div class="text-container d-flex gap-16 align-items-center"
                            *ngIf="_insightInfo.type==='tracker'">
                            <div class="text-box d-flex align-items-center justify-content-between gap-8">
                                <section class="d-flex flex-column gap-2">
                                    <span class="font-medium">{{metric.name}}</span>
                                    <div class="d-flex align-items-center gap-6" *ngIf="metric.marketplace_name">
                                        <app-marketplace-store-logo [storeName]="metric.marketplace_name">
                                        </app-marketplace-store-logo>
                                        <div class="d-inline">{{metric.marketplace_name}}</div>
                                    </div>
                                    <div>
                                        <span class="text">Product count </span>
                                        <span class="count">: {{metric.product_count}}</span>
                                    </div>
                                </section>
                                <button type="button" class="btn btn-primary btn-lg" (click)="addNewProfile(metric)">
                                    <i class="icon icon-Location-plus cursor-pointer"
                                    placement="{{commonFunctions.rtlStatus ? 'right' : 'left'}}"
                                    ngbTooltip="{{ 'Track' | translate }}" 
                                    tooltipClass="info-custom-tooltip"></i>
                                    <span>{{'Track' | translate}}</span>
                                </button>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </ng-container>
            <ng-template #noData>
                <div class="no-data-common">
                    <div *ngIf="!insightData.isLoading" class="no-data-found" translate>
                        <div class="no-data-text" translate>No data present</div>
                    </div>
                    <div *ngIf="insightData.isLoading" class="loading-panel">
                        <img src="/assets/images/spinner.svg" alt="" />
                    </div>
                </div>
            </ng-template>
        </div>
    </div>
</div>