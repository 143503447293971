import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DiscoverService } from '@ds-private-layouts/discover/discover.service'
import { Options } from '@angular-slider/ngx-slider';
import { DataTransferService } from '@ds-common-services/utility-services/data-transfer.service';
import { ActivatedRoute } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, debounceTime, finalize, takeUntil } from 'rxjs/operators';
import { UntypedFormControl } from '@angular/forms';
import { CommonFunctionsService } from '@ds-shared/common-services/utility-services/common-functions.service';

@Component({
  selector: 'left-filters',
  templateUrl: './left-filters.component.html',
  styleUrls: ['./left-filters.component.scss']
})
export class LeftFiltersComponent implements OnInit, OnChanges {
  @Input() public type: string;
  public response: any;
  public brandFilters: any;
  public brandFiltersMore = [];
  public marketplaceFilters: any;
  public marketplaceFiltersMore = [];
  public categoryFilters = [];
  public categoryFiltersMore = [];
  public keywordFilters = [];
  public keywordFiltersMore = [];
  public options: Options = {
    floor: 0,
    ceil: 0
  };
  public selectedBrandFilters = [];
  public selectedBrandFiltersInfo = [];
  public selectedCategoryFilters = [];
  public selectedCategoryFiltersInfo = [];
  public selectedMarketPlaceFilters = [];
  public selectedMarketPlaceFiltersInfo = [];
  public selectedKeywordFilters = [];
  public selectedKeywordFiltersInfo = [];
  public selectedRatings = [];
  public selectedRatingsInfo = [];
  public selectedProductType = [];
  public selectedProductTypeInfo = [];
  public productId: any;
  public priceChangeEmitter: Subject<Object> = new Subject();
  public ratingsOption = [
    { 'name': '5 stars', 'value': 5, "isChecked": false },
    { 'name': '4 stars', "value": 4, "isChecked": false },
    { 'name': '3 stars', "value": 3, "isChecked": false },
    { 'name': '2 stars', "value": 2, "isChecked": false },
    { 'name': '1 star', "value": 1, "isChecked": false }
  ]
  public productType = [
    { 'name': 'Category Matches', "value": 'category', "isChecked": false },
    { 'name': 'Direct Matches', "value": 'direct', "isChecked": false },
    { 'name': 'Competitor Matches', "value": 'cp', "isChecked": false },
    { 'name': 'OARP (Reseller) Matches', "value": 'rs', "isChecked": false },
    { 'name': 'Greyseller Matches', "value": 'gs', "isChecked": false },
    { 'name': 'Keyword Matches', "value": 'keyword', "isChecked": false },
    { 'name': 'Brand Matches', "value": 'brand', "isChecked": false }
  ]
  public filterObj = {}
  public filterObjInfo = {}
  public leftFilterHeight: number = 0;
  public brandFiltersSubscription:Subscription;
  public marketplaceFiltersSubscription:Subscription;
  public categoryFiltersSubscription:Subscription;
  public keywordFiltersSubscription:Subscription;
  public routeSubscription: Subscription;
  public priceChangeMinControl: UntypedFormControl = new UntypedFormControl("");
  public priceChangeMaxControl: UntypedFormControl = new UntypedFormControl("");
  @Input() removeItem;
  @Output() changedfilter = new EventEmitter();
  @ViewChild('leftFilterHeight')
  elementView: ElementRef;

  private $destroy: Subject<boolean> = new Subject();

  public rangeFilterOptions = [
    { name: "Price", key: "price", units: this.commonFunctions.currencySelected?.attributes?.symbol },
    { name: "Discount", key: "discount", units: "%" },
    { name: "Likes", key: "likes", units: "" },
    { name: "Rating Count", key: "ratings_count", units: "" }
  ];
  public rangeFiltersSelected = {
    selectedOption: this.rangeFilterOptions[0],
    data: null,
    rangeFilterValue: {
      "price": {
        min_value: 0,
        max_value: 0
      },
      "discount": {
        min_value: 0,
        max_value: 0
      },
      "likes": {
        min_value: 0,
        max_value: 0
      },
      "ratings_count": {
        min_value: 0,
        max_value: 0
      }
    },
    model_min_value: 0,
    model_max_value: 0
  };
  public productAvailability = [
    { 'name': 'All Products', 'value': 'all' },
    { 'name': 'Available to Purchase', 'value': 'atp' },
    { 'name': 'Out of Stock', "value": 'oos' }
  ];
  public availabilitySelected = {...this.productAvailability[0]};

  constructor(private discoverService: DiscoverService, private dataTransferService: DataTransferService, public route: ActivatedRoute, public commonFunctions: CommonFunctionsService) { }

  ngOnInit(): void {
    this.routeSubscription=this.route.params.subscribe((params:any) => {
      this.productId=params?.id;
      this.getFilters();
    });
    this.onPriceChangeEmitter();
  }

  onPriceChangeEmitter() {
    this.priceChangeEmitter.pipe(debounceTime(500), distinctUntilChanged()).subscribe((data: any) => {
      const { type, changeContext } = data;
      this.rangeFiltersSelected.rangeFilterValue[this.rangeFiltersSelected.selectedOption.key].min_value = this.rangeFiltersSelected.model_min_value;
      this.rangeFiltersSelected.rangeFilterValue[this.rangeFiltersSelected.selectedOption.key].max_value = this.rangeFiltersSelected.model_max_value;
      this.setPriceFilter();
      this.emitFilters();
    })
  }

  getFilterHeight() {
    this.leftFilterHeight = this.elementView.nativeElement.offsetHeight;
    this.dataTransferService.sendLeftFilterHeight(this.leftFilterHeight/16);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.removeItem = changes.removeItem.currentValue;
    if (this.removeItem) {
      if (this.removeItem['type'] == 'brand') {
        this.onSelectBrand(this.removeItem['data']['index'], this.removeItem['data']['isMore'])
      }
      else if (this.removeItem['type'] == 'marketplace') {
        this.onSelectMarketPlace(this.removeItem['data']['index'], this.removeItem['data']['isMore'])
      }
      else if (this.removeItem['type'] == 'category') {
        this.onSelectCategory(this.removeItem['data']['index'], this.removeItem['data']['isMore'])
      }
      else if (this.removeItem['type'] == 'keyword') {
        this.onSelectKeyword(this.removeItem['data']['index'], this.removeItem['data']['isMore'])
      }
      else if (this.removeItem['type'] == 'rating') {
        this.onRatingSelect(this.removeItem['data']['name'], this.removeItem['data']['index'])
      }
      else if (Object.keys(this.rangeFiltersSelected.rangeFilterValue).includes(this.removeItem['type'])) {
        this.getRangeDefaultValue(this.removeItem['type']);
        this.emitFilters();
      }
      else if (this.removeItem['type'] == 'availability') {
        this.availabilitySelected = {...this.productAvailability[0]};
        this.emitFilters();
      }
      else if (this.removeItem['type'] == 'productType') {
        this.onSelectProductType(this.removeItem['data']['name'], this.removeItem['data']['index'])
      }
    }
  }

  getFilters() {
    this.getBrandFilters();
    this.getMarketplaceFilters();
    this.getCategoryFilters();
    this.getRangeFilters();
  }

  getBrandFilters() {
    this.brandFiltersSubscription=this.discoverService.getBrandFilters(this.productId, this.commonFunctions.currencySelected?.id).subscribe((res:any) => {
      if (res) {
        this.brandFilters = res['data']
        if (this.brandFilters.length > 4) {
          this.brandFiltersMore = this.brandFilters.splice(5);
        }
        else {
          this.brandFiltersMore = [];
        }
      }
    }, (err: any) => {
      this.brandFilters = [];
      this.brandFiltersMore = [];
    });
  }

  getMarketplaceFilters() {
    this.marketplaceFiltersSubscription=this.discoverService.getMarketplaceFilters(this.productId, this.commonFunctions.currencySelected?.id).subscribe((res:any) => {
      if (res) {
        this.marketplaceFilters = res['data'];
        if (this.marketplaceFilters.length > 4) {
          this.marketplaceFiltersMore = this.marketplaceFilters.splice(5);
        }
        else {
          this.marketplaceFiltersMore = [];
        }
      }
    }, (err: any) => {
      this.marketplaceFilters = [];
      this.marketplaceFiltersMore = [];
    });
  }

  getCategoryFilters() {
    this.categoryFiltersSubscription=this.discoverService.getCategoryFilters(this.productId, this.commonFunctions.currencySelected?.id).subscribe((res:any) => {
      if (res) {
        this.categoryFilters = res['data'];
        if (this.categoryFilters.length > 4) {
          this.categoryFiltersMore = this.categoryFilters.splice(5);
        }
        else {
          this.categoryFiltersMore = [];
        }
      }
    }, (err: any) => {
      this.categoryFilters = [];
      this.categoryFiltersMore = [];
    });
  }

  getKeywordsFilters() {
    this.keywordFiltersSubscription=this.discoverService.getKeywordFilters(this.productId).subscribe((res:any) => {
      if (res) {
        this.keywordFilters = res['data'];
        if (this.keywordFilters.length > 4) {
          this.keywordFiltersMore = this.keywordFilters.splice(5);
        }
        else {
          this.keywordFiltersMore = [];
        }
      }
    }, (err: any) => {
      this.keywordFilters = [];
      this.keywordFiltersMore = [];
    });
  }

  getRangeFilters() {
    this.rangeFiltersSelected.data = null;
    this.discoverService.getRangeFilters(this.productId, this.commonFunctions.currencySelected?.id)
      .pipe(takeUntil(this.$destroy))
      .subscribe((res) => {
      if (res) {
        this.rangeFiltersSelected.data = res["data"];
        this.rangeFilterOptions.forEach((item) => {
          this.getRangeDefaultValue(item.key);
        });
        this.setRangeSelectorOption();
      }
    });
  }
  getRangeDefaultValue(type:string) {
    this.rangeFiltersSelected.rangeFilterValue[type] = { ...this.rangeFiltersSelected.data[type] };
  }
  setPriceFilter() {
    this.rangeFiltersSelected.rangeFilterValue[this.rangeFiltersSelected.selectedOption.key].min_value = this.rangeFiltersSelected.rangeFilterValue[this.rangeFiltersSelected.selectedOption.key].min_value <= this.rangeFiltersSelected.data[this.rangeFiltersSelected.selectedOption.key].max_value ? this.rangeFiltersSelected.rangeFilterValue[this.rangeFiltersSelected.selectedOption.key].min_value < this.rangeFiltersSelected.data[this.rangeFiltersSelected.selectedOption.key].min_value ? this.rangeFiltersSelected.data[this.rangeFiltersSelected.selectedOption.key].min_value : this.rangeFiltersSelected.rangeFilterValue[this.rangeFiltersSelected.selectedOption.key].min_value : this.rangeFiltersSelected.data[this.rangeFiltersSelected.selectedOption.key].max_value;
    this.rangeFiltersSelected.rangeFilterValue[this.rangeFiltersSelected.selectedOption.key].max_value = this.rangeFiltersSelected.rangeFilterValue[this.rangeFiltersSelected.selectedOption.key].max_value <= this.rangeFiltersSelected.data[this.rangeFiltersSelected.selectedOption.key].max_value ? this.rangeFiltersSelected.rangeFilterValue[this.rangeFiltersSelected.selectedOption.key].max_value < this.rangeFiltersSelected.data[this.rangeFiltersSelected.selectedOption.key].min_value ? this.rangeFiltersSelected.data[this.rangeFiltersSelected.selectedOption.key].min_value : this.rangeFiltersSelected.rangeFilterValue[this.rangeFiltersSelected.selectedOption.key].max_value : this.rangeFiltersSelected.data[this.rangeFiltersSelected.selectedOption.key].max_value;
    if (this.rangeFiltersSelected.rangeFilterValue[this.rangeFiltersSelected.selectedOption.key].min_value > this.rangeFiltersSelected.rangeFilterValue[this.rangeFiltersSelected.selectedOption.key].max_value) {
      const temp = this.rangeFiltersSelected.rangeFilterValue[this.rangeFiltersSelected.selectedOption.key].min_value;
      this.rangeFiltersSelected.rangeFilterValue[this.rangeFiltersSelected.selectedOption.key].min_value = this.rangeFiltersSelected.rangeFilterValue[this.rangeFiltersSelected.selectedOption.key].max_value;
      this.rangeFiltersSelected.rangeFilterValue[this.rangeFiltersSelected.selectedOption.key].max_value = temp;
    }
    this.rangeFiltersSelected.model_min_value = this.rangeFiltersSelected.rangeFilterValue[this.rangeFiltersSelected.selectedOption.key].min_value;
    this.rangeFiltersSelected.model_max_value = this.rangeFiltersSelected.rangeFilterValue[this.rangeFiltersSelected.selectedOption.key].max_value;
  }

  onSelectBrand(i, isMore) {
    if (isMore) {
      if ('isChecked' in this.brandFiltersMore[i]) {
        this.brandFiltersMore[i].isChecked = !this.brandFiltersMore[i].isChecked;
      }
      else {
        this.brandFiltersMore[i]['isChecked'] = true;
      }
      if (this.brandFiltersMore[i]['isChecked'] == true) {
        this.selectedBrandFilters.push(this.brandFiltersMore[i]['id']);
        this.selectedBrandFiltersInfo.push({ 'id': this.brandFiltersMore[i]['id'], 'name': this.brandFiltersMore[i]['name'], 'index': i, 'isMore': true });
      }
      else {
        if (this.selectedBrandFilters.length > 0) {
          let j = this.selectedBrandFilters.findIndex(f => f === this.brandFiltersMore[i]['id'])
          if (j !== -1) {
            this.selectedBrandFilters.splice(j, 1);
            this.selectedBrandFiltersInfo.splice(j, 1);
            this.brandFiltersMore[i]['isChecked'] = false;
          }
        }
      }
    }
    else {
      if ('isChecked' in this.brandFilters[i]) {
        this.brandFilters[i].isChecked = !this.brandFilters[i].isChecked;
      }
      else {
        this.brandFilters[i]['isChecked'] = true;
      }
      if (this.brandFilters[i]['isChecked'] == true) {
        this.selectedBrandFilters.push(this.brandFilters[i]['id']);
        this.selectedBrandFiltersInfo.push({ 'id': this.brandFilters[i]['id'], 'name': this.brandFilters[i]['name'], 'index': i, 'isMore': false });
      }
      else {
        if (this.selectedBrandFilters.length > 0) {
          let j = this.selectedBrandFilters.findIndex(f => f === this.brandFilters[i]['id'])
          if (j !== -1) {
            this.selectedBrandFilters.splice(j, 1);
            this.selectedBrandFiltersInfo.splice(j, 1);
            this.brandFilters[i]['isChecked'] = false
          }
        }
      }
    }
    this.emitFilters();
  }

  onSelectCategory(i, isMore) {
    if (isMore) {
      if ('isChecked' in this.categoryFiltersMore[i]) {
        this.categoryFiltersMore[i].isChecked = !this.categoryFiltersMore[i].isChecked;
      }
      else {
        this.categoryFiltersMore[i]['isChecked'] = true;
      }
      if (this.categoryFiltersMore[i]['isChecked'] == true) {
        this.selectedCategoryFilters.push(this.categoryFiltersMore[i]['category']);
        this.selectedCategoryFiltersInfo.push({ 'id': this.categoryFiltersMore[i]['id'], 'name': this.categoryFiltersMore[i]['category'], 'index': i, 'isMore': true });
      }
      else {
        if (this.selectedCategoryFilters.length > 0) {
          let j = this.selectedCategoryFilters.findIndex(f => f === this.categoryFiltersMore[i]['category'])
          if (j !== -1) {
            this.selectedCategoryFilters.splice(j, 1);
            this.selectedCategoryFiltersInfo.splice(j, 1);
            this.categoryFiltersMore[i]['isChecked'] = false;
          }
        }
      }
    }
    else {
      if ('isChecked' in this.categoryFilters[i]) {
        this.categoryFilters[i].isChecked = !this.categoryFilters[i].isChecked;
      }
      else {
        this.categoryFilters[i]['isChecked'] = true;
      }
      if (this.categoryFilters[i]['isChecked'] == true) {
        this.selectedCategoryFilters.push(this.categoryFilters[i]['category']);
        this.selectedCategoryFiltersInfo.push({ 'id': this.categoryFilters[i]['id'], 'name': this.categoryFilters[i]['category'], 'index': i, 'isMore': false });
      }
      else {
        if (this.selectedCategoryFilters.length > 0) {
          let j = this.selectedCategoryFilters.findIndex(f => f === this.categoryFilters[i]['category'])
          if (j !== -1) {
            this.selectedCategoryFilters.splice(j, 1);
            this.selectedCategoryFiltersInfo.splice(j, 1);
            this.categoryFilters[i]['isChecked'] = false;
          }
        }
      }
    }
    this.emitFilters();
  }

  onSelectMarketPlace(i, isMore) {
    if (isMore) {
      if ('isChecked' in this.marketplaceFiltersMore[i]) {
        this.marketplaceFiltersMore[i].isChecked = !this.marketplaceFiltersMore[i].isChecked;
      }
      else {
        this.marketplaceFiltersMore[i]['isChecked'] = true;
      }
      if (this.marketplaceFiltersMore[i]['isChecked'] == true) {
        this.selectedMarketPlaceFilters.push(this.marketplaceFiltersMore[i]['id']);
        this.selectedMarketPlaceFiltersInfo.push({ 'id': this.marketplaceFiltersMore[i]['id'], 'name': this.marketplaceFiltersMore[i]['marketplace'], 'index': i, 'isMore': true });
      }
      else {
        if (this.selectedMarketPlaceFilters.length > 0) {
          let j = this.selectedMarketPlaceFilters.findIndex(f => f === this.marketplaceFiltersMore[i]['id'])
          if (j !== -1) {
            this.selectedMarketPlaceFilters.splice(j, 1);
            this.selectedMarketPlaceFiltersInfo.splice(j, 1);
            this.marketplaceFiltersMore[i]['isChecked'] = false;
          }
        }
      }
    }
    else {
      if ('isChecked' in this.marketplaceFilters[i]) {
        this.marketplaceFilters[i].isChecked = !this.marketplaceFilters[i].isChecked;
      }
      else {
        this.marketplaceFilters[i]['isChecked'] = true;
      }
      if (this.marketplaceFilters[i]['isChecked'] == true) {
        this.selectedMarketPlaceFilters.push(this.marketplaceFilters[i]['id']);
        this.selectedMarketPlaceFiltersInfo.push({ 'id': this.marketplaceFilters[i]['id'], 'name': this.marketplaceFilters[i]['marketplace'], 'index': i, 'isMore': false });
      }
      else {
        if (this.selectedMarketPlaceFilters.length > 0) {
          let j = this.selectedMarketPlaceFilters.findIndex(f => f === this.marketplaceFilters[i]['id'])
          if (j !== -1) {
            this.selectedMarketPlaceFilters.splice(j, 1);
            this.selectedMarketPlaceFiltersInfo.splice(j, 1);
            this.marketplaceFilters[i]['isChecked'] = false;
          }
        }
      }
    }
    this.emitFilters();
  }

  onSelectKeyword(i, isMore) {
    if (isMore) {
      if ('isChecked' in this.keywordFiltersMore[i]) {
        this.keywordFiltersMore[i].isChecked = !this.keywordFiltersMore[i].isChecked;
      }
      else {
        this.keywordFiltersMore[i]['isChecked'] = true;
      }
      if (this.keywordFiltersMore[i]['isChecked'] == true) {
        this.selectedKeywordFilters.push(this.keywordFiltersMore[i]['keyword']);
        this.selectedKeywordFiltersInfo.push({ 'id': this.keywordFiltersMore[i]['id'], 'name': this.keywordFiltersMore[i]['keyword'], 'index': i, 'isMore': true });
      }
      else {
        if (this.selectedKeywordFilters.length > 0) {
          let j = this.selectedKeywordFilters.findIndex(f => f === this.keywordFiltersMore[i]['keyword'])
          if (j !== -1) {
            this.selectedKeywordFilters.splice(j, 1);
            this.selectedKeywordFiltersInfo.splice(j, 1);
            this.keywordFiltersMore[i]['isChecked'] = false
          }
        }
      }
    }
    else {
      if ('isChecked' in this.keywordFilters[i]) {
        this.keywordFilters[i].isChecked = !this.keywordFilters[i].isChecked;
      }
      else {
        this.keywordFilters[i]['isChecked'] = true;
      }
      if (this.keywordFilters[i]['isChecked'] == true) {
        this.selectedKeywordFilters.push(this.keywordFilters[i]['keyword']);
        this.selectedKeywordFiltersInfo.push({ 'id': this.keywordFilters[i]['id'], 'name': this.keywordFilters[i]['keyword'], 'index': i, 'isMore': false });
      }
      else {
        if (this.selectedKeywordFilters.length > 0) {
          let j = this.selectedKeywordFilters.findIndex(f => f === this.keywordFilters[i]['keyword'])
          if (j !== -1) {
            this.selectedKeywordFilters.splice(j, 1);
            this.selectedKeywordFiltersInfo.splice(j, 1);
            this.keywordFilters[i]['isChecked'] = false
          }
        }
      }
    }
    this.emitFilters();
  }

  onPriceChange(type, changeContext?: any): void {
    if (this.rangeFiltersSelected.data) {
      if (type === 'range') {
        this.rangeFiltersSelected.rangeFilterValue[this.rangeFiltersSelected.selectedOption.key].min_value = changeContext?.value ? changeContext?.value : 0;
        this.rangeFiltersSelected.rangeFilterValue[this.rangeFiltersSelected.selectedOption.key].max_value = changeContext?.highValue ? changeContext?.highValue : 0;
        this.emitFilters();
      }
      else this.priceChangeEmitter.next({ type, changeContext });
    }
  }

  onRatingSelect(val, i) {

    this.ratingsOption[i].isChecked = !this.ratingsOption[i].isChecked;
    if (this.ratingsOption[i]['isChecked'] == true) {
      this.selectedRatings.push(this.ratingsOption[i]['value']);
      this.selectedRatingsInfo.push({ 'name': this.ratingsOption[i]['name'], 'index': i });

    }
    else {
      if (this.selectedRatings.length > 0) {
        let j = this.selectedRatings.findIndex(f => f === this.ratingsOption[i]['value'])
        if (j !== -1) {
          this.selectedRatings.splice(j, 1);
          this.selectedRatingsInfo.splice(j, 1);
          this.ratingsOption[i]['isChecked'] = false
        }
      }
    }

    this.emitFilters();
  }

  onSelectProductType(val,i) {
    this.productType[i].isChecked = !this.productType[i].isChecked;
    if (this.productType[i]['isChecked'] == true) {
      this.selectedProductType.push(this.productType[i]['value']);
      this.selectedProductTypeInfo.push({ 'name': this.productType[i]['name'], 'index': i });
    }
    else {
      if (this.selectedProductType.length > 0) {
        let j = this.selectedProductType.findIndex(f => f === this.productType[i]['value'])
        if (j !== -1) {
          this.selectedProductType.splice(j, 1);
          this.selectedProductTypeInfo.splice(j, 1);
          this.productType[i]['isChecked'] = false
        }
      }
    }
    this.emitFilters();
  }

  onClearAllFilters() {
    this.resetFilterArray();
    this.resetIsChecked();
    this.availabilitySelected = {...this.productAvailability[0]};
    this.rangeFilterOptions.forEach((item) => {
      this.getRangeDefaultValue(item.key);
    });
    this.changedfilter.emit('clearAll');
  }

  resetFilterArray() {
    this.selectedBrandFilters = [];
    this.selectedMarketPlaceFilters = [];
    this.selectedCategoryFilters = [];
    this.selectedKeywordFilters = [];
    this.selectedRatings = [];
    this.selectedProductType = [];
    // Info array
    this.selectedBrandFiltersInfo = [];
    this.selectedMarketPlaceFiltersInfo = [];
    this.selectedCategoryFiltersInfo = [];
    this.selectedKeywordFiltersInfo = [];
    this.selectedRatingsInfo = [];
    this.selectedProductTypeInfo = [];
  }
  resetIsChecked() {
    this.resetBrandCheckbox();
    this.resetMarketplaceCheckbox();
    this.resetCategoryCheckbox();
    this.resetKeywordCheckbox();
    this.resetRatingsCheckbox();
    this.resetProductTypeCheckbox();
  }

  resetBrandCheckbox() {
    if (this.brandFilters.length > 0) {
      this.brandFilters.forEach((val, key) => {
        if ('isChecked' in val) {
          val['isChecked'] = false;
        }
      });
      if (this.brandFiltersMore.length > 0) {
        this.brandFiltersMore.forEach((val, key) => {
          if ('isChecked' in val) {
            val['isChecked'] = false;
          }
        });
      }
    }
  }

  resetMarketplaceCheckbox() {
    if (this.marketplaceFilters.length > 0) {
      this.marketplaceFilters.forEach((val, key) => {
        if ('isChecked' in val) {
          val['isChecked'] = false;
        }
      });
      if (this.marketplaceFiltersMore.length > 0) {
        this.marketplaceFiltersMore.forEach((val, key) => {
          if ('isChecked' in val) {
            val['isChecked'] = false;
          }
        });
      }
    }
  }

  resetCategoryCheckbox() {
    if (this.categoryFilters.length > 0) {
      this.categoryFilters.forEach((val, key) => {
        if ('isChecked' in val) {
          val['isChecked'] = false;
        }
      });
      if (this.categoryFiltersMore.length > 0) {
        this.categoryFiltersMore.forEach((val, key) => {
          if ('isChecked' in val) {
            val['isChecked'] = false;
          }
        });
      }
    }
  }

  resetKeywordCheckbox() {
    if (this.keywordFilters.length > 0) {
      this.keywordFilters.forEach((val, key) => {
        if ('isChecked' in val) {
          val.isChecked = false;
        }
      });
      if (this.keywordFiltersMore.length > 0) {
        this.keywordFiltersMore.forEach((val, key) => {
          if ('isChecked' in val) {
            val['isChecked'] = false;
          }
        });
      }
    }
  }

  resetRatingsCheckbox() {
    if (this.ratingsOption.length > 0) {
      this.ratingsOption.forEach((val, key) => {
        val.isChecked = false;
      });
    }
  }

  resetProductTypeCheckbox() {
      this.productType.forEach((val, key) => {
        val.isChecked = false;
      });
  }

  emitFilters() {
    this.filterObj = {
      'brandlist': this.selectedBrandFilters,
      'marketplacelist': this.selectedMarketPlaceFilters,
      'categorylist': this.selectedCategoryFilters,
      'keywordlist': this.selectedKeywordFilters,
      'ratinglist': this.selectedRatings,
      'badge': this.selectedProductType
    }
    this.filterObjInfo = {
      'brandlist': this.selectedBrandFiltersInfo,
      'marketplacelist': this.selectedMarketPlaceFiltersInfo,
      'categorylist': this.selectedCategoryFiltersInfo,
      'keywordlist': this.selectedKeywordFiltersInfo,
      'ratinglist': this.selectedRatingsInfo,
      'productTypeList': this.selectedProductTypeInfo
    }
    this.createRangeObject();
    if (this.availabilitySelected.value !== "all") {
      this.filterObj = { ...this.filterObj, 'availability': this.availabilitySelected.value };
      this.filterObjInfo = { ...this.filterObjInfo, 'availability': this.availabilitySelected.value };
    }
    if (!this.selectedRatings) {
      delete this.filterObj['ratinglist'];
    }
    if (!this.selectedProductType) {
      delete this.filterObj['badge'];
    }
    let qsInfo = [{
      'qs': this.filterObj
    },
    {
      'qsInfo': this.filterObjInfo
    },
    ]
    this.changedfilter.emit(qsInfo);

  }
  createRangeObject() {
    this.rangeFilterOptions.forEach((item, index) => {
      if (this.rangeFiltersSelected.rangeFilterValue[item.key].max_value) {
        if (this.rangeFiltersSelected.rangeFilterValue[item.key].min_value !== this.rangeFiltersSelected.data[item.key].min_value || this.rangeFiltersSelected.rangeFilterValue[item.key].max_value !== this.rangeFiltersSelected.data[item.key].max_value) {
          this.filterObj = {
            ...this.filterObj,
            [`${item.key}_min`]: this.rangeFiltersSelected.rangeFilterValue[item.key].min_value,
            [`${item.key}_max`]: this.rangeFiltersSelected.rangeFilterValue[item.key].max_value
          }
          this.filterObjInfo = {
            ...this.filterObjInfo,
            [`${item.key}_min`]: this.rangeFiltersSelected.rangeFilterValue[item.key].min_value,
            [`${item.key}_max`]: this.rangeFiltersSelected.rangeFilterValue[item.key].max_value
          }
        }
      }
    });
  }
  onRangeFilterSelect(option) {
    this.rangeFiltersSelected.selectedOption = option;
    this.setRangeSelectorOption();
  }
  setRangeSelectorOption() {
    this.options = {
      floor: this.rangeFiltersSelected.data[this.rangeFiltersSelected.selectedOption.key].min_value ? this.rangeFiltersSelected.data[this.rangeFiltersSelected.selectedOption.key].min_value : 0,
      ceil: this.rangeFiltersSelected.data[this.rangeFiltersSelected.selectedOption.key].max_value ? this.rangeFiltersSelected.data[this.rangeFiltersSelected.selectedOption.key].max_value : 0
    };
    this.rangeFiltersSelected.model_min_value = this.rangeFiltersSelected.rangeFilterValue[this.rangeFiltersSelected.selectedOption.key].min_value;
    this.rangeFiltersSelected.model_max_value = this.rangeFiltersSelected.rangeFilterValue[this.rangeFiltersSelected.selectedOption.key].max_value;
  }
  ngOnDestroy() {
    if (this.brandFiltersSubscription) this.brandFiltersSubscription.unsubscribe();
    if(this.marketplaceFiltersSubscription)this.marketplaceFiltersSubscription.unsubscribe();
    if(this.categoryFiltersSubscription)this.categoryFiltersSubscription.unsubscribe();
    if(this.keywordFiltersSubscription)this.keywordFiltersSubscription.unsubscribe();
    if(this.routeSubscription)this.routeSubscription.unsubscribe();
  }
}

