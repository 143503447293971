import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { CommonFunctionsService } from "@ds-shared/common-services/utility-services/common-functions.service";
import {
	ColumnModel,
	CommonSearchModel,
	MarketplaceConfiguration,
	ModuleType
} from "@ds-shared/models/common.model";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { DocumentCollection } from "ngx-jsonapi";
import { Subject, takeUntil, Subscription } from "rxjs";
import {
	PopUpGrid,
	PopUpListService,
	PopUpListExtraService,
	GlobalService,
	PopUpGridCompetitor,
	PopUpListCompetitorService,
	PopUpListCompetitorExtraService
} from "@ds-shared/common-services/http-services/global.service";
import { COL, PAYLOAD } from "@ds-shared/enums/common.enum";
import { MixpanelService } from "@ds-common-services/utility-services/mixpanel.service";
import { DataTransferService } from "@ds-shared/common-services/utility-services/data-transfer.service";

@Component({
	selector: "app-compare-view-popup",
	templateUrl: "./compare-view-popup.component.html",
	styleUrls: ["./compare-view-popup.component.scss"]
})
export class CompareViewPopupComponent implements OnInit {
	@ViewChild("popUp") popUp: ElementRef;
	public popUpInfo;
	public customerGridDataList: DocumentCollection<PopUpGrid>;
	public competitorGridDataList: DocumentCollection<PopUpGridCompetitor>;
	public colsCustomer: ColumnModel[];
	public colsCompetitor: ColumnModel[];
	public NoData: boolean = false;
	public NoDataCompetitor: boolean = false;
	public sort: string[] = [];
	public sortCompetitor: string[] = [];
	public loadingData: boolean = false;
	public loadingDataCompetitor: boolean = false;
	public page: number = 1;
	public pageCompetitor: number = 1;
	public pageSize: number;
	public pageSizeCompetitor: number;
	public itemPerPage: number = 5;
	public itemPerPageCompetitor: number = 5;
	public moduleType: ModuleType = {
		name: "pop-up-list",
		style: {
			tableStyle: {
				height: "21.5rem"
			},
			tableWrapper: {
				border: "none",
				"border-radius": "0rem"
			},
			noDataStyle: {
				height: "18.375rem"
			},
			paginationStyle: {
				border: "none",
				padding: "1rem 0rem 0rem 0rem"
			}
		},
		pagination: {
			show: true
		}
	};
	private $destroy: Subject<boolean> = new Subject();
	public clearLastCall: Subscription;
	public clearLastCallCompetitor: Subscription;
	
	constructor(
		private modalService: NgbModal,
		public commonFunctions: CommonFunctionsService,
		private popUpListService: PopUpListService,
		public globalService: GlobalService,
		public popUpListExtraService: PopUpListExtraService,
		public popUpListCompetitorService: PopUpListCompetitorService,
		public popUpListCompetitorExtraService: PopUpListCompetitorExtraService
	) {}

	ngOnInit(): void {}

	private initDataCustomer(keepCurrentPage?: boolean): void {
		if (keepCurrentPage) this.getCustomerGridData(this.page);
		else this.getCustomerGridData(1);
	}

	private initDataCompetitor(keepCurrentPage?: boolean): void {
		if (keepCurrentPage) this.getCompetitorGridData(this.pageCompetitor);
		else this.getCompetitorGridData(1);
	}

	openModal(data) {
		this.popUpInfo = data;
		this.modalService.open(this.popUp, {
			centered: true,
			windowClass: "popUpModal",
			keyboard: false,
			backdrop: "static"
		});
		this.resetData();
		this.setExtraServicesCustomer();
		this.setExtraServicesCompetitor();
		this.colsCustomer = this.popUpInfo.data.gridInfo.cols;
		this.colsCompetitor = this.popUpInfo.data.gridInfo.cols;
		this.initDataCustomer();
		this.initDataCompetitor();
	}

	private setExtraServicesCustomer(): void {
		this.globalService.register();
		this.popUpListService.setType(this.popUpInfo.data.gridInfo.path);
		this.popUpListService.register();
		this.popUpListExtraService.setType(this.popUpInfo.data.gridInfo.beforePath);
		this.popUpListExtraService.register();
	}

	private setExtraServicesCompetitor(): void {
		this.popUpListCompetitorService.setType(this.popUpInfo.data.gridInfo.path);
		this.popUpListCompetitorService.register();
		this.popUpListCompetitorExtraService.setType(
			this.popUpInfo.data.gridInfo.beforePathCompetitor
		);
		this.popUpListCompetitorExtraService.register();
	}

	public onPageChange(eve: number): void {
		this.page = eve;
		this.getCustomerGridData(this.page);
	}
	public onPageChangeCompetitor(eve: number): void {
		this.pageCompetitor = eve;
		this.getCompetitorGridData(this.pageCompetitor);
	}
	public onPageSizeChange(eve: number): void {
		this.pageSize = eve;
		this.itemPerPage = eve;
		this.getCustomerGridData(1);
	}
	public onPageSizeChangeCompetitor(eve: number): void {
		this.pageSizeCompetitor = eve;
		this.itemPerPageCompetitor = eve;
		this.getCompetitorGridData(1);
	}

	public sortData(data: string[]): void {
		this.sort = data;
		this.initDataCustomer(true);
	}
	public sortDataCompetitor(data: string[]): void {
		this.sortCompetitor = data;
		this.initDataCompetitor(true);
	}

	public resetData(): void {
		this.sort = [];
		this.sortCompetitor = [];
		this.page = 1;
		this.pageCompetitor = 1;
		this.itemPerPage = 5;
		this.itemPerPageCompetitor = 5;
		this.pageSize = 5;
		this.pageSizeCompetitor = 5;
	}
	private getCustomerGridData(page: number): void {
		this.customerGridDataList = new DocumentCollection();
		const param: any = this.popUpInfo.data.gridInfo.params;
		Object.keys(param).forEach((item) => {
			if (
				(Array.isArray(param[item]) && !param[item]?.length) ||
				(!Array.isArray(param[item]) && !param[item])
			)
				delete param[item];
		});
		this.clearLastCall?.unsubscribe();
		this.clearLastCall = this.popUpListService
			.all({
				page: { number: page || 1, size: this.pageSize || 5 },
				remotefilter: param,
				sort: this.sort,
				beforepath: this.popUpInfo.data.gridInfo.beforePath
			})
			.pipe(takeUntil(this.$destroy))
			.subscribe(
				(customerGridDataList) => {
					this.loadingData = true;
					if (
						customerGridDataList.source == "server" &&
						customerGridDataList.is_loading == false
					) {
						if (customerGridDataList.data.length == 0) {
							this.NoData = true;
							this.loadingData = true;
						} else {
							this.loadingData = false;
							this.NoData = false;
							this.customerGridDataList = customerGridDataList;
						}
					} else {
						this.loadingData = true;
						this.NoData = false;
					}
				},
				(error): void => {
					this.loadingData = true;
					this.NoData = true;
				}
			);
	}

	private getCompetitorGridData(page: number): void {
		this.competitorGridDataList = new DocumentCollection();
		const param: any = this.popUpInfo.data.gridInfo.competitorParams;
		Object.keys(param).forEach((item) => {
			if (
				(Array.isArray(param[item]) && !param[item]?.length) ||
				(!Array.isArray(param[item]) && !param[item])
			)
				delete param[item];
		});
		this.clearLastCallCompetitor?.unsubscribe();
		this.clearLastCallCompetitor = this.popUpListCompetitorService
			.all({
				page: { number: page || 1, size: this.pageSizeCompetitor || 5 },
				remotefilter: param,
				sort: this.sortCompetitor,
				beforepath: this.popUpInfo.data.gridInfo.beforePathCompetitor
			})
			.pipe(takeUntil(this.$destroy))
			.subscribe(
				(competitorGridDataList) => {
					this.loadingDataCompetitor = true;
					if (
						competitorGridDataList.source == "server" &&
						competitorGridDataList.is_loading == false
					) {
						if (competitorGridDataList.data.length == 0) {
							this.NoDataCompetitor = true;
							this.loadingDataCompetitor = true;
						} else {
							this.loadingDataCompetitor = false;
							this.NoDataCompetitor = false;
							this.competitorGridDataList = competitorGridDataList;
						}
					} else {
						this.loadingDataCompetitor = true;
						this.NoDataCompetitor = false;
					}
				},
				(error): void => {
					this.loadingDataCompetitor = true;
					this.NoDataCompetitor = true;
				}
			);
	}

	public ngOnDestroy(): void {
		this.$destroy.next(true);
		this.$destroy.unsubscribe();
	}
}
