/* eslint-disable */
import {
	AfterViewInit,
	Component,
	Input,
	OnInit,
	Renderer2,
	SimpleChanges
} from "@angular/core";
import * as Highcharts from "highcharts/highstock";
import { CommonFunctionsService } from "@ds-common-services/utility-services/common-functions.service";
import { DataTransferService } from "@ds-common-services/utility-services/data-transfer.service";
import { Subject, filter, takeUntil } from "rxjs";
import { Router } from "@angular/router";
import { TOOLTIPCOLOR } from "@ds-shared/enums/common.enum";
let componentContext;

@Component({
	selector: "app-bar-chart",
	templateUrl: "./bar-chart.component.html",
	styleUrls: ["./bar-chart.component.scss"]
})
export class BarChartComponent implements OnInit, AfterViewInit {
	public Highcharts: typeof Highcharts = Highcharts;
	public highchartsData: typeof Highcharts;
	public chartOptions: any;
	private chartRef;
	@Input() data: any;
	@Input() enableBottomLabel: boolean = true;
	@Input() barColor: string = "#FF9800";
	@Input() barHoverColor: string = "#EE6002";
	@Input() public scrollbarThreshold = 12;
	@Input() public pointWidth = 20;
	@Input() public caller = "gmv-bar";
	@Input() public meta = {};
	@Input() public showTooltipOutside: boolean = true;
	public profileType = {
		keyword: "K",
		category: "C",
		brand: "B",
		product: "P"
	};
	private destroy$: Subject<boolean> = new Subject();
	public storeClickedElement;
	listenerFn = () => {};
	constructor(
		private commonFunctions: CommonFunctionsService,
		private dataTransferService: DataTransferService,
		private router: Router,
		private renderer: Renderer2
	) {
		this.dataTransferService.secondaaryMenuToggleSignal$
			.pipe(
				filter((res) => res),
				takeUntil(this.destroy$)
			)
			.subscribe((res) => {
				setTimeout(() => {
					this.chartRef.reflow();
				}, 0);
			});

		this.listenerFn = this.renderer.listen("document", "click", (e: any) => {
			componentContext.storeClickedElement =
				componentContext.commonFunctions.closeCustomDropdown(
					e,
					componentContext.storeClickedElement
				);
		});
	}

	ngOnInit(): void {
		componentContext = this;
		Highcharts.setOptions({
			lang: {
				thousandsSep: ","
			}
		});
	}
	ngOnChanges(changes: SimpleChanges) {
		componentContext = this;
	}

	ngAfterViewInit() {
		setTimeout(() => {
			this.renderBarChart();
		}, 0);
	}

	onStoreNameClick(e) {
		const OuterDiv = document.querySelectorAll(".highcharts-container");
		const axis = e.xAxis[0];
		const ticks = axis.ticks;
		const points = e.series[0].points;
		const processedXData = e.series[0].processedXData;
		points.forEach(function (point, i) {
			//  only hover effect
			if (point.caller === "brand-market-share-product-collection") {
				if (ticks[processedXData[i]]) {
					const label = ticks[processedXData[i]].label.element;
					label.onmouseover = function () {
						label.childNodes.forEach((item) => {
							item.style.setProperty("cursor", "pointer");
							item.childNodes[1].style.setProperty("visibility", "visible");
							OuterDiv.forEach((div: HTMLElement) =>
								div.style.setProperty("overflow", "unset")
							);
						});
					};
					label.onmouseout = function () {
						label.childNodes.forEach((item) => {
							item.style.setProperty("color", "#727487", "important");
							item.childNodes[1].style.setProperty("visibility", "hidden");
							OuterDiv.forEach((div: HTMLElement) =>
								div.style.setProperty("overflow", "hidden")
							);
						});
					};
				}
			}
			if (point?.type !== "store") return;
			if (ticks[processedXData[i]]) {
				const label = ticks[processedXData[i]].label.element;
				label.onmouseover = function () {
					label.childNodes.forEach((item) => {
						item.style.setProperty("color", "#ff5e00", "important");
						item.style.setProperty("cursor", "pointer");
						item.childNodes[1].style.setProperty("visibility", "visible");
						OuterDiv.forEach((div: HTMLElement) =>
							div.style.setProperty("overflow", "unset")
						);
					});
				};
				label.onmouseout = function () {
					label.childNodes.forEach((item) => {
						item.style.setProperty("color", "#727487", "important");
						item.childNodes[1].style.setProperty("visibility", "hidden");
						OuterDiv.forEach((div: HTMLElement) =>
							div.style.setProperty("overflow", "hidden")
						);
					});
				};
				label.onclick = function (event) {
					if (point?.harmonised_view) {
						componentContext.storeClickedElement =
							componentContext.commonFunctions.setPositionOfCustomDropdown(
								event,
								label,
								componentContext.storeClickedElement,
								point["raw_data"],
								20
							);
					} else {
						componentContext.storeClickedElement =
							componentContext.commonFunctions.closeCustomDropdown(
								null,
								componentContext.storeClickedElement
							);
						window.open(
							componentContext.commonFunctions?.redirectToScorecardInfo(
								point.id,
								"store",
								true
							)?.params,
							"_blank"
						);
					}
				};
			}
		});
	}
	renderBarChart() {
		this.highchartsData = Highcharts;
		this.chartOptions = {
			chart: {
				type: "bar",
				marginBottom: 0,
				spacing: [0, 0, 0, 0],
				events: {
					load: function () {
						document
							.querySelectorAll(".highcharts-scrollbar-button")
							.forEach((el) => {
								el.remove();
							});
						document
							.querySelectorAll(".highcharts-scrollbar-arrow")
							.forEach((el) => {
								el.remove();
							});
					},
					render: function () {
						componentContext.onStoreNameClick(this);
					}
				}
			},
			title: {
				text: ""
			},
			subtitle: {
				text: ""
			},
			scrollbar: {
				enabled: this.data?.length > this.scrollbarThreshold,
				barBackgroundColor: "#aaaaaae5",
				barBorderRadius: 7,
				barBorderWidth: 0,
				buttonBackgroundColor: "#B3B3B3",
				buttonBorderWidth: 0,
				buttonBorderRadius: 7,
				trackBackgroundColor: "none",
				trackBorderWidth: 0,
				trackBorderRadius: 8,
				trackBorderColor: "#CCC",
				rifleColor: "transparent"
			},
			navigator: {
				enabled: false,
				height: 0
			},
			xAxis: {
				type: "category",
				labels: {
					useHTML: true, //Set to true
					formatter: function () {
						if (componentContext.data[this.pos]?.caller === "gmv-bar") {
							if (
								this.axis.series[0]?.userOptions?.data[this.pos].type ===
								"store"
							) {
								if (
									this.axis.series[0]?.userOptions?.data[this.pos]
										.profile_type === "store"
								) {
									return `<div style="display:flex;font-size:0.688rem;font-family:Poppins Regular;font-weight:400;color:#727487;gap:0.3125rem">
								<span style=" visibility: hidden;border: 0.0625rem solid ${
									TOOLTIPCOLOR["BG"]
								}; padding: 0.375rem ;left: -0.25rem;bottom: 100%;position: absolute;background-color:${
										TOOLTIPCOLOR["BG"]
									};color:${
										TOOLTIPCOLOR["VALUE"]
									};border-radius:0.375rem">${componentContext.commonFunctions.getSpecificTranslation(
										componentContext.data[this.pos]?.key
									)}</span>
								<div style="width:5.25rem;overflow:hidden;white-space:nowrap;text-overflow:ellipsis;">${componentContext.commonFunctions.getSpecificTranslation(
									this.value.replace(
										componentContext.data[this.pos]?.profile_id,
										""
									)
								)}</div>
								</div>`;
								}
								return `<div style="display:flex;font-size:0.688rem;font-family:Poppins Regular;font-weight:400;color:#727487;gap:0.3125rem">
								<span style=" visibility: hidden;border: 0.0625rem solid ${
									TOOLTIPCOLOR["BG"]
								}; padding: 0.375rem ;left: -0.25rem;bottom: 100%;position: absolute;background-color:${
									TOOLTIPCOLOR["BG"]
								};color:${
									TOOLTIPCOLOR["VALUE"]
								};border-radius:0.375rem">${componentContext.commonFunctions.getSpecificTranslation(
									componentContext.data[this.pos]?.key
								)}</span>
								<div style="width:5.25rem;overflow:hidden;white-space:nowrap;text-overflow:ellipsis;">${componentContext.commonFunctions.getSpecificTranslation(
									this.value.replace(
										componentContext.data[this.pos]?.profile_id,
										""
									)
								)}</div>
								</div>`;
							} else {
								return `<span style="font-size:0.688rem;font-family:Poppins Regular;font-weight:400;color:#727487;gap:0.3125rem">
								<span style="width:5.25rem;overflow:hidden;white-space:nowrap;text-overflow:ellipsis;">${componentContext.commonFunctions.getSpecificTranslation(
									this.value.replace("undefined", "")
								)}</span>
								</span>`;
							}
						} else if (
							componentContext.caller == "brand-market-share-product-collection"
						) {
							return `<div style="display:flex;font-size:0.688rem;font-family:Poppins Regular;font-weight:400;color:#727487;gap:0.3125rem">
							<span style=" visibility: hidden;border: 0.0625rem solid ${
								TOOLTIPCOLOR["BG"]
							}; padding: 0.375rem ;left: -0.25rem;bottom: 100%;position: absolute;background-color:${
								TOOLTIPCOLOR["BG"]
							};color:${
								TOOLTIPCOLOR["VALUE"]
							};border-radius:0.375rem">${componentContext.commonFunctions.getSpecificTranslation(
								this.value
							)}</span>
							<div style="width:5.25rem;overflow:hidden;white-space:nowrap;text-overflow:ellipsis;">${componentContext.commonFunctions.getSpecificTranslation(
								this.value
							)}</div>
							</div>`;
						} else {
							return `<span style="font-size:0.688rem;font-family:Poppins Regular;font-weight:400;color:#727487;gap:0.3125rem">
							<span style="width:5.25rem;overflow:hidden;white-space:nowrap;text-overflow:ellipsis;">${componentContext.commonFunctions.getSpecificTranslation(
								this.value
							)}</span>
							</span>`;
						}
					},
					align: "left",
					reserveSpace: true,
					verticalAlign: "bottom"
				},
				lineColor: "transparent",
				gridLineColor: "transparent",
				title: {
					text: null
				},
				max:
					this.data?.length > this.scrollbarThreshold
						? this.scrollbarThreshold - 1
						: this.data?.length - 1,
				tickLength: 0
			},
			yAxis: {
				labels: {
					enabled: this.enableBottomLabel
				},
				lineColor: "transparent",
				gridLineColor: "transparent",
				title: {
					text: "",
					align: "high"
				}
			},
			tooltip: {
				split: false,
				backgroundColor: undefined,
				borderColor: undefined,
				borderWidth: 0,
				shadow: true,
				formatter: this.toolTipFormatter,
				shared: false,
				useHTML: true,
				outside: this.showTooltipOutside
			},
			plotOptions: {
				bar: {
					groupPadding: 0,
					pointPadding: 0,
					borderWidth: 0,
					borderRadius: 2,
					pointWidth: this.pointWidth,
					cursor: "pointer",
					dataLabels: {
						enabled: true,
						style: {
							textOutline: 0
						}
					},
					events: {
						click: (event) => {
							this.dataTransferService.sendGraphSliceData({
								...event.point.options
							});
						}
					}
				},
				series: {
					color: this.barColor,
					cursor: "pointer",
					cropThreshold: 600
				}
			},
			legend: {
				enabled: false
			},
			credits: {
				enabled: false
			},
			series: [
				{
					name:
						componentContext.dataTransferService.currentReportingCriteria ===
						"sales"
							? this.commonFunctions.getSpecificTranslation("GMV")
							: this.commonFunctions.getSpecificTranslation("Quantity"),
					dataLabels: [
						{
							inside: false,
							allowOverlap: false,
							style: {
								color: "#D2D3D6"
							},
							formatter: this.formatterFunctionForOutsideDatalabels
						},
						{
							inside: true,
							align: "left",
							allowOverlap: false,
							formatter: this.formatterFunctionForInsideDatalabels
						}
					],
					point: {
						events: {
							mouseOver() {
								this.dataLabel.css({
									color: "#1C2331"
								});
								const spans = document.querySelectorAll(
									".highcharts-xaxis-labels span span"
								);
								spans.forEach((span) => {
									if (span.innerHTML == this.name) {
										(span as HTMLElement).style.color = "#1C2331";
									}
								});
							},
							mouseOut() {
								this.dataLabel.css({
									color: "#D2D3D6"
								});
								const spans = document.querySelectorAll(
									".highcharts-xaxis-labels span span"
								);
								spans.forEach((span) => {
									if (span.innerHTML == this.name) {
										(span as HTMLElement).style.color = "#727487";
									}
								});
							}
						}
					},
					data: this.data,
					states: {
						hover: {
							color: this.barHoverColor
						}
					}
				}
			]
		};
	}

	toolTipFormatter(ev): string {
		const dataLabel = this as Highcharts.DataLabelsOptions;
		switch (dataLabel["point"]["options"]["caller"]) {
			case "gmv-bar":
				return `<div
    style="background-color:${
			TOOLTIPCOLOR["BG"]
		};min-width:12rem;border-radius:0.375rem;height:5rem;display:flex;flex-direction: column;padding:0.625rem 0.875rem;border:none;margin:0;font-family:Poppins Regular;font-weight:400;row-gap:0.5rem;">
    <p style="color:${
			TOOLTIPCOLOR["TEXT"]
		};font-size:0.625rem;margin:0">${componentContext.commonFunctions.getSpecificTranslation(
					dataLabel["point"]["options"]["key"]
				)} (${dataLabel["point"]["options"]["percentage"]}%)</p>
    <div style="display:flex;gap:1rem;flex-direction:row;justify-content:space-between">
        <div style="display:flex;flex-direction: column;">
            <span style="color:${
							TOOLTIPCOLOR["VALUE"]
						};font-size:0.875rem;">${componentContext.commonFunctions.getSuffixedNumberFormat(
					dataLabel["point"]["options"]["quantity"]
				)}</span>
            <span style="color:${
							TOOLTIPCOLOR["TEXT"]
						};font-size:0.875rem;">${componentContext.commonFunctions.getSpecificTranslation(
					"Quantity Sold"
				)}</span>
        </div>
        <div style="display:flex;flex-direction: column;">
            <span style="color:${TOOLTIPCOLOR["VALUE"]};font-size:0.875rem;">${
					dataLabel["point"]["options"]["currency"]["symbol"]
				}${componentContext.commonFunctions.getSuffixedNumberFormat(
					dataLabel.y
				)}</span>
                    <span style="color:${
											TOOLTIPCOLOR["TEXT"]
										};font-size:0.875rem;">${componentContext.commonFunctions.getSpecificTranslation(
					"GMV"
				)}</span>
        </div>
    </div>
</div>`;
			case "assortments":
				return `<div
style="background-color:${
					TOOLTIPCOLOR["BG"]
				};min-width:8rem;border-radius:0.375rem;height:4rem;display:flex;flex-direction: column;padding:0.625rem 0.875rem;border:none;margin:0;font-family:Poppins Regular;font-weight:400;row-gap:0.5rem;">
<p style="color:${
					TOOLTIPCOLOR["TEXT"]
				};font-size:0.625rem;margin:0;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">${componentContext.commonFunctions.getSpecificTranslation(
					dataLabel["key"]
				)}</p>
<div style="display:flex;gap:1rem;flex-direction:row;justify-content:space-between">
<div style="display:flex;flex-direction: column;">
	<span style="color:${
		TOOLTIPCOLOR["VALUE"]
	};font-size:0.875rem;">${componentContext.commonFunctions.getSuffixedNumberFormat(
					dataLabel["point"]["options"]["percent"]
				)}%</span>
</div>
</div>
</div>`;
			case "bestSellingProduct":
				return `<div
style="background-color:${
					TOOLTIPCOLOR["BG"]
				};min-width:8rem;border-radius:0.375rem;display:flex;flex-direction: column;padding:0.625rem 0.8rem;border:none;margin:0;font-family:Poppins Regular;font-weight:400;gap:0.5rem">
<p style="color:${
					TOOLTIPCOLOR["TEXT"]
				};font-size:0.625rem;margin:0;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">${componentContext.commonFunctions.getSpecificTranslation(
					dataLabel["point"]["options"]["name"]
				)}</p>
<div style="display:flex;flex-direction: column;">
	<span style="color:${TOOLTIPCOLOR["VALUE"]};font-size:0.75rem;">${
					dataLabel["point"]["options"]["percentage"]
				}%</span>
</div>
</div>`;
			case "performanceDealerSellout":
			case "lowestPriceProductSellout":
				return `<div style="display:flex;flex-direction:column;background-color:${
					TOOLTIPCOLOR["BG"]
				};min-width:10rem;border-radius:0.375rem;padding:0.5rem 0.625rem;border:none;margin:0;font-family:Poppins Regular;font-weight:400;color:#ffffff">
			 				<div style="font-size:0.625rem;">${componentContext.commonFunctions.getSpecificTranslation(
								dataLabel["point"]["options"]["name"]
							)}</div>
							<div style="display:flex;flex-direction: column;margin-top:0.5rem">
								<span style="color:${
									TOOLTIPCOLOR["VALUE"]
								};font-size:0.8rem;">${componentContext.commonFunctions.getSuffixedNumberFormat(
					dataLabel["point"]["options"]["value"]
				)}</span>
								<span style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.8rem;">Quantity</span>
							</div>
						</div>`;
			case "channelDealerShare":
			case "channelGroupShare":
			case "bestsellerBrandShare":
			case "overviewDealerShare":
				return `<div style="display:flex;flex-direction:column;background-color:${
					TOOLTIPCOLOR["BG"]
				};min-width:10rem;border-radius:0.375rem;padding:0.5rem 0.625rem;border:none;margin:0;font-family:Poppins Regular;font-weight:400;color:#ffffff">
			 				<div style="font-size:0.625rem;">${dataLabel["point"]["options"]["name"]}</div>
							<div style="display:flex;flex-direction: column;margin-top:0.5rem">
								<span style="color:${TOOLTIPCOLOR["VALUE"]};font-size:0.875rem;">${
					dataLabel["point"]["criterion"] === "sales"
						? componentContext.commonFunctions.currencySelected.attributes
								.symbol
						: ""
				}${componentContext.commonFunctions.getSuffixedNumberFormat(
					dataLabel["point"]["options"]["value"]
				)}</span>
								<span style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.875rem;">${
					dataLabel["point"]["criterion"] === "sales"
						? componentContext.commonFunctions.getSpecificTranslation("Price")
						: componentContext.commonFunctions.getSpecificTranslation(
								"Quantity"
						  )
				}</span>
							</div>
						</div>`;
			case "skuShareKR":
				return `<div
				style="background-color:${
					TOOLTIPCOLOR["BG"]
				};min-width:12rem;border-radius:0.375rem;height:5rem;display:flex;flex-direction: column;padding:0.625rem 0.875rem;border:nine;margin:0;font-family:Poppins Regular;font-weight:400;row-gap:0.5rem;">
				<p style="color:${
					TOOLTIPCOLOR["TEXT"]
				};font-size:0.625rem;margin:0">${componentContext.commonFunctions.getSpecificTranslation(
					dataLabel["key"]
				)} (${dataLabel["y"]}%)</p>
				<div style="display:flex;gap:1rem;flex-direction:row;justify-content:space-between">
					<div style="display:flex;flex-direction: column;">
						<span style="color:${TOOLTIPCOLOR["VALUE"]};font-size:0.875rem;">${
					componentContext.meta?.criterion === "sales"
						? componentContext.commonFunctions.currencySelected.attributes
								.symbol
						: ""
				}${componentContext.commonFunctions.getSuffixedNumberFormat(
					dataLabel["point"]["options"]["value"]
				)}</span>
								<span style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.875rem;">${
					componentContext.meta?.criterion === "sales"
						? componentContext.commonFunctions.getSpecificTranslation("GMV")
						: componentContext.commonFunctions.getSpecificTranslation(
								"Quantity Sold"
						  )
				}</span>
					</div>
				</div>
			</div>`;
			case "brand-market-share-product-collection":
				return `<div style="background-color:${
					TOOLTIPCOLOR["BG"]
				};min-width:12rem;border-radius:0.375rem;height:5rem;display:flex;flex-direction: column;padding:0.625rem 0.875rem;border:nine;margin:0;font-family:Poppins Regular;font-weight:400;row-gap:0.5rem;">
							<p style="color:${
								TOOLTIPCOLOR["TEXT"]
							};font-size:0.625rem;margin:0">${componentContext.commonFunctions.getSpecificTranslation(
					dataLabel["key"]
				)} (${dataLabel["y"]}%)</p>
							<div style="display:flex;gap:1rem;flex-direction:row;justify-content:space-between">
							<div style="display:flex;flex-direction: column;">
								<span style="color:${TOOLTIPCOLOR["VALUE"]};font-size:0.875rem;">${
					dataLabel["point"]["options"]["share_on"] === "gmv"
						? componentContext.commonFunctions.currencySelected.attributes
								.symbol
						: ""
				}${componentContext.commonFunctions.getSuffixedNumberFormat(
					dataLabel["point"]["options"]["value"]
				)}</span>
										<span style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.875rem;">${
					dataLabel["point"]["options"]["share_on"] === "gmv"
						? componentContext.commonFunctions.getSpecificTranslation("GMV")
						: componentContext.commonFunctions.getSpecificTranslation(
								"Quantity Sold"
						  )
				}</span>
							</div>
						</div>`;
			default:
				return "";
		}
	}

	formatterFunctionForOutsideDatalabels(): string {
		const dataLabel = this as Highcharts.DataLabelsOptions;
		switch (dataLabel["point"]["options"]["caller"]) {
			case "gmv-bar":
				return `<span style="font-size:0.688rem;font-family:Poppins Medium;font-weight:500;">${
					dataLabel["point"]["options"]["currency"]["symbol"]
				}${componentContext.commonFunctions.getSuffixedNumberFormat(
					dataLabel.y
				)}</span>`;
			case "assortments":
				return `<span style="font-size:0.688rem;font-family:Poppins Medium;font-weight:500;">${componentContext.commonFunctions.getSuffixedNumberFormat(
					dataLabel.y
				)} ${componentContext.commonFunctions.getSpecificTranslation(
					"Assortments"
				)}</span>`;
			case "bestSellingProduct":
				return `<span style="font-size:0.688rem;font-family:Poppins Medium;font-weight:500;color:#000000">${dataLabel["point"]["options"]["percentage"]}%</span>`;
			case "performanceDealerSellout":
			case "channelDealerShare":
			case "channelGroupShare":
			case "bestsellerBrandShare":
			case "lowestPriceProductSellout":
			case "overviewDealerShare":
				return `<span style="font-size:0.688rem;font-family:Poppins Medium;font-weight:500;color:#000000">${dataLabel.y}%</span>`;
			case "skuShareKR":
				return `<span style="font-size:0.688rem;font-family:Poppins Medium;font-weight:500;">${dataLabel.y}%</span>`;
			default:
				return "";
		}
	}

	formatterFunctionForInsideDatalabels(): string {
		const dataLabel = this as Highcharts.DataLabelsOptions;
		if (
			!dataLabel["point"] ||
			!dataLabel["point"]["shapeArgs"] ||
			dataLabel["point"]["shapeArgs"]["height"] < 20
		)
			return "";
		switch (dataLabel["point"]["options"]["caller"]) {
			case "gmv-bar":
				return `<span style="font-size:0.625rem;color: #FFFFFF;font-family:Poppins Regular;font-weight:400;">${dataLabel["point"]["options"]["percentage"]}%</span>`;
			default:
				return "";
		}
	}

	chartCallback: Highcharts.ChartCallbackFunction = (chart) => {
		this.chartRef = chart;
	};

	ngOnDestroy() {
		this.destroy$.next(true);
		this.destroy$.unsubscribe();
		this.listenerFn();
	}
}
