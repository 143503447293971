import {
	Component,
	OnInit,
	OnChanges,
	ViewChild,
	ElementRef, Input
} from "@angular/core";
import { GlobalService } from "@ds-common-services/http-services/global.service";
import { NotificationService } from "@ds-common-services/utility-services/notification.service";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { Subject, Subscription } from "rxjs";
import { RolePermissions } from "@ds-models/access-matrix.model";
import { CommonFunctionsService } from "@ds-common-services/utility-services/common-functions.service";
import { AccessMatrixService } from "@ds-common-services/utility-services/access-matrix.service";
import { TranslateService } from "@ngx-translate/core";
import { TranslationService } from "@ds-common-services/utility-services/translation.service";
import { finalize, takeUntil } from "rxjs/operators";
import { Router } from "@angular/router";
import { saveAs } from 'file-saver-es';
import { DataTransferService } from "@ds-common-services/utility-services/data-transfer.service";
import { ScorecardService } from "@ds-private-layouts/scorecard/scorecard.service";
import { MixpanelService } from '@ds-common-services/utility-services/mixpanel.service';
import { Modules } from "@ds-shared/enums/modules.enum";

@Component({
	selector: "bulk-actions",
	templateUrl: "./bulk-actions.component.html",
	styleUrls: ["./bulk-actions.component.scss"]
})
export class BulkActionsComponent implements OnInit, OnChanges {
	// @Input() mappedType;
	public bulkExportSubscription: Subscription;
	public bulkImportSubscription: Subscription;
	private modalRef: NgbModalRef;
	bulkFileToUpload: any;
	uploadingFile: boolean = false;
	public $destroy: Subject<boolean> = new Subject();
	public permissions: RolePermissions = new RolePermissions();
	public profileDownloadProgress = {
		disabled: false,
		downloaded: false
	};
	public isProfileUploading: boolean = false;
	public fileToUpload;
	public errorMessage: string = null;
	@ViewChild("content") content: ElementRef;
	@Input() listType;
	@Input() buttonStyle={};
	constructor(
		public globalService: GlobalService,
		public notificationService: NotificationService,
		private modalService: NgbModal,
		private scorecardService: ScorecardService,
		private commonFunction: CommonFunctionsService,
		private accessMatrixService: AccessMatrixService,
		private translate: TranslateService,
		public translationService: TranslationService,
		public router: Router,
		public dataTransferService: DataTransferService,
		private mixpanelService: MixpanelService
	) {
		this.permissions = this.accessMatrixService.getPermissionByRoute();
		this.setUpdatedLang();
	}

	ngOnInit(): void {
	 }

	ngOnChanges() { }



	private getIEType() {
		// I mport or E xport Type
		// return this.mappedType === "your" ? "profiles" : "competitor-profiles";
		return "scorecard/bulk-import";
	}

	public checkFileSizeExceed(eventDetails) {
		if (this.validateFile(eventDetails)) {
			this.fileToUpload = eventDetails.target.files[0];
			return true;
		}
		eventDetails.target.value = "";
		this.resetFileInput();
		return false;
	}

	validateFile(eventDetails) {
		const getFile = eventDetails.target.files[0].name.split('.');
		const getFormat = getFile[getFile.length - 1];
		if (!(getFormat === 'xlsx' || getFormat === 'xls')) {
			this.errorMessage = "Please Select a XLS or XLSX File.";
			return false;
		}
		else if (eventDetails.target.files[0].size / 1024 / 1024 > 5) {
			this.errorMessage = "File size exceeded. Choose file less than 5mb";
			return false;
		}
		else {
			this.errorMessage = null;
			return true;
		}
	}

	onProfilesUpload() {
		this.isProfileUploading = true;
		this.commonFunction
			.getBase64(this.fileToUpload)
			.then((base64) => {
				const data = (base64 as string).split("base64,")[1];
				this.scorecardService
					.importProfiles({ data },this.commonFunction.currencySelected.id, this.listType)
					.pipe(
						finalize(() => {
							this.isProfileUploading = false;
							this.resetFileInput();
						}),
						takeUntil(this.$destroy)
					)
					.subscribe(
						(res: any) => {
							if ("errorMessage" in res) {
								this.notificationService.setMessage(1100, res.errorMessage);
								// this.errorMessage = "File not imported.";
							} else {
								this.mixpanelService.track(this.listType + ' scorecard import done' ,{})
								this.notificationService.setMessage(1000, res.summary);
								this.commonFunction.reRender();
								this.closeModal();
							}
						},
						(err: any) => {
							this.notificationService.setMessage(
								1100,
								this.translate.instant(err.error.summary ? err.error.summary : "Something went wrong, please try again")
							);
						}
					);
			})
			.catch(() => {
				this.resetFileInput();
				this.notificationService.setMessage(
					1100,
					this.translate.instant("Something went wrong, please try again")
				);
			});
	}

	resetFileInput() {
		(document.getElementById('buildImport') as any).value = '';
		this.fileToUpload = null;
	}

	public onProfilesDownload() {
		this.profileDownloadProgress.disabled = true;
		this.notificationService.setMessage(
			1200,
			this.commonFunction.getSpecificTranslation("Your download will begin shortly")
		  );
		this.scorecardService
			.exportProfiles(this.getIEType())
			.pipe(
				finalize(() => {
					this.profileDownloadProgress.disabled = false;
				}),
				takeUntil(this.$destroy)
			)
			.subscribe(
				(res) => {
					this.notificationService.setMessage(
						1000,
						this.translate.instant("File Downloaded Successfully")
					);
					this.profileDownloadProgress.downloaded = true;
					setTimeout(() => {
						this.profileDownloadProgress.downloaded = false;
					}, 1000);
					const a = document.createElement("a");
					a.style.display = "none";
					a.href = res;
					document.body.appendChild(a);
					a.click();
				},
				() => {
					this.notificationService.setMessage(
						1100,
						this.translate.instant("Something went wrong, please try again")
					);
				}
			);
	}

	openImportModal() {
		this.errorMessage = null;
		this.modalRef = this.modalService.open(this.content, {
			centered: true,
			windowClass: "competitor-catalogue-modal",
			size: "md",
			backdrop: "static",
			keyboard: false
		});
	}

	onFileClick(eventDetails) { }

	downloadSampleTemplate() {
		this.profileDownloadProgress.disabled = true;
		this.scorecardService.downloadSampleFile(this.listType).subscribe((res) => {
			saveAs(new Blob([res], { type: "application/xlsx" }), this.listType !== 'product_collection' ? 'tracker_import_sample.xlsx' : 'product_collection_import_sample.xlsx');
			this.profileDownloadProgress.disabled = false;
		}, (err) => {
			this.notificationService.setMessage(
				1100,
				this.translate.instant("Something went wrong, please try again")
			);
			this.profileDownloadProgress.disabled = false;
		})
	}

	closeModal() {
		this.resetFileInput();
		this.modalRef.close();
	}

	setUpdatedLang() {
		let lang = this.translationService.getBrowsersLocals();
		this.translate.use(`${lang.code}/locale`);
	}

	ngOnDestroy(): void {
		this.$destroy.next(true);
		this.$destroy.unsubscribe();
	}
}
