/* eslint-disable */
import { Component, OnInit, Input, SimpleChanges } from "@angular/core";
import * as Highcharts from "highcharts/highstock";
import { filter, Subject, takeUntil } from "rxjs";
import { DataTransferService } from "@ds-shared/common-services/utility-services/data-transfer.service";
let componentContext;
import { CommonFunctionsService } from "@ds-shared/common-services/utility-services/common-functions.service";
import { TOOLTIPCOLOR } from "@ds-shared/enums/common.enum";
import { SAVE_WIDGET } from "@ds-shared/enums/reporting.enum";

@Component({
	selector: "app-coloumn-chart",
	templateUrl: "./coloumn-chart.component.html",
	styleUrls: ["./coloumn-chart.component.scss"]
})
export class ColoumnChartComponent implements OnInit {
	@Input() public data;
	@Input() public categories;
	@Input() public showLegends = false;
	@Input() public caller = "default";
	@Input() public yAxisName = "";
	@Input() public xAxisName = "default";
	@Input() public scrollbarThreshold = 12;
	@Input() public pointWidth = 24;
	@Input() public isInverted = false;
	@Input() public grouping = true;
	@Input() public showYaxis = true;
	@Input() public xAxisLineWidth = 1;
	@Input() public xAxisAlign = undefined;
	@Input() public reserveSpace = undefined;
	@Input() public shared = false;
	@Input() isDate;
	@Input() public yAxisType = "";
	public Highcharts: typeof Highcharts = Highcharts;
	public highchartsData: typeof Highcharts;
	public chartOptions;
	private destroy$: Subject<boolean> = new Subject();
	private chartRef;
	readonly MONTHS = [
		"Jan",
		"Feb",
		"Mar",
		"Apr",
		"May",
		"Jun",
		"Jul",
		"Aug",
		"Sep",
		"Oct",
		"Nov",
		"Dec"
	];

	constructor(
		private dataTransferService: DataTransferService,
		private commonFunctions: CommonFunctionsService
	) {
		this.dataTransferService.secondaaryMenuToggleSignal$
			.pipe(
				filter((res) => res),
				takeUntil(this.destroy$)
			)
			.subscribe((res) => {
				setTimeout(() => {
					this.chartRef.reflow();
				}, 0);
			});
	}

	ngOnInit(): void {
		componentContext = this;
		Highcharts.setOptions({
			lang: {
				thousandsSep: ","
			}
		});
	}
	ngAfterViewInit(): void {
		setTimeout(() => {
			this.renderColumnChart();
		}, 0);
	}
	ngOnChanges(changes: SimpleChanges) {
		componentContext = this;
	}


	public renderColumnChart() {
		this.highchartsData = Highcharts;
		this.chartOptions = {
			chart: {
				type: "column",
				inverted: this.isInverted,
				events: {
					load: function () {
						document
							.querySelectorAll(".highcharts-scrollbar-button")
							.forEach((el) => {
								el.remove();
							});
						document
							.querySelectorAll(".highcharts-scrollbar-arrow")
							.forEach((el) => {
								el.remove();
							});
					}
				}
			},
			title: {
				text: ""
			},
			scrollbar: {
				enabled: this.categories?.length > this.scrollbarThreshold,
				barBackgroundColor: "#aaaaaae5",
				barBorderRadius: 7,
				barBorderWidth: 0,
				buttonBackgroundColor: "#B3B3B3",
				buttonBorderWidth: 0,
				buttonBorderRadius: 7,
				trackBackgroundColor: "none",
				trackBorderWidth: 0,
				trackBorderRadius: 8,
				trackBorderColor: "#CCC",
				rifleColor: "transparent"
			},
			tooltip: {
				split: false,
				backgroundColor: undefined,
				borderColor: undefined,
				borderWidth: 0,
				shadow: false,
				hideDelay: 100,
				formatter: this.toolTipFormatter,
				shared: this.shared,
				useHTML: true,
				outside: true
			},
			xAxis: {
				labels: {
					formatter: (ev) => {
						if (componentContext.commonFunctions.isDateStringValid(ev.value)) {
							return `${
								this.MONTHS[new Date(ev.value).getMonth()] +
								" " +
								new Date(ev.value).getDate()
							}`;
						}
						return ev.value; // clean, unformatted number for year
					},
					align: this.xAxisAlign,
					reserveSpace: this.reserveSpace,
					style: {
						color: "#727487",
						lineHeight: "normal",
						overflow: "hidden",
						whiteSpace: "nowrap",
						textOverflow: "ellipsis",
						width: 100
					}
				},
				lineWidth: this.xAxisLineWidth,
				categories: this.categories,
				max:
					this.categories?.length > this.scrollbarThreshold
						? this.scrollbarThreshold - 1
						: this.categories?.length - 1,
				tickLength: 0
			},
			plotOptions: {
				column: {
					dataLabels: {
						enabled: true,
						formatter: this.dataLabelFormatter,
						style: {
							textOutline: 0
						}
					}
				},
				series: {
					grouping: this.grouping,
					groupPadding: 2,
					pointPadding: 2,
					borderWidth: 0,
					pointWidth: this.pointWidth,
					cursor: "pointer",
					color: "#FF5E00"
				}
			},
			yAxis: {
				type:componentContext.yAxisType,
				labels: {
					enabled: this.showYaxis
				},
				title:  {
					text: componentContext.commonFunctions.getSpecificTranslation(this.yAxisName) && componentContext.commonFunctions.getSpecificTranslation(this.yAxisName),
					y: -30,
					style: {
						fontFamily: "Poppins Regular",
						fontSize: "0.625rem",
						fontWeight: "400",
						letterSpacing: "0.01em",
						color: "#727487"
					}
				},
				gridLineColor: "transparent"
			},
			credits: {
				enabled: false
			},
			legend: {
				enabled: this.showLegends,
				symbolWidth: 16,
				symbolRadius: 0,
				squareSymbol: true
			},
			series: this.data
		};
	}

	toolTipFormatter(ev): string {
		const dataLabel = this as Highcharts.DataLabelsOptions;
		const caller = componentContext.caller === SAVE_WIDGET ? dataLabel['series']['userOptions']['caller'] : componentContext.caller;
		switch (caller) {
			case "productSales":
			case "dealerSales":
				const start_Date = dataLabel['series']['userOptions']['dateObj'][dataLabel.x]['start_date']
				const end_Date = dataLabel['series']['userOptions']['dateObj'][dataLabel.x]['end_date']
				return `<div style="display:flex;flex-direction:column;background-color:${TOOLTIPCOLOR["BG"]};min-width:10rem;border-radius:0.375rem;padding:0.5rem 0.625rem;border:none;margin:0;font-family:Poppins Regular;font-weight:400;color:#121737">
							<p style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.625rem;margin:0">
								${dataLabel["key"]} ( ${componentContext.MONTHS[new Date(start_Date).getMonth()] +" " +
								new Date(start_Date).getDate()} - ${componentContext.MONTHS[new Date(end_Date).getMonth()] +
								" " + new Date(end_Date).getDate()} )
							</p>
							<div style="display:flex;flex-direction: column;margin-top:0.5rem">
								<span style="color:${TOOLTIPCOLOR["VALUE"]};font-size:0.875rem;">${
									dataLabel["series"]["userOptions"]["criteria"] === "sales"
										? componentContext.commonFunctions.currencySelected
												.attributes.symbol
										: ""
								}${componentContext.commonFunctions.getSuffixedNumberFormat(
					dataLabel.y
				)}</span>
								<span style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.875rem;">${
									dataLabel["series"]["userOptions"]["criteria"] === "sales"
										? componentContext.commonFunctions.getSpecificTranslation("GMV")
										: componentContext.commonFunctions.getSpecificTranslation("Quantity Sold")
								}</span>
							</div>
						</div>`;
			case "performanceProductSellout":
				return `<div style="display:flex;flex-direction:column;background-color:${TOOLTIPCOLOR["BG"]};min-width:10rem;border-radius:0.375rem;padding:0.5rem 0.625rem;border:none;margin:0;font-family:Poppins Regular;font-weight:400;color:#ffffff">
			 				<div style="font-size:0.625rem;">${dataLabel.x}</div>
							<div style="display:flex;flex-direction: column;margin-top:0.5rem">
								<span style="color:${TOOLTIPCOLOR["VALUE"]};font-size:0.875rem;">${
									dataLabel["series"]["userOptions"]["criterion"] === "sales"
										? componentContext.commonFunctions.currencySelected
												.attributes.symbol
										: ""
								}${componentContext.commonFunctions.getSuffixedNumberFormat(
					dataLabel.y
				)}</span>
								<span style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.875rem;">${
									dataLabel["series"]["userOptions"]["criterion"] === "sales"
										? "Price"
										: "Quantity"
								}</span>
							</div>
						</div>`;
			case "channelsDetail":
				return `<div
						style="background-color:${TOOLTIPCOLOR["BG"]};min-width:12rem;border-radius:0.375rem;height:5rem;display:flex;flex-direction: column;padding:0.625rem 0.875rem;border:none;margin:0;font-family:Poppins Regular;font-weight:400;row-gap:0.5rem;">
						<p style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.625rem;margin:0">${dataLabel.x}</p>
						<div style="display:flex;gap:1rem;flex-direction:row;justify-content:space-between">
						<div style="display:flex;flex-direction: column;">
							<span style="color:${TOOLTIPCOLOR["VALUE"]};font-size:0.875rem;">${
								componentContext.commonFunctions.currencySelected.attributes
									.symbol
							}${componentContext.commonFunctions.getSuffixedNumberFormat(
					dataLabel["points"][1].y
				)}</span>
									<span style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.875rem;">${componentContext.commonFunctions.getSpecificTranslation(
										"Sold"
									)}</span>
						</div>
							<div style="display:flex;flex-direction: column;">
								<span style="color:${TOOLTIPCOLOR["VALUE"]};font-size:0.875rem;">${componentContext.commonFunctions.getSuffixedNumberFormat(
									dataLabel["points"][0].y
								)}</span>
								<span style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.875rem;">${componentContext.commonFunctions.getSpecificTranslation(
									"Quantity"
								)}</span>
							</div>
							
						</div>
					</div>`;
			case "xSimple":
				return `<div style="display:flex;flex-direction:column;background-color:${TOOLTIPCOLOR["BG"]};min-width:10rem;border-radius:0.375rem;padding:0.5rem 0.625rem;border:none;margin:0;font-family:Poppins Regular;font-weight:400;color:#121737">
				<p style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.625rem;margin:0">${
					( componentContext.isDate || dataLabel['series']['userOptions']['isDate'] )  ?
					dataLabel["x"].toString().includes(":")
						? "at " + componentContext.tConvert(dataLabel["x"])
						: "on " +
						  componentContext.MONTHS[new Date(dataLabel["x"]).getMonth()] +
						  " " +
						  new Date(dataLabel["x"]).getDate() : dataLabel["x"]
				}</p>
							<div style="display:flex;flex-direction: column;margin-top:0.25rem">
								<span style="color:${TOOLTIPCOLOR["VALUE"]};font-size:0.875rem;">${componentContext.commonFunctions.getSuffixedNumberFormat(
									dataLabel.y
								)}</span>
								</span>
								<span style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.875rem;">${
									componentContext.caller === SAVE_WIDGET ? componentContext.commonFunctions.getSpecificTranslation(dataLabel['series']['userOptions']['yAxisName']) : componentContext.commonFunctions.getSpecificTranslation(componentContext.yAxisName)
								}</span>
							</div>
						</div>`;
			case "xUnitYUnit":
				return `<div style="display:flex;flex-direction:column;background-color:${TOOLTIPCOLOR["BG"]};min-width:10rem;border-radius:0.375rem;padding:0.5rem 0.625rem;border:none;margin:0;font-family:Poppins Regular;font-weight:400;color:#121737">
				<p style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.625rem;margin:0">${
					( componentContext.isDate || dataLabel['series']['userOptions']['isDate'] )  ?
					dataLabel["x"].toString().includes(":")
						? "at " + componentContext.tConvert(dataLabel["x"])
						: "on " +
						  componentContext.MONTHS[new Date(dataLabel["x"]).getMonth()] +
						  " " +
						  new Date(dataLabel["x"]).getDate() : dataLabel["x"]
				}</p>
			<div style="display:flex;flex-direction: column;margin-top:0.25rem">
				<span style="color:${TOOLTIPCOLOR["VALUE"]};font-size:0.875rem;">${componentContext.commonFunctions.getSuffixedNumberFormat(
					dataLabel.y
				)} ${componentContext.commonFunctions.getSpecificTranslation('units')}</span>
				</span>
				<span style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.875rem;">${
					componentContext.caller === SAVE_WIDGET ? dataLabel['series']['userOptions']['yAxisName'] : componentContext.yAxisName
				}</span>
			</div>
		</div>`;
			case "xCurrencyYCurrency":
				return `<div style="display:flex;flex-direction:column;background-color:${TOOLTIPCOLOR["BG"]};min-width:10rem;border-radius:0.375rem;padding:0.5rem 0.625rem;border:none;margin:0;font-family:Poppins Regular;font-weight:400;color:#121737">
				<p style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.625rem;margin:0">${
					( componentContext.isDate || dataLabel['series']['userOptions']['isDate'] )  ?
					dataLabel["x"].toString().includes(":")
						? "at " + componentContext.tConvert(dataLabel["x"])
						: "on " +
						  componentContext.MONTHS[new Date(dataLabel["x"]).getMonth()] +
						  " " +
						  new Date(dataLabel["x"]).getDate() : dataLabel["x"]
				}</p>
                            <div style="display:flex;flex-direction: column;margin-top:0.25rem">
                                <span style="color:${TOOLTIPCOLOR["VALUE"]};font-size:0.875rem;">${
																	componentContext.commonFunctions
																		.currencySelected.attributes.symbol
																}${componentContext.commonFunctions.getSuffixedNumberFormat(
					dataLabel.y
				)}</span>
                                </span>
                                <span style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.875rem;">${
									componentContext.caller === SAVE_WIDGET ? componentContext.commonFunctions.getSpecificTranslation(dataLabel['series']['userOptions']['yAxisName']) : componentContext.commonFunctions.getSpecificTranslation(componentContext.yAxisName)
																}</span>
                            </div>
                        </div>`;
			case "xCurrencyYUnit":
				return `<div style="display:flex;flex-direction:column;background-color:${TOOLTIPCOLOR["BG"]};min-width:10rem;border-radius:0.375rem;padding:0.5rem 0.625rem;border:none;margin:0;font-family:Poppins Regular;font-weight:400;color:#121737">
				<p style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.625rem;margin:0">${
					( componentContext.isDate || dataLabel['series']['userOptions']['isDate'] )  ?
					dataLabel["x"].toString().includes(":")
						? "at " + componentContext.tConvert(dataLabel["x"])
						: "on " +
						  componentContext.MONTHS[new Date(dataLabel["x"]).getMonth()] +
						  " " +
						  new Date(dataLabel["x"]).getDate() : dataLabel["x"]
				}</p>
                            <div style="display:flex;flex-direction: column;margin-top:0.25rem">
                                <span style="color:${TOOLTIPCOLOR["VALUE"]};font-size:0.875rem;">${componentContext.commonFunctions.getSuffixedNumberFormat(
																	dataLabel.y
																)} ${componentContext.commonFunctions.getSpecificTranslation('units')}</span>
                                </span>
                                <span style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.875rem;">${
									componentContext.caller === SAVE_WIDGET ? componentContext.commonFunctions.getSpecificTranslation(dataLabel['series']['userOptions']['yAxisName']) : componentContext.commonFunctions.getSpecificTranslation(componentContext.yAxisName)
																}</span>
                            </div>
                        </div>`;
			case "xUnit":
				return `<div style="display:flex;flex-direction:column;background-color:${TOOLTIPCOLOR["BG"]};min-width:10rem;border-radius:0.375rem;padding:0.5rem 0.625rem;border:none;margin:0;font-family:Poppins Regular;font-weight:400;color:#121737">
				<p style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.625rem;margin:0">${
					( componentContext.isDate || dataLabel['series']['userOptions']['isDate'] )  ?
					dataLabel["x"].toString().includes(":")
						? "at " + componentContext.tConvert(dataLabel["x"])
						: "on " +
						  componentContext.MONTHS[new Date(dataLabel["x"]).getMonth()] +
						  " " +
						  new Date(dataLabel["x"]).getDate() : dataLabel["x"]
				}</p>
                            <div style="display:flex;flex-direction: column;margin-top:0.25rem">
                                <span style="color:${TOOLTIPCOLOR["VALUE"]};font-size:0.875rem;">${componentContext.commonFunctions.getSuffixedNumberFormat(
																	dataLabel.y
																)}</span>
                                </span>
                                <span style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.875rem;">${
									componentContext.caller === SAVE_WIDGET ? componentContext.commonFunctions.getSpecificTranslation(dataLabel['series']['userOptions']['yAxisName']) : componentContext.commonFunctions.getSpecificTranslation(componentContext.yAxisName)
																}</span>
                            </div>
                        </div>`;
			case "xUnitYCurrency":
				return `<div style="display:flex;flex-direction:column;background-color:${TOOLTIPCOLOR["BG"]};min-width:10rem;border-radius:0.375rem;padding:0.5rem 0.625rem;border:none;margin:0;font-family:Poppins Regular;font-weight:400;color:#121737">
				<p style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.625rem;margin:0">${
					( componentContext.isDate || dataLabel['series']['userOptions']['isDate'] )  ?
					dataLabel["x"].toString().includes(":")
						? "at " + componentContext.tConvert(dataLabel["x"])
						: "on " +
						  componentContext.MONTHS[new Date(dataLabel["x"]).getMonth()] +
						  " " +
						  new Date(dataLabel["x"]).getDate() : dataLabel["x"]
				}</p>
                            <div style="display:flex;flex-direction: column;margin-top:0.25rem">
                                <span style="color:${TOOLTIPCOLOR["VALUE"]};font-size:0.875rem;">${
																	componentContext.commonFunctions
																		.currencySelected.attributes.symbol
																}${componentContext.commonFunctions.getSuffixedNumberFormat(
					dataLabel.y
				)}</span>
                                </span>
                                <span style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.875rem;">${
									componentContext.caller === SAVE_WIDGET ? componentContext.commonFunctions.getSpecificTranslation(dataLabel['series']['userOptions']['yAxisName']) : componentContext.commonFunctions.getSpecificTranslation(componentContext.yAxisName)
																}</span>
                            </div>
                        </div>`;
			case "xCurrency":
				return `<div style="display:flex;flex-direction:column;background-color:${TOOLTIPCOLOR["BG"]};min-width:10rem;border-radius:0.375rem;padding:0.5rem 0.625rem;border:none;margin:0;font-family:Poppins Regular;font-weight:400;color:#121737">
				<p style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.625rem;margin:0">${
					( componentContext.isDate || dataLabel['series']['userOptions']['isDate'] )  ?
					dataLabel["x"].toString().includes(":")
						? "at " + componentContext.tConvert(dataLabel["x"])
						: "on " +
						  componentContext.MONTHS[new Date(dataLabel["x"]).getMonth()] +
						  " " +
						  new Date(dataLabel["x"]).getDate() : dataLabel["x"]
				}</p>
                            <div style="display:flex;flex-direction: column;margin-top:0.25rem">
                                <span style="color:${TOOLTIPCOLOR["VALUE"]};font-size:0.875rem;">${componentContext.commonFunctions.getSuffixedNumberFormat(
																	dataLabel.y
																)}</span>
                                </span>
                                <span style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.875rem;">${
									componentContext.caller === SAVE_WIDGET ? componentContext.commonFunctions.getSpecificTranslation(dataLabel['series']['userOptions']['yAxisName']) : componentContext.commonFunctions.getSpecificTranslation(componentContext.yAxisName)
																}</span>
                            </div>
                        </div>`;
			case "xSimpleYCurrency":
				return `<div style="display:flex;flex-direction:column;background-color:${TOOLTIPCOLOR["BG"]};min-width:10rem;border-radius:0.375rem;padding:0.5rem 0.625rem;border:none;margin:0;font-family:Poppins Regular;font-weight:400;color:#121737">
				<p style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.625rem;margin:0">${
					( componentContext.isDate || dataLabel['series']['userOptions']['isDate'] )  ?
					dataLabel["x"].toString().includes(":")
						? "at " + componentContext.tConvert(dataLabel["x"])
						: "on " +
						  componentContext.MONTHS[new Date(dataLabel["x"]).getMonth()] +
						  " " +
						  new Date(dataLabel["x"]).getDate() : dataLabel["x"]
				}</p>
                            <div style="display:flex;flex-direction: column;margin-top:0.25rem">
                                <span style="color:${TOOLTIPCOLOR["VALUE"]};font-size:0.875rem;">${
																	componentContext.commonFunctions
																		.currencySelected.attributes.symbol
																}${componentContext.commonFunctions.getSuffixedNumberFormat(
					dataLabel.y
				)}</span>
                                </span>
                                <span style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.875rem;">${
									componentContext.caller === SAVE_WIDGET ? componentContext.commonFunctions.getSpecificTranslation(dataLabel['series']['userOptions']['yAxisName']) : componentContext.commonFunctions.getSpecificTranslation(componentContext.yAxisName)
																}</span>
                            </div>
                        </div>`;
			case "xSimpleYUnit":
				return `<div style="display:flex;flex-direction:column;background-color:${TOOLTIPCOLOR["BG"]};min-width:10rem;border-radius:0.375rem;padding:0.5rem 0.625rem;border:none;margin:0;font-family:Poppins Regular;font-weight:400;color:#121737">
				<p style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.625rem;margin:0">${
					( componentContext.isDate || dataLabel['series']['userOptions']['isDate'] )  ?
					dataLabel["x"].toString().includes(":")
						? "at " + componentContext.tConvert(dataLabel["x"])
						: "on " +
						  componentContext.MONTHS[new Date(dataLabel["x"]).getMonth()] +
						  " " +
						  new Date(dataLabel["x"]).getDate() : dataLabel["x"]
				}</p>
                            <div style="display:flex;flex-direction: column;margin-top:0.25rem">
                                <span style="color:${TOOLTIPCOLOR["VALUE"]};font-size:0.875rem;">${componentContext.commonFunctions.getSuffixedNumberFormat(
																	dataLabel.y
																)}${componentContext.commonFunctions.getSpecificTranslation('units')}</span>
                                </span>
                                <span style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.875rem;">${
									componentContext.caller === SAVE_WIDGET ? componentContext.commonFunctions.getSpecificTranslation(dataLabel['series']['userOptions']['yAxisName']) : componentContext.commonFunctions.getSpecificTranslation(componentContext.yAxisName)
																}</span>
                            </div>
                        </div>`;
					case "categoryPerformanceBySegment":
						return `<div style="display:flex;flex-direction:column;background-color:${TOOLTIPCOLOR["BG"]};min-width:10rem;border-radius:0.375rem;padding:0.5rem 0.625rem;border:none;margin:0;font-family:Poppins Regular;font-weight:400;color:#121737">
						<div style="display:flex;flex-direction: column;margin-top:0.5rem">
						<span style="color:${TOOLTIPCOLOR["VALUE"]};font-size:0.875rem;">${
							dataLabel.x
						}</span>
									</div>
									<div style="display:flex;flex-direction: column;margin-top:0.5rem">
										<span style="color:${TOOLTIPCOLOR["VALUE"]};font-size:0.875rem;">${componentContext.commonFunctions.getSuffixedNumberFormat(
							dataLabel.y
						)}</span>
									</div>
								</div>`;
					default:
					return "";
		}
	}

	formatterFunction(): string {
		const dataLabel = this as Highcharts.DataLabelsOptions;
		return componentContext.commonFunctions.getSuffixedNumberFormat(
			dataLabel.y
		);
	}

	dataLabelFormatter(): string {
		const dataLabel = this as Highcharts.DataLabelsOptions;
		switch (componentContext.caller) {
			case "productSales":
			case "dealerSales":
			case "channelsDetail":
				return `<span style="font-size:0.688rem;font-family:Poppins Medium;font-weight:500;">${componentContext.commonFunctions.getSuffixedNumberFormat(
					dataLabel.y
				)}</span>`;
			case "performanceProductSellout":
				return `${componentContext.commonFunctions.getSuffixedNumberFormat(
					dataLabel.y
				)}`;
			default:
				return "";
		}
	}
	chartCallback: Highcharts.ChartCallbackFunction = (chart) => {
		this.chartRef = chart;
	};
}
