import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
	UntypedFormControl,
	UntypedFormGroup,
	Validators
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NotificationService } from "@ds-common-services/utility-services/notification.service";
import { UserManagementService } from "@ds-private-layouts/user-management/user-management.service";
import { DataTransferService } from "@ds-common-services/utility-services/data-transfer.service";
import { Subscription } from "rxjs";
import { MixpanelService } from "@ds-common-services/utility-services/mixpanel.service";
import { CommonFunctionsService } from "@ds-shared/common-services/utility-services/common-functions.service";

@Component({
	selector: "app-add-edit-team-member",
	templateUrl: "./add-edit-team-member.component.html",
	styleUrls: ["./add-edit-team-member.component.scss"]
})
export class AddEditTeamMemberComponent implements OnInit {
	@Input() userData;
	public actionType;
	public submitted: boolean = false;
	public response: any;
	public umUserInfoSub: Subscription;
	public notificationInfo: string;
	public userForm: UntypedFormGroup;
	public rolesSubscription: Subscription;
	public roleList: any[] = [];
	public loadedRoleList: boolean = false;
	public addSubscription: Subscription;
	public updateSubscription: Subscription;
	public showPassword: boolean = true;
	@Output("onCancelAction") onCancelAction: EventEmitter<any> =
		new EventEmitter();
	@Output("onSuccessAction") onSuccessAction: EventEmitter<any> =
		new EventEmitter();

	constructor(
		private userManagementService: UserManagementService,
		private notificationService: NotificationService,
		private route: ActivatedRoute,
		private mixpanelService: MixpanelService,
		private commonFunctions: CommonFunctionsService
	) {}

	ngOnInit(): void {
		this.notificationInfo =
			"Password must contain 8 characters, including one special character, one number and one capital letter";
		this.getRolesList();
		this.actionType = this.commonFunctions.getURLSegments()[2];
		this.userForm = new UntypedFormGroup({
			first_name: new UntypedFormControl(this.getDefaultValue("first_name"), [
				Validators.required,
				this.validateName.bind(this)
			]),
			last_name: new UntypedFormControl(this.getDefaultValue("last_name"), [
				Validators.required,
				this.validateName.bind(this)
			]),
			email: new UntypedFormControl(this.getDefaultValue("email"), [
				Validators.required,
				Validators.email,
				Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")
			]),
			password: new UntypedFormControl(
				this.getDefaultValue("password"),
				Validators.required
			),
			contact: new UntypedFormControl(this.getDefaultValue("contact"), [
				Validators.required,
				Validators.maxLength(15)
			]),
			countryCode: new UntypedFormControl(this.getDefaultValue("countryCode"), [
				Validators.required,
				Validators.maxLength(3),
				Validators.minLength(2)
			]),
			select_role: new UntypedFormControl(
				this.getDefaultValue("role"),
				Validators.required
			)
		});
		if (this.actionType === "edit") {
			this.userForm.controls["email"].disable();
			this.userForm.controls["password"].disable();
		}
	}

	noSpace(event): boolean {
		const charCode = event.which ? event.which : event.keyCode;
		if (charCode === 32) {
			return false;
		}
		return true;
	}

	numberOnly(event) {
		const charCode = event.which ? event.which : event.keyCode;
		if (charCode >= 48 && charCode <= 57) {
			return true;
		}
		return false;
	}

	getDefaultValue(forLabel: string): any {
		if (this.actionType === "edit") {
			if (forLabel === "contact")
				return this.userData.contact.includes("-")
					? this.userData.contact?.split("-")[1]
					: this.userData.contact;
			else if (forLabel === "countryCode")
				return this.userData.contact.includes("-")
					? this.userData.contact?.split("-")[0]
					: null;
			else if (forLabel === "password") return "- - - -";
			return this.userData?.[forLabel];
		}
		return null;
	}
	onResponseAction(response: any) {
		if (response) {
			this.onSuccessAction.emit();
			this.notificationService.setMessage(
				response["context_code"],
				response["summary"]
			);
		}
	}
	onSubmit() {
		this.submitted = true;
		if (this.userForm.status === "VALID") {
			let data = {
				first_name: this.userForm.get("first_name").value,
				last_name: this.userForm.get("last_name").value,
				email: this.userForm.get("email").value,
				password: this.userForm.get("password").value,
				contact: this.userForm.controls["countryCode"].value
					? this.userForm.controls["countryCode"].value +
					  "-" +
					  this.userForm.controls["contact"].value
					: this.userForm.controls["contact"].value,
				role: this.userForm.get("select_role").value?.id
			};
			if (this.actionType === "edit") {
				delete data.password;
				data["id"] = this.userData.id;
				this.updateSubscription?.unsubscribe();
				this.updateSubscription = this.userManagementService
					.editUserInfo(data)
					.subscribe(
						(res: any) => {
							if (res) {
								this.onResponseAction(res);
								this.mixpanelService.track("New team member added", {});
							}
						},
						(err: any) => {
							this.notificationService.setMessage(
								err?.error?.context_code,
								err?.error?.summary
							);
						}
					);
			} else {
				this.addSubscription?.unsubscribe();
				this.addSubscription = this.userManagementService
					.createTeamMember(data)
					.subscribe(
						(res: any) => {
							if (res) {
								this.mixpanelService.track("Team member info edited", {});
								this.onResponseAction(res);
							}
						},
						(err: any) => {
							this.notificationService.setMessage(
								err?.error?.context_code,
								err?.error?.summary
							);
						}
					);
			}
		}
	}

	onCloseAction() {
		this.onCancelAction.emit();
	}

	onPasswordPaste() {
		this.notificationService.setMessage(1100, "Copy/Paste is not allowed");
		return false;
	}

	getRolesList() {
		this.loadedRoleList = true;
		this.rolesSubscription = this.userManagementService
			.getRolesList()
			.subscribe((res: any) => {
				this.roleList = res?.data;
				this.loadedRoleList = false;
				if (this.actionType === "add") {
					this.userForm.controls["select_role"].setValue(this.roleList?.[0]);
				}
			});
	}

	updateRoleSelected(role: any) {
		this.userForm.get("select_role").setValue(role);
	}
	checkUpdate() {
		if (this.actionType === "edit") {
			return (
				this.userForm?.status == "INVALID" ||
				!(
					this.userData?.["first_name"] !=
						this.userForm.get("first_name").value ||
					this.userData?.["last_name"] !=
						this.userForm.get("last_name").value ||
					this.userData?.["contact"] !=
						this.userForm.get("countryCode").value +
							"-" +
							this.userForm.get("contact").value ||
					this.userData?.["role"]?.id !=
						this.userForm.get("select_role").value?.id
				)
			);
		}
		return false;
	}
	validateName(c: UntypedFormControl): { [s: string]: string } {
		let val = c.value;
		if (!new RegExp("^[a-zA-Z]*$").test(val)) {
			return { fieldMessage: "Only alphabets are allowed" };
		} else return null;
	}

	ngOnDestroy() {
		this.addSubscription?.unsubscribe();
		this.updateSubscription?.unsubscribe();
	}
}
