import { DOCUMENT } from "@angular/common";
import { Component, Inject, Input, OnInit, SimpleChanges } from "@angular/core";
import { CommonFunctionsService } from "@ds-common-services/utility-services/common-functions.service";
import { LocalStorageService } from "@ds-common-services/storage-services/local-storage.service";

@Component({
	selector: "app-manage-columns",
	templateUrl: "./manage-columns.component.html",
	styleUrls: ["./manage-columns.component.scss"]
})
export class ManageColumnsComponent implements OnInit {
	@Input() moduleType;
	private _columns: any[];
	private copyColumns: any[] = [];
	get columns(): any[] {
		return this._columns;
	}
	@Input() set columns(value: any[]) {
		this._columns = value;
	}
	@Input() hideColumn = [];
	@Input() isScorecardList: boolean = false;
	private scorecardInitialCol: any[] = [];
	public changedColoumnsScorecard = {};
	constructor(
		@Inject(DOCUMENT) private document: Document,
		public commonFunctions: CommonFunctionsService,
		private localStorageService: LocalStorageService
	) {}

	ngOnInit(): void {
		if (this.isScorecardList && this.moduleType.name.includes("scorecard")) {
			if (
				!this.localStorageService.get(this.localStorageService.scorecardCol)
			) {
				this.scorecardInitialCol = JSON.parse(
					JSON.stringify([...this.columns])
				);
				this.localStorageService.set(
					this.localStorageService.scorecardInitialCol,
					this.commonFunctions.getEncodedData(
						JSON.stringify({ value: this.scorecardInitialCol })
					)
				);
			}
		}
	}

	ngOnChanges(changes: SimpleChanges) {
		this.copyColumns = JSON.parse(
			JSON.stringify([...changes.columns.currentValue])
		);
		if (this.isScorecardList && this.moduleType.name.includes("scorecard")) {
			if (this.localStorageService.get(this.localStorageService.scorecardCol)) {
				const tempScorecardCol = JSON.parse(
					this.commonFunctions.getDecodedData(
						this.localStorageService.get(this.localStorageService.scorecardCol)
					)
				).value;
				this.scorecardInitialCol = JSON.parse(
					this.commonFunctions.getDecodedData(
						this.localStorageService.get(
							this.localStorageService.scorecardInitialCol
						)
					)
				).value;

				if (tempScorecardCol.isAll) {
					this.markChecked(tempScorecardCol.fromCopyColumns);
				} else {
					tempScorecardCol.columns.forEach((v, k) => {
						this.columns[k].isChecked = v.isChecked;
					});
				}
			}
		}

		if (
			changes.moduleType?.currentValue?.tabType !==
			changes.moduleType?.previousValue?.tabType
		)
			this.copyColumns = JSON.parse(JSON.stringify([...this.columns]));
		if (changes.moduleType?.currentValue) {
			if (
				this.document.getElementById(
					"select_all" + this.moduleType.name
				) as HTMLInputElement
			)
				(
					this.document.getElementById(
						"select_all" + this.moduleType.name
					) as HTMLInputElement
				).checked = this.isEveryColumnChecked();
		}
	}
	public onSelectAllColumn(checkedStatus) {
		// markChecked takes boolean if its false, it will mark check to every column,
		// if its true it will copy from copyColumn duplicate variable of columns of grid.
		checkedStatus.target.checked
			? this.markChecked(false)
			: this.markChecked(true);
	}
	private markChecked(fromCopyColumns): void {
		if (this.isScorecardList && this.moduleType.name.includes("scorecard")) {
			this.changedColoumnsScorecard = {
				columns: this.columns,
				scorecardInitialCol: this.scorecardInitialCol,
				isAll: true,
				fromCopyColumns: fromCopyColumns
			};
			this.localStorageService.set(
				this.localStorageService.scorecardCol,
				this.commonFunctions.getEncodedData(
					JSON.stringify({ value: this.changedColoumnsScorecard })
				)
			);
			this.columns = this.columns?.map((col, i) => {
				col.isChecked = fromCopyColumns ? this.scorecardInitialCol[i]?.isChecked : true;
				if ((col.name || col.name==="") && this.hideColumn.includes(col.name)) {
					col.isChecked = false;
				}
				return col;
			});
		} else {
			this.columns = this.columns?.map((col, i) => {
				col.isChecked = fromCopyColumns ? this.copyColumns[i]?.isChecked : true;
				if ((col.name || col.name === "") && this.hideColumn.includes(col.name)) {
					col.isChecked = false;
				}
				return col;
			});
		}
	}

	public onSingleCheckChange(event, i) {
		if (event.target.checked && this.columns?.every((col) => col.isChecked)) {
			(
				this.document.getElementById(
					"select_all" + this.moduleType.name
				) as HTMLInputElement
			).checked = true;
		} else {
			(
				this.document.getElementById(
					"select_all" + this.moduleType.name
				) as HTMLInputElement
			).checked = false;
		}
		if (this.isScorecardList && this.moduleType.name.includes("scorecard")) {
			this.changedColoumnsScorecard = {
				columns: this.columns,
				scorecardInitialCol: this.scorecardInitialCol,
				isAll: false,
				fromCopyColumns: null
			};
			this.localStorageService.set(
				this.localStorageService.scorecardCol,
				this.commonFunctions.getEncodedData(
					JSON.stringify({ value: this.changedColoumnsScorecard })
				)
			);
		}
	}

	public isEveryColumnChecked(): boolean {
		return this.columns.every((item) => item.isChecked);
	}
}
