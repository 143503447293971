import { Component, Input, OnDestroy, OnInit, SimpleChanges } from '@angular/core';

import { CommonFunctionsService } from '@ds-common-services/utility-services/common-functions.service';
import { filter, finalize, Subject, Subscription, takeUntil } from 'rxjs';
import { ScorecardService } from '@ds-private-layouts/scorecard/scorecard.service';
import { CurrencyTemplate, ImageDownloadObj } from '@ds-shared/models/common.model';
import { PAYLOAD, TIMEVIEW } from '@ds-shared/enums/common.enum';
import { DataTransferService } from '@ds-common-services/utility-services/data-transfer.service'
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import isoWeek from "dayjs/plugin/isoWeek"
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localizedFormat from 'dayjs/plugin/localizedFormat';import { RolePermissions } from '@ds-shared/models/access-matrix.model';
import { AccessMatrixService } from '@ds-shared/common-services/utility-services/access-matrix.service';
import { AccessMatrix } from '@ds-shared/enums/access-matrix.enum';
@Component({
	selector: "pricing-history",
	templateUrl: "./pricing-history.component.html",
	styleUrls: ["./pricing-history.component.scss"]
})
export class PricingHistoryComponent implements OnInit, OnDestroy {
	private _currencyDetails: CurrencyTemplate;
	public storeName = null;
	get currencyDetails(): any {
		return this._currencyDetails;
	}
	@Input() set currencyDetails(value: CurrencyTemplate) {
		this._currencyDetails = value;
		this.tabObj.pricing.suffiyAxis = ` (${this.currencyDetails?.symbol})`;
		this.tabObj.sales.yRightAxis = `GMV (${this.currencyDetails?.symbol})`;
	}
	private _campaignStatus: number;
	get campaignStatus(): number {
		return this._campaignStatus;
	}
	@Input() set campaignStatus(value: number) {
		this._campaignStatus = value;
		this.setCampaignMessage();
	}
	private _scorecardInfo: any;
	get scorecardInfo(): any {
		return this._scorecardInfo;
	}
	@Input() set scorecardInfo(value: any) {
		this._scorecardInfo = value;
		if (this.scorecardInfo && this.dateFilter) {
			this.getPricingHistoryData();
		}
	}
	private _dateFilter: any;
	get dateFilter(): any {
		return this._dateFilter;
	}
	@Input() set dateFilter(value: any) {
		this._dateFilter = value;
		if (this.scorecardInfo && this.dateFilter) {
			this.isCustomDate = false;
			this.isDefaultTimeline = false;
			this.getPricingHistoryData();
		}
	}

	@Input() public scrollbarThreshold = 30;
	@Input() public xAxisRotationAngle = -30;
	@Input() public takeControlOfTabs = false;
	@Input() public title: string = "";
	@Input() public needTooltip: boolean = true;
	@Input() public chartBackgroundColor = "transparent";
	@Input() public isDefaultCalendarTimeline : boolean = false;
	public pricingHistoryTooltip: string = `trends over time`;
	private $destroy: Subject<boolean> = new Subject();
	public pricingHistoryObj = {
		isLoading: true,
		categories: [],
		chartData: []
	};
	public isDefaultTimeline: boolean = true;
	public defaultTimeline = {
		startDate: this.commonFunctions.getTimeLine()['last_90_days']['startDate'],
		endDate: this.commonFunctions.getTimeLine()['last_90_days']['endDate'],
	}
	public tabObj = {
		pricing: {
			name: "Pricing",
			yAxis: `Price`,
			type: "pricing",
			yRightAxis: "DISCOUNT (%)",
			suffiyAxis: ``,
			isHidden: false
		},
		sales: {
			name: "Sales",
			yAxis: "Quantity Sold",
			type: "sales",
			yRightAxis: `GMV`,
			suffiyAxis: "",
			isHidden: false,
		},
		availability: {
			name: "Availability",
			yAxis: "",
			type: "availability",
			yRightAxis: ``,
			suffiyAxis: "",
			isHidden: false
		}
	};
	private _activeTab: string = "pricing";
	get activeTab(): string {
		return this._activeTab;
	}
	@Input() set activeTab(value: string) {
		this._activeTab = value;
		if (this.scorecardInfo && this.activeTab) {
			this.getPricingHistoryData();
		}
	}
	public tabs: string[] = Object.keys(this.tabObj);
	public exportPayloads = {
		fileName: "",
		path: "scorecard/export-product-store-scorecard-trend-history",
		params: {},
		optionalParams:{}
	};
	public customMessageToHandleCampaign: string = "No data present";
	private trendSubscription: Subscription = new Subscription();
	public displayedDateStringForSales = {
		startDate: "",
		endDate: ""
	};
	// @Input() optionalParams;
	private _optionalParams;
	get optionalParams() {
		return this._optionalParams;
	}
	@Input() set optionalParams(value) {
		this._optionalParams = value;
		this.getPricingHistoryData();
	}
	public dateFilterObj;
	public filterParams: any;
	public isCustomDate = false;
	public dateConfig;
	public isCustomDateByDefault: boolean = false;
	public imageDownloadObj: ImageDownloadObj= {
		isGraph:true,
		graphName: '',
		mainDiv: '',
		legendDiv: 'pricingLegendDiv',
		style: {
			legend: {
				display: 'd-block'
			},
			newEleWidth: '120rem'
		}
	}
	public permissions: {pricing: RolePermissions, sales: RolePermissions, availability: RolePermissions} = {
		pricing: new RolePermissions(),
		sales: new RolePermissions(),
		availability: new RolePermissions()
	}
	private _miscParams: any = {};
	get miscParams(): any {
		return this._miscParams;
	}
	@Input() set miscParams(value: any) {
		this._miscParams = value;
		if (this.scorecardInfo && Object.keys(this.miscParams).length) {
			this.getPricingHistoryData();
		}
	}
	constructor(
		public commonFunctions: CommonFunctionsService,
		public scorecardService: ScorecardService,
		private dataTransferService: DataTransferService,
		private accessMatrixService: AccessMatrixService
	) {
		dayjs.extend(isoWeek)
		dayjs.extend(utc)
		dayjs.extend(customParseFormat)
		dayjs.extend(localizedFormat)
		this.permissions['pricing'] = this.accessMatrixService.getPermissionByModule(AccessMatrix.Pricing);
		this.permissions['sales'] = this.accessMatrixService.getPermissionByModule(AccessMatrix.Sales);
		this.permissions['availability'] = this.accessMatrixService.getPermissionByModule(AccessMatrix.Availability);
	}
  
	ngOnInit(): void {
		// Tabs as per permissions
		Object.entries(this.tabObj).forEach((ele: any) => ele[1].isHidden = !(this.permissions[ele[0]].read_access));
		this.activeTab  = Object.entries(this.tabObj).find((ele: any) => !ele[1].isHidden)?.[0] || '';
		this.imageDownloadObj.onlyImage = !this.permissions[this.activeTab].export_access;
		this.imageDownloadObj.graphName = `${this.tabObj[this.activeTab].name} History`;
		this.dataTransferService.scorecardBreadcrumb$
			.pipe(takeUntil(this.$destroy))
			.subscribe((res) => {
				if (res) {
					this.storeName = res;
					this.exportFileNameChanger();
				}
			});
		if(!this.isDefaultCalendarTimeline)  {
			this.dataTransferService.onDirectMappingChange$
			.pipe(takeUntil(this.$destroy))
			.subscribe((res) => {
				if (res) {
					this.filterParams = {
						...res,
						currency_id: this.commonFunctions.currencySelected?.id
					};
					if (!this.isCustomDateByDefault) {
						this.isCustomDateByDefault = true;

						this.getPricingHistoryData(false);
						return;
					} else {
						this.isCustomDate = true;
						this.getPricingHistoryData(this.isCustomDate);
					}
					this.isDefaultTimeline = false
				}
			});
		}
		else this.getPricingHistoryData();
		this.dataTransferService.variantMappingUpdated$.pipe(filter(res => res), takeUntil(this.$destroy)).subscribe((res) => {
			this.getPricingHistoryData(this.isCustomDate);
		})
	}

	private setCampaignMessage() {
    if(['pricing', 'sales', 'availability'].includes(this.tabObj[this.activeTab].type)) return;
		if (!this.commonFunctions.isCampaignFrequencyAvailable()) {
			this.customMessageToHandleCampaign = "SELLOUT MODULE IS NOT SUBSCRIBED";
		} else if (!this.campaignStatus) {
			this.customMessageToHandleCampaign =
				"Tracker is not Configured in Campaign Mode.";
		} else {
			this.customMessageToHandleCampaign = "No data present";
		}
	}

	getExportDateFormat(date) {
		if (!date?.startDate && !date?.endDate) return;
		if (date?.startDate === date?.endDate)
			return date.startDate.split("-").join("");
		return `${date.startDate.split("-").join("")}_${date.endDate
			.split("-")
			.join("")}`;
	}

	private getPricingHistoryData(isCustomDate?) {
		this.trendSubscription?.unsubscribe();
		this.pricingHistoryObj = {
			isLoading: true,
			categories: [],
			chartData: []
		};
    const currentDate = dayjs(new Date());
		this.dateConfig = this.getDateConfig();
    const daysPrior = new Date(new Date().getTime() - (7 * 24 * 60 * 60 * 1000))
		this.exportFileNameChanger();
    const dateConfig = {
      startDate: this.dateFilter?.startDate,
      endDate: this.dateFilter?.endDate
    }
    this.exportPayloads.fileName = this.commonFunctions.getDynamicExportFileName(this.getExportDateFormat(this.dateFilter), `${this.scorecardInfo.type === 'store' && this.storeName ? this.commonFunctions.removeSpaceAddUnderscore(this.storeName) : this.scorecardInfo.type}_${this.tabObj[this.activeTab].name}_History`);
		this.exportPayloads.params = {
			scorecard_type: this.scorecardInfo.type,
			[`${this.scorecardInfo.view}_id`]: this.scorecardInfo.id,
			start_date: this.isCustomDate
				? this.filterParams.timeline?.startDate
				: this.dateConfig.startDate,
			end_date: this.isCustomDate
				? this.filterParams.timeline?.endDate
				: this.dateConfig.endDate,
			trend_type: this.tabObj[this.activeTab].type,
			[PAYLOAD.TREND_VIEW]:
				this.tabObj[this.activeTab].type === "sales"
					? TIMEVIEW.WEEKLY
					: TIMEVIEW.DAILY
		};
		this.exportPayloads.params = this.commonFunctions.filterPayload({
			...this.exportPayloads.params,
			currency_id: this.currencyDetails.id,
		});
		const miscFilter = this.commonFunctions.getPayloadFromObject(this.miscParams, "filter");
		this.exportPayloads.optionalParams = miscFilter.length ? `${this.optionalParams ? this.optionalParams : ''}&${miscFilter}` : this.optionalParams
		this.displayedDateStringForSales = {
			startDate: this.getDisplayDateString(dayjs(this.dateConfig.startDate)),
			endDate: this.getDisplayDateString(dayjs(this.dateConfig.endDate))
		};
		if (isCustomDate)
			this.dateFilterObj = {
				startDate: this.filterParams.timeline?.startDate,
				endDate: this.filterParams.timeline?.endDate
			};
		else
			this.dateFilterObj = {
				startDate: this.dateFilter?.startDate,
				endDate: this.dateFilter?.endDate
			};
		this.trendSubscription = this.scorecardService
			.getPricingHistory(
				this.scorecardInfo.type,
				this.scorecardInfo.id,
				this.dateFilterObj,
				this.tabObj[this.activeTab].type,
				this.scorecardInfo.view,
				this.tabObj[this.activeTab].type === "sales"
					? TIMEVIEW.WEEKLY
					: TIMEVIEW.DAILY,
				miscFilter.length ? `${this.optionalParams ? this.optionalParams : ''}&${miscFilter}` : this.optionalParams
			)
			.pipe(
				finalize(() => {
					this.pricingHistoryObj.isLoading = false;
				}),
				takeUntil(this.$destroy)
			)
			.subscribe((res: any) => {
				this.pricingHistoryObj.categories = res.data?.categories;
				const multiAxis = ["Discount", "GMV"];
				const nameObj = {
					Sales: "Quantity Sold",
          "Availability": 'Quantity',
          "historical_sold": 'Historical Sold'
          }
				if (this.tabObj[this.activeTab].type === "sales") {
					this.pricingHistoryObj.chartData = res.data?.graph.map((item) => {
						return {
							...item,
							color: item.name === "GMV" ? "#FF5E00" : "#0089FF",
							name: item.name in nameObj ? nameObj[item.name] : item.name,
							caller: item.name.toLocaleLowerCase(),
							currency: this.currencyDetails?.symbol,
							dateObj: res.data["date_range"]
						};
					});
				} else {
					this.pricingHistoryObj.chartData = res.data?.graph.map((item) => {
						return {
							...item,
							color: multiAxis.includes(item.name) ? "#0089ff" : "#ff5800",
							name: item.name in nameObj ? nameObj[item.name] : item.name,
							type: "spline",
							[multiAxis.includes(item.name) ? "yAxis" : null]: 1,
							caller: item.name.toLocaleLowerCase(),
							currency: this.currencyDetails?.symbol
						};
					});
				}
			});
	}

	private exportFileNameChanger(): void {
		const dConfig = this.getDateConfig();
		if(this.isCustomDate){
			this.exportPayloads.fileName =
			this.commonFunctions.getDynamicExportFileName(
				this.commonFunctions.getExportDateFormat(this.filterParams?.timeline),
				`${
					this.storeName
						? this.commonFunctions.removeSpaceAddUnderscore(this.storeName)
						: this.scorecardInfo.type
				}_${this.tabObj[this.activeTab].name}_History`
			);
		}
		else 
		this.exportPayloads.fileName =
			this.commonFunctions.getDynamicExportFileName(
				this.getExportDateFormat(dConfig),
				`${
					this.storeName
						? this.commonFunctions.removeSpaceAddUnderscore(this.storeName)
						: this.scorecardInfo.type
				}_${this.tabObj[this.activeTab].name}_History`
			);
	}

	private getDateConfig(): { startDate; endDate } {
		const dateConfig = {
			startDate: this.dateFilter?.startDate,
			endDate: this.dateFilter?.endDate
		};
		return dateConfig;
	}

	private getDisplayDateString(momentDate) {
		if (momentDate) return momentDate.format("MMM Do YYYY");
	}

	public getDisplayDate(date) {
		return this.getDisplayDateString(dayjs(date));
	}

	onTabChange(tab: string) {
		this.activeTab = tab;
		this.setCampaignMessage();
		if(this.isCustomDate)
		this.getPricingHistoryData(true);
		else this.getPricingHistoryData(false);
		this.imageDownloadObj.graphName = `${this.tabObj[this.activeTab].name} History`;
		this.imageDownloadObj.onlyImage = !this.permissions[this.activeTab].export_access;
	}

	ngOnDestroy() {
		this.$destroy.next(true);
		this.$destroy.unsubscribe();
	}
}
