import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { DashboardService } from "@ds-private-layouts/dashboard/dashboard.service";
import { GlobalService } from "@ds-shared/common-services/http-services/global.service";
import { LocalStorageService } from "@ds-shared/common-services/storage-services/local-storage.service";
import { CommonFunctionsService } from "@ds-shared/common-services/utility-services/common-functions.service";
import { CommonInsightModel } from "@ds-shared/models/common.model";
import { Subject, Subscription, finalize, takeUntil } from "rxjs";

export const enum METRICS {
  NEW_LAUNCHES_BY_COMPETITORS = "new launches by competitor",
  OUT_OF_STOCK = "out of stock",
  MORE_DISCOUNT = "more discount",
  LOW_STOCK = "low_stock",
  SHARE_OF_SEARCH = "share of search",
  RATINGS = "ratings",
  REVIEWS = "reviews",
  CONTENT_SCORE = "content score",
  OVERPRICED_UNDERPRICED = "overpriced and underpriced",
  PRICE_DROP = "price drop"
}
@Component({
  selector: "app-insights",
  templateUrl: "./insights.component.html",
  styleUrls: ["./insights.component.scss"]
})
export class InsightsComponent implements OnInit {
  public _insightInfo: CommonInsightModel = {
    type: null,
    title: null,
    path: null,
    payload: null
  };

  @Input() set insightInfo(value: CommonInsightModel) {
    this._insightInfo = { ...value };
    if (this._insightInfo.payload) {
      this.getMetricsDetails();
    }
  }
  isCollapsed = false;
  public isLoading: boolean = false;
  public metricDataList = [];
  private $destroy: Subject<boolean> = new Subject();
  private clearLastCall: Subscription;
  public insightData: { isLoading: boolean, data: any } = {
    isLoading: false,
    data: []
  }
  constructor(
    public commonFunctions: CommonFunctionsService,
    private route: ActivatedRoute,
    private globalService: GlobalService
  ) { }

  ngOnInit(): void { }

  getColors(item) {
    let color: string = "#8695a4";
    const color_codes = {
      'green': "#009D2E",
      'yellow': "#FF9800",
      'red': "#FF1948"
    }
    if (
    	[
    		METRICS.NEW_LAUNCHES_BY_COMPETITORS,
    		METRICS.MORE_DISCOUNT,
    		METRICS.PRICE_DROP,
    		METRICS.OVERPRICED_UNDERPRICED
    	].includes(item.metric)
    ) {
    	color = "#FF9800";
    } else if (item.metric === METRICS.REVIEWS) {
    	color =
    		item.value > 70 ? "#009D2E" : item.value < 30 ? "#FF1948" : "#FF9800";
    } else if ([METRICS.RATINGS, METRICS.CONTENT_SCORE].includes(item.metric)) {
    	color = item.value > 75 ? "#FF1948" : "#FF9800";
    } else if (
    	[METRICS.OUT_OF_STOCK, METRICS.LOW_STOCK].includes(item.metric)
    ) {
    	color = item.value > 50 ? "#FF1948" : "#FF9800";
    } else if (item.metric === METRICS.SHARE_OF_SEARCH) {
    	color = item.value >= 30 ? "#009D2E" : "#FF9800";
    }

    return item.status in color_codes ? color_codes[item.status] : color;
  }

  private getMetricsDetails(): void {
    this.clearLastCall?.unsubscribe();
    this.insightData.isLoading = true;
    this.insightData.data = [];
    this.clearLastCall = this.globalService
      .callInsightsAPI(this._insightInfo.path, this._insightInfo.payload)
      .pipe(
        finalize(() => (this.insightData.isLoading = false)),
        takeUntil(this.$destroy)
      )
      .subscribe((res: any) => {
        if (!res.data.length) {
          this.closePanel();
          return;
        }

        this.insightData.data = res.data.map((item: any) => {
          return {
            ...item,
            color: this.getColors(item)
          }
        })
      }, (err: any) => (this.closePanel()));
  }
  public addNewProfile(item) {
    const profileObj = {
      url: item.url,
      name: item.name
    }
    this.commonFunctions.redirectToProfile(this._insightInfo.payload["filter[type]"], this.commonFunctions.getEncodedData(JSON.stringify(profileObj)), this.route);
  }
  private closePanel = () => {
    setTimeout(() => {
      this.isCollapsed = true
    }, 1000);
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.unsubscribe();
  }
}
