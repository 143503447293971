<ng-template #popUp let-modal>
    <div class="modal-header p-0 mb-3">
        <div class="heading" translate>{{popUpInfo.name}}</div>
        <div class="d-flex align-items-center">
            <i class="icon icon-Close2 cursor-pointer" (click)="modal.dismiss('Cross click')">
            </i>
        </div>
    </div>
    <div class="modal-body d-flex gap-16 p-0 w-100">
        <div class="w-50">
            <h4>Customer</h4>
            <app-ngx-grid-revamp [ngxCols]="colsCustomer" [ngxItems]="customerGridDataList" [moduleType]="moduleType"
                [column]="colsCustomer" [collection]="customerGridDataList" [loadingData]="loadingData"
                [noData]="NoData" (sortData)="sortData($event)" [itemPerPage]='itemPerPage'
                [isPaginationWithoutRoute]=true (paginationNo)="onPageChange($event)"
                (paginationSize)="onPageSizeChange($event)" [ngClass]="'common-popup-grid'"
                [showPopUpPageSize]="true" [goToPlaceholder]="'Pg.'" [pgType]="'sm'" [maxSize]="1"></app-ngx-grid-revamp>
        </div>
        <div class="w-50">
            <h4>Competitor</h4>
            <app-ngx-grid-revamp [ngxCols]="colsCompetitor" [ngxItems]="competitorGridDataList"
                [moduleType]="moduleType" [column]="colsCompetitor" [collection]="competitorGridDataList"
                [loadingData]="loadingDataCompetitor" [noData]="NoDataCompetitor"
                (sortData)="sortDataCompetitor($event)" [itemPerPage]='itemPerPageCompetitor'
                [isPaginationWithoutRoute]=true (paginationNo)="onPageChangeCompetitor($event)"
                (paginationSize)="onPageSizeChangeCompetitor($event)" [ngClass]="'common-popup-grid'"
                [showPopUpPageSize]="true" [goToPlaceholder]="'Pg.'" [pgType]="'sm'" [maxSize]="1"></app-ngx-grid-revamp>
        </div>
    </div>
</ng-template>