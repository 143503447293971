<div class="overlay"></div>
<div class="right-panel notification-panel" *ngIf="moduleType == 'notification' || moduleType == 'add-notification'"> 
    <i class="icon icon-Close2"
        (click)="onCloseAction()" placement="left" ngbTooltip="{{'Close' | translate}}"
        tooltipClass="info-custom-tooltip"></i>
    <app-notification-headers (selected)="subType = $event" [subType]="subType" [actionType]="actionType">
    </app-notification-headers>
    <app-notification-store-detail *ngIf="subType == 'stores'  && shouldOpenPanel()" (onSuccessAction)="onSuccessAction()"
        (onCancelAction)="onCloseAction()" [notificationData]="notificationData"></app-notification-store-detail>
    <app-notification-product-detail *ngIf="subType == 'products' && shouldOpenPanel()"
        (onSuccessAction)="onSuccessAction()" (onCancelAction)="onCloseAction()" [notificationData]="notificationData">
    </app-notification-product-detail>
    <app-notification-keyword-detail *ngIf="subType == 'keywords' && shouldOpenPanel()"
        (onSuccessAction)="onSuccessAction()" (onCancelAction)="onCloseAction()"
        [notificationData]="notificationData">
    </app-notification-keyword-detail>
    <app-notification-catogory-details *ngIf="subType == 'categories' && shouldOpenPanel()"
        (onSuccessAction)="onSuccessAction()" (onCancelAction)="onCloseAction()"
        [notificationData]="notificationData">
    </app-notification-catogory-details>
    <app-notification-brand-details *ngIf="subType == 'brands' && shouldOpenPanel()"
        (onSuccessAction)="onSuccessAction()" (onCancelAction)="onCloseAction()"
        [notificationData]="notificationData">
    </app-notification-brand-details>
    <div *ngIf="actionType=='edit' && !shouldOpenPanel()"
        class="loading-panel d-flex align-items-center justify-content-center h-75">
        <img src="/assets/images/spinner.svg" alt="">
    </div>
</div>
<div class="right-panel-team-member" *ngIf="moduleType=='team-members'">
    <i class="icon icon-Close2" placement="left" ngbTooltip="{{'Close' | translate}}"
    tooltipClass="info-custom-tooltip" (click)="onCloseAction()"></i>
    <app-add-edit-team-member  (onSuccessAction)="onSuccessAction()" (onCancelAction)="onCloseAction()" [userData]="userData" *ngIf="shouldOpenPanel()"></app-add-edit-team-member>
    <div *ngIf="actionType=='edit' && !shouldOpenPanel()"
        class="loading-panel d-flex align-items-center justify-content-center h-100">
        <img src="/assets/images/spinner.svg" alt="">
    </div>
</div>
<div class="right-panel right-panel-dashboard" *ngIf="(moduleType == 'profile-scorecard' || moduleType === 'build') && subType == 'manage-products'"> <i class="icon icon-Close2"
    (click)="onCloseAction()" placement="left" ngbTooltip="{{'Close' | translate}}"
    tooltipClass="info-custom-tooltip"></i>
    <app-dashboard-headers></app-dashboard-headers>
    <app-manage-product></app-manage-product>
</div>
<div class="right-panel right-panel-dashboard" *ngIf="(moduleType == 'profile-scorecard'  || moduleType === 'build' || moduleType === 'scorecard') && subType == 'manage-mappings'"> 
    <i class="icon icon-Close2" (click)="onCloseAction()" placement="left" ngbTooltip="{{'Close' | translate}}"
        tooltipClass="info-custom-tooltip"></i>
    <app-manage-mapping></app-manage-mapping>
</div>

<div class="right-panel-team-member" *ngIf="moduleType == 'manage-user'">
    
    <i class="icon icon-Close2 z-index2" placement="left" ngbTooltip="Close"
    tooltipClass="info-custom-tooltip" (click)="onCloseAction()"></i>
    <app-edit-user-details (onSuccessAction)="onSuccessAction()" (onCancelAction)="onCloseAction()"></app-edit-user-details>
    <!-- <app-add-edit-team-member  (onSuccessAction)="onSuccessAction()" (onCancelAction)="onCloseAction()"></app-add-edit-team-member> -->
</div>

<div class="right-panel right-panel-roles" *ngIf="type == 'access-matrix' && subType == 'roles'">
    <app-roles-detail (onSuccessAction)="onSuccessAction()" (onCancelAction)="onCloseAction()" [rolesData]="roleData" *ngIf="shouldOpenPanel()">
    </app-roles-detail>
    <div *ngIf="actionType=='edit' && !shouldOpenPanel()" class="loading-panel d-flex align-items-center justify-content-center h-100">
        <img src="/assets/images/spinner.svg" alt="">
    </div>
</div>